import { useState, useCallback, useEffect } from "react";
import { Box, Stack, Typography, SxProps, Theme } from "@mui/material";

import { AppFile } from "@alex/types";

import { ErrorText, LabelText, sharedConfig } from "../shared";
import AlexGradientText from "@/components/text/AlexGradientText";
import { colors } from "@/app/themes";
import FileUploader, { IFileUploaderProps } from "./FileUploader";
import FileDisplay from "./FileDisplay";

export interface IAppFileUploadProps extends IFileUploaderProps {
  label: string;
  value?: AppFile;
  errorMsg?: string;
  onChange: (data: AppFile | undefined) => void;
  sx?: SxProps<Theme>;
}

const AppFileUpload: React.FunctionComponent<IAppFileUploadProps> = (props) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const sharedSx: SxProps<Theme> = {
    borderRadius: sharedConfig.borderRadius,
  };

  useEffect(() => {
    console.log("Value change", { v: props.value });
  }, [props.value]);

  return (
    <Box>
      <LabelText>{props.label}</LabelText>

      <Stack
        sx={{
          bgcolor: sharedConfig.bgcolor,
          borderRadius: sharedConfig.borderRadius,

          ...props.sx,
        }}
      >
        {!props.value ? (
          <FileUploader onChange={props.onChange} />
        ) : (
          <FileDisplay
            value={props.value}
            onClear={() => props.onChange(undefined)}
          />
        )}
      </Stack>

      {props.errorMsg && <ErrorText text={props.errorMsg} />}
    </Box>
  );
};

export default AppFileUpload;
