import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";

import {
  Notification,
  Employee,
  BusinessWithPhoneNumber,
  EmployeeRole,
  Nullable,
} from "@alex/types";

import { apiGet } from "@/services/api/apiSlice";
import { updateMyBusiness, updateMe } from "../settings/slice";
import { InteractionSummary } from "../messages/types";

interface AuthState {
  apiToken: string;
  me: Nullable<Employee>;
  myBusiness: Nullable<BusinessWithPhoneNumber>;
}

const initialState: AuthState = {
  apiToken: "",
  me: null,
  myBusiness: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAPIToken(state, action: PayloadAction<string>) {
      state.apiToken = action.payload;
    },
    setMe(state, action: PayloadAction<Employee>) {
      state.me = action.payload;
    },
    setMyBusiness(state, action: PayloadAction<BusinessWithPhoneNumber>) {
      state.myBusiness = action.payload;
    },
    updateMyBusiness(state, action: PayloadAction<BusinessWithPhoneNumber>) {
      if (state.myBusiness) {
        state.myBusiness.name = action.payload.name;
      }
    },
    updateMe(state, action: PayloadAction<Employee>) {
      if (state.me) {
        state.me.firstName = action.payload.firstName;
        state.me.lastName = action.payload.lastName;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(configureNewAPIToken.fulfilled, (state, action) => {
      if (action.payload.newToken) {
        authSlice.caseReducers.setAPIToken(state, {
          payload: action.payload.newToken,
          type: action.type,
        });
      }

      if (action.payload.me) {
        authSlice.caseReducers.setMe(state, {
          payload: action.payload.me,
          type: action.type,
        });
      }

      if (action.payload.myBusiness) {
        authSlice.caseReducers.setMyBusiness(state, {
          payload: action.payload.myBusiness,
          type: action.type,
        });
      }
    });
    builder.addCase(updateMyBusiness.fulfilled, (state, action) => {
      if (action.payload) {
        authSlice.caseReducers.updateMyBusiness(state, {
          payload: action.payload,
          type: action.type,
        });
      }
    });
    builder.addCase(updateMe.fulfilled, (state, action) => {
      if (action.payload) {
        authSlice.caseReducers.updateMe(state, {
          payload: action.payload,
          type: action.type,
        });
      }
    });
  },
});

export const configureNewAPIToken = createAsyncThunk(
  "auth/configureNewAPIToken",
  async (
    newToken: string,
    { dispatch },
  ): Promise<{
    newToken: string;
    me: Employee | null;
    myBusiness: BusinessWithPhoneNumber | null;
    interactionSummaries: InteractionSummary[] | null;
    notifications: Notification[] | null;
  }> => {
    try {
      const [me, myBusiness, interactionSummaries, notifications] =
        await Promise.all([
          dispatch(apiGet({ url: "/api/e/employees/me", apiToken: newToken })),
          dispatch(apiGet({ url: "/api/e/businesses/me", apiToken: newToken })),
          dispatch(
            apiGet({
              url: "/api/e/messages/interaction-summaries",
              apiToken: newToken,
            }),
          ),
          dispatch(
            apiGet({
              url: "/api/e/notifications",
              apiToken: newToken,
            }),
          ),
        ]);

      if (me.payload && myBusiness.payload) {
        return {
          newToken: newToken,
          me: me.payload as Employee,
          myBusiness: myBusiness.payload as BusinessWithPhoneNumber,
          interactionSummaries:
            interactionSummaries.payload as InteractionSummary[],
          notifications: notifications.payload as Notification[],
        };
      }
    } catch (e) {
      console.log(e);
    }

    return {
      newToken,
      me: null,
      myBusiness: null,
      interactionSummaries: null,
      notifications: null,
    };
  },
);

export const getRoles = (state: AuthState): EmployeeRole[] => {
  if (!state.me) {
    return [];
  }

  const { roles } = state.me;

  return roles;
};

export const { setAPIToken, setMe } = authSlice.actions;
export default authSlice.reducer;
