import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Contact, ContactUpdate, UncreatedContact } from "@alex/types";

import { apiGet, apiPost, apiPut } from "@/services/api/apiSlice";

interface ContactsState {
  contacts: Contact[] | null;
}

const initialState: ContactsState = {
  contacts: null,
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts(state, action: PayloadAction<Contact[]>) {
      state.contacts = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.contacts) {
          state.contacts.push(action.payload);
        } else {
          state.contacts = [action.payload];
        }
      }
    });
  },
});

export const getAllContacts = createAsyncThunk(
  "contacts/getContacts",
  async (_, { dispatch }) => {
    try {
      const contacts = await dispatch(apiGet({ url: "/api/e/contacts" }));

      if (contacts) {
        return contacts.payload as Contact[];
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export const getContactByID = createAsyncThunk<
  Contact | null,
  string,
  { rejectValue: { message: string } }
>(
  "contacts/getContactByID",
  async (contactID: string, { dispatch }): Promise<Contact | null> => {
    try {
      const res = await dispatch(
        apiGet({ url: `/api/e/contacts/${contactID}` }),
      );

      return res.payload as Contact;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const createContact = createAsyncThunk<
  Contact | null,
  UncreatedContact,
  { rejectValue: { message: string } }
>(
  "contacts/createContact",
  async (newContact: UncreatedContact, { dispatch }) => {
    try {
      const res = await dispatch(
        apiPost({ url: `/api/e/contacts`, body: newContact }),
      );

      return res.payload as Contact;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const updateContactByID = createAsyncThunk<
  Contact | null,
  { id: string; update: ContactUpdate },
  { rejectValue: { message: string } }
>(
  "contacts/updateContact",
  async (payload: { id: string; update: ContactUpdate }, { dispatch }) => {
    try {
      const res = await dispatch(
        apiPut({
          url: `/api/e/contacts/${payload.id}`,
          body: payload.update,
        }),
      );

      return res.payload as Contact;
    } catch (e) {
      console.log(e);
    }
    return null;
  },
);

export const formatAndValidatePhoneNumber = createAsyncThunk(
  "contacts/validatePhoneNumber",
  async (phoneNumber: string, { dispatch }): Promise<boolean> => {
    try {
      const res = await dispatch(
        apiGet({
          url: `api/e/contacts/phone-number/is-valid/${phoneNumber}`,
        }),
      );

      return res.payload.isValid as boolean;
    } catch (e) {
      console.log(e);
    }

    return false;
  },
);

export default contactsSlice.reducer;
