import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useState } from "react";

export interface IIntegrationCard {
  name: string;
  isEnabled?: boolean;
  logoUrl?: string;
  logoSx?: SxProps<Theme>;
  actionOverride?: JSX.Element;
  comingSoon?: boolean;
  onClick: () => void;
}

const IntegrationCard: React.FC<IIntegrationCard> = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Stack
      onClick={props.onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "20px",
        py: 5,
        width: "250px",
        alignItems: "center",
        userSelect: "none",
        boxShadow: isHovered
          ? `-1px 2px 4px ${theme.palette.grey[300]}`
          : "none",
        cursor: "pointer",
      })}
    >
      <Box
        component="img"
        src={props.logoUrl}
        sx={{ height: "50px", ...props.logoSx }}
      />

      <Typography variant="h5" sx={{ fontWeight: "bold", mt: 3, mb: 2 }}>
        {props.name}
      </Typography>

      {props.comingSoon ? (
        <Typography sx={{ color: "text.secondary", fontStyle: "italic" }}>
          Coming soon!
        </Typography>
      ) : !!props.actionOverride ? (
        props.actionOverride
      ) : props.isEnabled ? (
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            color: "success.main",
            bgcolor: "success.light",
            py: 1,
            px: 2,
            borderRadius: "100px",
          }}
          spacing={0.5}
        >
          <CheckIcon sx={{ fontSize: 18 }} />
          <Typography sx={{ fontWeight: "bold" }}>Configured</Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            color: "error.main",
            bgcolor: "error.light",
            py: 1,
            px: 2,
            borderRadius: "100px",
          }}
          spacing={0.5}
        >
          <Typography sx={{ fontWeight: "bold" }}>Not Configured</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default IntegrationCard;
