import { Box, Stack } from "@mui/material";

import { IAppIconProps } from "../types";
import { defaultStyles } from "./shared";
import SVG from "@/assets/images/icons/message.svg";
import GradientSVG from "@/assets/images/icons/message-gradient.svg";
import SecondarySVG from "@/assets/images/icons/message-secondary.svg";

interface MessageIconProps extends IAppIconProps {
  secondary?: boolean;
}

const MessageIcon: React.FunctionComponent<MessageIconProps> = (props) => {
  const width = props.width || defaultStyles.width;
  const height = props.height || defaultStyles.height;

  let src = SVG;
  if (props.colored) {
    src = GradientSVG;
  } else if (props.secondary) {
    src = SecondarySVG;
  }

  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "center", ...props.sx }}
    >
      <Box component="img" sx={{ width, height }} src={src} />
    </Stack>
  );
};

export default MessageIcon;
