import { SxProps, Theme, Typography } from "@mui/material";

interface IGraphTitleProps {
  children: string | string[];
  small?: boolean;
  sx?: SxProps<Theme>;
}

const GraphTitle: React.FC<IGraphTitleProps> = (props) => {
  return (
    <Typography
      variant={props.small ? "h6" : "h5"}
      sx={{ color: "text.secondary", fontWeight: "bold", pb: 1.5, ...props.sx }}
    >
      {props.children}
    </Typography>
  );
};

export default GraphTitle;
