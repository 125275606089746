import { Box } from "@mui/material";

import { useAppSelector } from "@/app/state/hooks";
import { getDisplayMessages } from "@/features/messages/slice";
import MsgComposer from "./msgComposer";
import MsgList from "./msgList";

export interface IMessagesProps {
  width: string;
}

const Messages: React.FunctionComponent<IMessagesProps> = (props) => {
  const msgComposerHeight = "70px";

  const messageDisplayItems = useAppSelector((state) =>
    getDisplayMessages(state.messages),
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: props.width,
        overflowX: "hidden",

        borderRadius: "10px",

        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
      }}
    >
      <Box
        sx={{
          height: `calc(100% - ${msgComposerHeight})`,
          width: "100%",
          pl: "15px",
        }}
      >
        <MsgList items={messageDisplayItems}></MsgList>
      </Box>

      <Box
        sx={{
          height: `calc(${msgComposerHeight})`,
          borderRadius: "15px",
        }}
      >
        <MsgComposer />
      </Box>
    </Box>
  );
};

export default Messages;
