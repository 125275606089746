import { useState, useEffect, useRef } from "react";

import { TextField, Button, Box, Stack } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import EmojiEditor from "@/components/inputs/EmojiEditor";
import { sendMessage } from "@/features/messages/slice";
import { useAppDispatch } from "@/app/state/hooks";
import { addNewToast } from "@/services/ui/uiSlice";
import { getPrimaryGradient } from "@/shared/utils/ui";

interface IMsgComposerProps {}

const MsgComposer: React.FunctionComponent<IMsgComposerProps> = (props) => {
  const dispatch = useAppDispatch();

  const [msgBody, setMsgBody] = useState("");
  const msgComposerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (msgComposerRef) {
      msgComposerRef.current?.focus();
    }
  });

  const addEmojiToMessage = (emoji: string) => {
    setMsgBody(msgBody + emoji);
  };

  const handleMessageSend = async () => {
    if (msgBody.length) {
      dispatch(sendMessage(msgBody)).then((res) => {
        if (!res.payload) {
          dispatch(
            addNewToast({
              text: "Failed to send message.",
              severity: "error",
              displaySeconds: 15,
            }),
          );
        }
      });

      setMsgBody("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toLowerCase() === "enter") {
      handleMessageSend();
    }
  };

  // send button disabling
  const [sendBtnDisabled, setSendBtnDisabled] = useState(true);
  useEffect(() => {
    setSendBtnDisabled(!msgBody.length);
  }, [msgBody]);

  return (
    <Box
      sx={{
        borderTop: (theme) => `.5px solid ${theme.palette.grey[400]}`,
        bgcolor: (theme) => theme.palette.grey[100],
        px: "15px",

        mb: 1,
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          pt: "20px",
          height: "50px",
        }}
      >
        <TextField
          value={msgBody}
          onChange={(e) => {
            setMsgBody(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          inputRef={msgComposerRef}
          variant="outlined"
          placeholder="Message"
          autoComplete="off"
          sx={{
            "& .MuiInputBase-input": {
              height: "15px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0,
              borderRadius: "50px",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: "1.5px",
              },
            borderRadius: "50px",
            bgcolor: "white",
            width: "100%",
          }}
          InputProps={{
            sx: { fontSize: 15 },
            endAdornment: (
              <Button
                onClick={handleMessageSend}
                sx={{
                  background: sendBtnDisabled
                    ? "transparent"
                    : getPrimaryGradient(135),
                  borderRadius: "100px",
                  border: (theme) =>
                    sendBtnDisabled
                      ? `1px solid ${theme.palette.grey[400]}`
                      : "none",
                }}
                size="small"
                disabled={sendBtnDisabled}
              >
                <SendIcon
                  sx={{
                    color: sendBtnDisabled ? "grey.400" : "white",
                    fontSize: 18,
                    my: 0.5,
                  }}
                />
              </Button>
            ),
          }}
        />

        <Box sx={{ height: "50px", display: "flex" }}>
          <EmojiEditor onEmojiSelect={addEmojiToMessage} />
        </Box>
      </Stack>
    </Box>
  );
};

export default MsgComposer;
