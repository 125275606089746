import { Divider, SxProps, Theme } from "@mui/material";

export interface IModalDividerProps {
  sx?: SxProps<Theme>;
}

const ModalDivider: React.FunctionComponent<IModalDividerProps> = (props) => {
  return (
    <Divider
      sx={{
        borderBottomWidth: "0.3px",
        borderBottomColor: "grey.500",
        ...props.sx,
      }}
    />
  );
};

export default ModalDivider;
