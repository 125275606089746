import ErrorText from "./ErrorText";
import LabelText from "./LabelText";

export const sharedConfig = {
  width: "40ch",
  py: "10px",
  fontSize: 15,
  borderRadius: "10px",
  bgcolor: "grey.100",
};

export const errorHeight = "20px";

export { ErrorText, LabelText };
