import { ReactNode } from "react";
import { Button, Typography } from "@mui/material";
import { getPrimaryGradient } from "@/shared/utils/ui";

interface IAppButtonProps {
  icon?: ReactNode;
  text: string;
  textColor?: string;
  variant: "text" | "outlined" | "contained" | undefined;
  size?: "small" | "medium" | "large" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  onClick?: () => void;
}

const AppButton: React.FunctionComponent<IAppButtonProps> = (props) => {
  return (
    <Button
      size={props.size}
      variant={props.variant}
      sx={{
        borderRadius: "100px",
        fontSize: 18,
      }}
      onClick={props.onClick}
      color={props.color || undefined}
    >
      {props.icon}

      <Typography
        sx={
          props.textColor
            ? { color: props.textColor, fontSize: 14 }
            : {
                backgroundImage: getPrimaryGradient(45),
                backgroundSize: "100%",
                backgroundRepeat: "repeat",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }
        }
      >
        {props.text}
      </Typography>
    </Button>
  );
};

export default AppButton;
