import { RouteObject, useRoutes } from "react-router-dom";

import {
  CampaignIcon,
  ContactIcon,
  DashboardIcon,
  FlowIcon,
  KeywordIcon,
  MessageIcon,
  SettingsIcon,
  StarIcon,
} from "@/components/icons";

import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import { EmployeeRole } from "@alex/types";

import MainLayout from "@/app/layouts/mainLayout";
import Dashboard from "@/features/dashboard";
import Messages from "@/features/messages";
import Keywords from "@/features/keywords";
import Lists from "@/features/groups";
import ListDetails from "@/features/groups/lists/details";
import Profile from "@/features/profile";
import Settings from "@/features/settings";
import AccountSettings from "@/features/settings/account";
import EmployeesSettings from "@/features/settings/users";
import IntegrationsSettings from "@/features/settings/integrations";

import Campaigns from "@/features/campaigns";
import Contacts from "@/features/contacts";
import Flows from "@/features/campaigns/flows";
import StoreLocations from "@/features/rewardsApp/locations";
import LocationDetails from "@/features/rewardsApp/locations/details";
import TabletScreensSettings from "@/features/rewardsApp/settings/tabletScreens";
import Test from "@/features/test";
import { IAppRoute, INavItem } from "./types";

import InboxAdornment from "../layouts/mainLayout/sidebar/InboxAdornment";
import RewardsAppSettings from "@/features/rewardsApp/settings";
import RewardsAppMessagingSettings from "@/features/rewardsApp/settings/messaging";
import SquarePOSIntegrationSettings from "@/features/settings/integrations/SquarePOS";

export enum AppRoute {
  AccountSettings = "/settings/account",
  BlastCampaigns = "/campaigns/blasts",
  Contacts = "/audience/contacts",
  Dashboard = "",
  FlowCampaigns = "/campaigns/flows",
  Lists = "/audience/lists",
  Profile = "/profile",
  TypographyBasePath = "/typography",
  UserSettings = "/settings/users",
  IntegrationSettings = "/settings/integrations",
  InstoreAppLanding = "/instore-app",
  InstoreAppLocations = "/instore-app/locations",
  InstoreAppTabletScreensSettings = "/instore-app/settings/tablet-screens",
  InstoreAppMessagingSettings = "/instore-app/settings/messaging",
}

const features: IAppRoute[] = [
  {
    displayName: "Dashboard",
    path: AppRoute.Dashboard,
    element: <Dashboard />,
    requiredRole: null,
    navBar: {
      icon: DashboardIcon,
    },
  },

  // {
  //   displayName: "Keywords",
  //   path: "keywords",
  //   element: <Keywords />,
  //   requiredRole: EmployeeRole.Admin,
  //   children: [
  //     {
  //       path: ":id",
  //     },
  //   ],
  //   navBar: {
  //     icon: KeywordIcon,
  //   },
  // },
  // {
  //   displayName: "Campaigns",
  //   path: "campaigns",
  //   element: <></>,
  //   requiredRole: EmployeeRole.Admin,
  //   navBar: {
  //     icon: CampaignIcon,
  //     children: [
  //       {
  //         displayName: "Blasts",
  //         path: AppRoute.BlastCampaigns,
  //         element: <Campaigns />,
  //         requiredRole: EmployeeRole.Admin,
  //       },
  //       {
  //         displayName: "Flows",
  //         path: AppRoute.FlowCampaigns,
  //         element: <Flows />,
  //         requiredRole: EmployeeRole.Admin,
  //       },
  //     ],
  //   },
  // },
  {
    displayName: "Rewards App",
    path: AppRoute.InstoreAppLanding,
    element: <></>,
    requiredRole: null,
    navBar: {
      icon: StarIcon,
      children: [
        {
          displayName: "Locations",
          path: `${AppRoute.InstoreAppLocations}`,
          element: <StoreLocations />,
          requiredRole: EmployeeRole.Admin,
        },
        {
          displayName: "Settings",
          path: "/instore-app/settings/",
          element: <RewardsAppSettings />,
          requiredRole: EmployeeRole.Admin,
        },
      ],
    },
  },
  {
    displayName: "Audience",
    path: "audience",
    element: <></>,
    requiredRole: null,
    navBar: {
      icon: ContactIcon,
      children: [
        // {
        //   displayName: "Lists & Segments",
        //   path: AppRoute.Lists,
        //   element: <Lists />,
        //   requiredRole: EmployeeRole.Admin,
        // },
        {
          displayName: "Contacts",
          path: AppRoute.Contacts,
          element: <Contacts />,
          requiredRole: EmployeeRole.Admin,
        },
      ],
    },
  },
  {
    displayName: "Inbox",
    path: "messages",
    element: <Messages />,
    requiredRole: EmployeeRole.Messaging,
    navBar: {
      icon: MessageIcon,
      endAdornment: <InboxAdornment />,
    },
  },
  // {
  //   displayName: "Data Upload",
  //   path: "/integrations",
  //   element: <DataSourcesOverview />,
  //   requiredRole: EmployeeRole.Admin,
  //   navBar: {
  //     icon: ElectricBoltIcon,
  //   },
  // },

  {
    path: `${AppRoute.Lists}/:id`,
    element: <ListDetails />,
    requiredRole: EmployeeRole.Admin,
  },
  {
    path: `${AppRoute.InstoreAppLocations}/:id`,
    element: <LocationDetails />,
    requiredRole: EmployeeRole.Admin,
  },
  {
    displayName: "Settings",
    path: "settings",
    element: <Settings />,
    requiredRole: EmployeeRole.Admin,
    children: [
      { path: "account", element: <AccountSettings /> },
      {
        path: "users",
        element: <EmployeesSettings />,
      },
      {
        path: "integrations",
        element: <IntegrationsSettings />,
      },
      {
        path: "integrations/square",
        element: <SquarePOSIntegrationSettings />,
      },
    ],
    putOnSidebarBottom: true,
    navBar: {
      icon: SettingsIcon,
      clickPath: AppRoute.AccountSettings,
    },
  },
  {
    path: AppRoute.Profile,
    element: <Profile />,
    requiredRole: null,
  },
  {
    displayName: "Typography",
    path: "typography",
    element: <Test />,
    requiredRole: null,
    navBar: {},
  },
];

export default function Routes() {
  let featureRouteObjects: RouteObject[] = [];

  features.forEach((el) => {
    el.navBar?.children?.forEach((el) => {
      featureRouteObjects.push({
        path: el.path,
        element: el.element,
        children: el.children,
      });
    });

    featureRouteObjects.push({
      path: el.path,
      element: el.element,
      children: el.children,
    });
  });

  featureRouteObjects.push({
    path: "*",
    element: <>Not Found</>,
  });

  let element = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: featureRouteObjects,
    },
  ]);

  return element;
}

export function getHeaderText(urlPath: string): string {
  const lookup: { [key: string]: string } = {};

  features.forEach((el) => {
    if (el.displayName) {
      lookup[el.path] = el.displayName;
    }
  });

  urlPath = urlPath.substring(1);
  const pathParts = urlPath.split("/");

  const topLevel = pathParts[0];
  if (topLevel in lookup) {
    return lookup[topLevel];
  } else {
    return "";
  }
}

export function getNavItems(roles: EmployeeRole[]): INavItem[] {
  const navItems: INavItem[] = [];

  for (const el of features) {
    if (el.displayName && el.navBar?.icon) {
      navItems.push({
        path: el.navBar?.clickPath || el.path,
        displayText: el.displayName,
        icon: el.navBar?.icon,
        putOnSidebarBottom: el.putOnSidebarBottom,
        endAdornment: el.navBar?.endAdornment,
        children: el.navBar?.children,
      });
    }
  }

  return navItems;
}

export function getTabTitles(): { [key: string]: string } {
  let titles: { [key: string]: string } = {};

  features.forEach((el) => {
    if (el.displayName) {
      titles[el.path] = el.displayName;
    }
  });

  return titles;
}
