import { configureStore } from "@reduxjs/toolkit";

// feature reducers
import campaignsReducer from "@/features/campaigns/slice";
import keywordsReducer from "@/features/keywords/slice";
import groupsReducer from "@/features/groups/slice";
import apiReducer from "@/services/api/apiSlice";
import authReducer from "@/features/auth/authSlice";
import contactsReducer from "@/features/contacts/slice";
import messagesReducer from "@/features/messages/slice";
import notificationsReducer from "@/features/notifications/slice";
import settingsReducer from "@/features/settings/slice";
import uiReducer from "@/services/ui/uiSlice";

export const store = configureStore({
  reducer: {
    campaigns: campaignsReducer,
    keywords: keywordsReducer,
    groups: groupsReducer,
    api: apiReducer,
    auth: authReducer,
    contacts: contactsReducer,
    messages: messagesReducer,
    notifications: notificationsReducer,
    settings: settingsReducer,
    ui: uiReducer,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(apiSlice.middleware);
  // },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
