import useSWR from "swr";
import moment from "moment";

import {
  ContactVisitsAggregate,
  IVisitsByLocation,
  MessageAggregate,
  StatCardData,
  TimeRange,
} from "@alex/types";

function getURLTimeRange(range: TimeRange): string {
  return `start_date=${moment(range.startDate).format(
    "MM/DD/YYYY",
  )}&end_date=${moment(range.endDate).format("MM/DD/YYYY")}`;
}

export const useDashboardData = (
  timeRange?: TimeRange,
  numDays?: number,
): {
  totalCustomers?: StatCardData;
  newCustomers?: StatCardData;
  instoreVisits?: StatCardData;
  instoreVisitAggregates?: ContactVisitsAggregate[];
  messageAggregates?: MessageAggregate[];
  visitsByLocation?: IVisitsByLocation[];
} => {
  const { data: totalCustomers } = useSWR<StatCardData>(
    timeRange && `/api/s/analytics/customers?${getURLTimeRange(timeRange)}`,
  );

  const { data: newCustomers } = useSWR<StatCardData>(
    timeRange && `/api/s/analytics/new-customers?${getURLTimeRange(timeRange)}`,
  );

  const { data: instoreVisits } = useSWR<StatCardData>(
    timeRange &&
      `/api/s/analytics/instore-visits?${getURLTimeRange(timeRange)}`,
  );

  const { data: instoreVisitAggregates } = useSWR<ContactVisitsAggregate[]>(
    timeRange &&
      `/api/s/analytics/dashboard/instore-capture/visits?num_visits=10`,
  );

  const { data: messageAggregates } = useSWR<{
    aggregates: MessageAggregate[];
    totalMsgs: number;
  }>(
    !!numDays &&
      `/api/s/analytics/dashboard/messages?unit=days&amount=${numDays}`,
  );

  const { data: visitsByLocation } = useSWR(
    `/api/s/analytics/instore-capture/visits/location?num_days=${numDays}`,
  );

  return {
    totalCustomers,
    newCustomers,
    instoreVisits,
    instoreVisitAggregates,
    messageAggregates: messageAggregates?.aggregates.map((el) => {
      return {
        ...el,
        startDate: new Date(el.startDate),
      };
    }),
    visitsByLocation,
  };
};
