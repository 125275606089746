import { useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MarkUnreadIcon from "@mui/icons-material/Markunread";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";

import { Notification } from "@alex/types";
import { getPrimaryGradient } from "@/shared/utils/ui";

export interface INotificationCardProps {
  item: Notification;
  onDeleteBtnClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onMarkReadBtnClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => void;
  onMarkUnreadBtnClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  sx?: SxProps<Theme>;
}

const NotificationCard: React.FunctionComponent<INotificationCardProps> = (
  props,
) => {
  const { item } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack
      onClick={() => {
        if (!props.item.isRead && !isOpen) {
          props.onMarkReadBtnClick(null);
        }
        setIsOpen(!isOpen);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      direction="row"
      sx={{
        ...props.sx,
        bgcolor: "grey.100",
        borderRadius: "10px",
        p: 1.5,

        justifyContent: "space-between",
        alignItems: "center",
        border: (theme) => `.5px solid ${theme.palette.grey[300]}`,
        "&:hover": {
          boxShadow: (theme) => `0 1px 2px ${theme.palette.grey[400]}`,
        },

        cursor: "pointer",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack direction="row">
            <Box
              sx={{
                width: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!item.isRead && (
                <>
                  <Box
                    sx={{
                      height: "12px",
                      width: "12px",
                      borderRadius: "100px",

                      background: getPrimaryGradient(),
                    }}
                  />
                </>
              )}
            </Box>

            <Box sx={{ px: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>{item.title}</Typography>
              <Typography sx={{ fontSize: 13 }}>
                {moment(props.item.createdAt)
                  .local()
                  .format("MMM D, yyyy @ h:mm a")}
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              pr: 2,
            }}
          >
            {isHovered && (
              <Box
                sx={{
                  border: (theme) => `0.5px solid ${theme.palette.grey[400]}`,
                  borderRadius: "5px",
                  mr: 2,
                  p: 0.25,
                }}
              >
                {props.item.isRead ? (
                  <ActionIconButton
                    onClick={props.onMarkUnreadBtnClick}
                    tooltipText="Unread"
                    icon={<MarkUnreadIcon sx={{ fontSize: "inherit" }} />}
                    color="grey.500"
                  />
                ) : (
                  <ActionIconButton
                    onClick={props.onMarkReadBtnClick}
                    tooltipText="Mark Read"
                    icon={
                      <CheckCircleOutlineIcon sx={{ fontSize: "inherit" }} />
                    }
                    color="success.main"
                  />
                )}

                <ActionIconButton
                  onClick={props.onDeleteBtnClick}
                  tooltipText="Delete"
                  icon={<DeleteOutlineIcon sx={{ fontSize: "inherit" }} />}
                  color="error.main"
                />
              </Box>
            )}

            <ArrowDownIcon
              sx={{
                fontSize: 22,
                color: "text.secondary",
                transform: isOpen ? "rotate(180deg)" : "none",
              }}
            />
          </Stack>
        </Stack>

        {isOpen && (
          <Typography
            sx={{
              mt: 1,
              py: 1,
              px: 2,

              borderRadius: "10px",
              ml: 2,
              color: "text.secondary",
              borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            {item.body}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

interface IActionIconButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: JSX.Element;
  color: string;
  tooltipText: string;
}

const ActionIconButton: React.FunctionComponent<IActionIconButtonProps> = (
  props,
) => {
  return (
    <Tooltip
      placement="bottom"
      title={
        <Typography
          sx={{
            fontSize: 14,
            p: 0,
          }}
        >
          {props.tooltipText}
        </Typography>
      }
    >
      <IconButton
        onClick={(e) => props.onClick(e)}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
            color: props.color,
          },
          py: 0.5,
          fontSize: 18,
        }}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};

export default NotificationCard;
