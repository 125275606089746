import { Stack, SxProps, Theme, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { SaveButton } from "../buttons";
import { getPrimaryGradient } from "@/shared/utils/ui";
import IconGradientWrapper from "../icons/IconGradientWrapper";
import { useState } from "react";

export interface IEditSaveResetProps {
  editEnabled: boolean;
  onEditBtnClick: () => void;
  onSaveBtnClick: () => void;
  onCancelBtnClick: () => void;
  sx?: SxProps<Theme>;
}

const EditSaveCancel: React.FC<IEditSaveResetProps> = (props) => {
  const [editBtnHovered, setEditBtnHovered] = useState(false);

  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", ...props.sx }}
      spacing={2}
    >
      {props.editEnabled ? (
        <>
          <SaveButton
            onClick={() => props.onSaveBtnClick()}
            sx={{ height: "28px" }}
          />

          <Typography
            onClick={() => props.onCancelBtnClick()}
            sx={{
              cursor: "pointer",

              fontWeight: "bold",
              color: "text.secondary",
              "&:hover": {
                color: "text.primary",
              },
            }}
          >
            Cancel
          </Typography>
        </>
      ) : (
        <Stack
          onClick={() => {
            setEditBtnHovered(false);
            props.onEditBtnClick();
          }}
          onMouseEnter={() => setEditBtnHovered(true)}
          onMouseLeave={() => setEditBtnHovered(false)}
          direction="row"
          sx={{
            alignItems: "center",
            color: "text.secondary",
            userSelect: "none",
            borderRadius: "100px",
            height: "28px",

            "&:hover": {
              cursor: "pointer",
              background: getPrimaryGradient(160),
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
              width: "fit-content",
            },
          }}
          spacing={0.5}
        >
          <IconGradientWrapper
            disableGradient={!editBtnHovered}
            sx={{
              fontSize: editBtnHovered ? 15.5 : 15,
              color: "grey.500",
            }}
          >
            {EditIcon}
          </IconGradientWrapper>
          <Typography sx={{ fontWeight: "inherit" }}>Edit</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default EditSaveCancel;
