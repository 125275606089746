import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { INavItem } from "@/app/routes/types";
import AlexGradientText from "@/components/text/AlexGradientText";
import NavItemChildren from "./NavItemChildren";

export interface IAppNavItemProps {
  item: INavItem;
}

const AppNavItem: React.FunctionComponent<IAppNavItemProps> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { icon: Icon } = item;

  const [isOpened, setIsOpened] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const hasChildren = !!item.children?.length;

  useEffect(() => {
    let newBasePath = location.pathname.slice(1);
    if (newBasePath.includes("/")) {
      newBasePath = newBasePath.split("/")?.[0];
    }

    let myBasePath = item.path;
    if (myBasePath?.[0] === "/") {
      myBasePath = myBasePath.slice(1);

      if (myBasePath.includes("/")) {
        myBasePath = myBasePath.split("/")?.[0];
      }
    }

    setIsActive(newBasePath === myBasePath);
  }, [location]);

  return (
    <Box sx={{ py: 0.25, px: "10px", mb: 1 }}>
      <Stack
        direction="row"
        onClick={() => {
          if (hasChildren) {
            setIsOpened(!isOpened);
          } else {
            navigate(item.path);
          }
        }}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: 1,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Icon
            colored={isActive}
            height={14}
            sx={{ width: "20px", mr: "8px", zIndex: 999 }}
          />

          {isActive ? (
            <AlexGradientText
              variant="h6"
              sx={{
                ...(isActive && !hasChildren ? { fontWeight: "bold" } : {}),
              }}
            >
              {item.displayText}
            </AlexGradientText>
          ) : (
            <Typography
              variant="h6"
              sx={{
                "&:hover": {
                  fontWeight: "bold",
                },
              }}
            >
              {item.displayText}
            </Typography>
          )}
        </Stack>

        {hasChildren && (
          <ChevronDownIcon
            sx={{
              fontSize: "20px",
              color: "text.secondary",
              transform: isOpened ? "rotate(-180deg)" : "none",
              transition: "transform 0.3s ease",
            }}
          />
        )}

        {item.endAdornment}
      </Stack>

      {item.children && (
        <NavItemChildren
          children={item.children}
          opened={isOpened}
          sx={{ ml: "10px", mt: "5px" }}
        />
      )}
    </Box>
  );
};

export default AppNavItem;
