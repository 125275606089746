import { Stack, SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ICloseModalButtonProps {
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const CloseModalButton: React.FunctionComponent<ICloseModalButtonProps> = (
  props,
) => {
  return (
    <Stack
      onClick={props.onClick}
      sx={{
        bgcolor: "grey.200",
        borderRadius: "10px",
        p: 0.75,
        color: "text.secondary",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "grey.300",
          boxShadow: (theme) => `-0.5px 0.5px 3px ${theme.palette.grey[300]}`,
        },
        ...props.sx,
      }}
    >
      <CloseIcon sx={{ fontSize: 22 }} />
    </Stack>
  );
};

export default CloseModalButton;
