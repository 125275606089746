import { IDateStatAggregate } from "@alex/types";
import { Stack } from "@mui/material";
import useSWR from "swr";
import VisitsByHourGraph from "./visitsByHourGraph";
import TabletStatusGraph from "./tabletStatusGraph";

interface ILocationAnalyticsProps {
  locationID?: string;
}

const LocationAnalytics: React.FC<ILocationAnalyticsProps> = (props) => {
  const { locationID } = props;

  const { data: heartbeats } = useSWR<IDateStatAggregate[]>(
    locationID && `api/s/instore-capture/locations/${locationID}/heartbeats`,
  );

  const { data: visits } = useSWR<IDateStatAggregate[]>(
    locationID && `api/s/instore-capture/locations/${locationID}/visits`,
  );

  return (
    <Stack>
      <VisitsByHourGraph data={visits} />

      <TabletStatusGraph data={heartbeats} />
    </Stack>
  );
};

export default LocationAnalytics;
