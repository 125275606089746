import { LabelText } from "@/components/inputs/shared";
import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

interface IImagePreviewProps {
  logoImageUrl?: string;
  bodyImageUrl?: string;
  large?: boolean;
  sx?: SxProps<Theme>;
}

const ImagePreview: React.FC<IImagePreviewProps> = (props) => {
  return (
    <Stack
      sx={{
        ...props.sx,
      }}
    >
      <LabelText>Logo Image</LabelText>
      <Stack sx={{ height: props.large ? "80px" : "60px" }}>
        {props.logoImageUrl ? (
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "fit-content",
              borderRadius: "5px",
              mt: 0.5,
            }}
            src={props.logoImageUrl}
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "150px",
              bgcolor: "grey.100",
              boxShadow: (theme) => `inset 0 0 5px ${theme.palette.grey[500]}`,
              borderRadius: "5px",
            }}
          />
        )}
      </Stack>
      <LabelText sx={{ mt: 3 }}>Body Image</LabelText>
      <Stack
        sx={{
          height: props.large ? "275px" : "100px",
          width: props.large ? "275px" : "100px",
          mt: 0.5,
        }}
      >
        {props.bodyImageUrl ? (
          <Box
            component="img"
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "200px",
            }}
            src={props.bodyImageUrl}
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "200px",
              boxShadow: (theme) => `inset 0 0 5px ${theme.palette.grey[500]}`,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ImagePreview;
