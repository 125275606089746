import { useEffect } from "react";

import ContactsTable from "./ContactsTable";
import { useAppDispatch, useAppSelector } from "@/app/state/hooks";
import { IBaseTableProps } from "@/components/tables";
import { getAllContacts } from "../slice";

export interface IContactsOverviewProps extends IBaseTableProps {}

const ContactsOverview: React.FunctionComponent<IContactsOverviewProps> = (
  props,
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  const rows = useAppSelector((state) => state.contacts.contacts);

  return (
    <ContactsTable
      rows={rows}
      onRefreshBtnClick={() => dispatch(getAllContacts())}
      {...props}
    />
  );
};

export default ContactsOverview;
