import { Stack, SxProps, Theme, Typography, Tooltip } from "@mui/material";

export interface ITooltipIconButtonProps {
  tooltipText: string;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  onClick: () => void;
  children: any;
  sx?: SxProps<Theme>;
}

const TooltipIconButton: React.FC<ITooltipIconButtonProps> = (props) => {
  return (
    <Tooltip
      placement={props.placement || "top"}
      arrow
      title={
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {props.tooltipText}
        </Typography>
      }
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          cursor: "pointer",
          ...props.sx,
        }}
        onClick={props.onClick}
      >
        {props.children}
      </Stack>
    </Tooltip>
  );
};

export default TooltipIconButton;
