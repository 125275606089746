import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import AppTab, { IAppTabProps } from "./AppTab";
import AppTabGroup from "./AppTabGroup";

interface INavigationTabsProps {
  tabs: INavTab[];
  sx?: SxProps<Theme>;
}

export interface INavTab extends Omit<IAppTabProps, "onClick"> {
  navPath: string;
}

const NavigationTabs: React.FC<INavigationTabsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <AppTabGroup sx={props.sx}>
      {props.tabs.map((tab) => {
        return (
          <Box key={tab.navPath}>
            <NavLink to={tab.navPath}>
              {({ isActive }) => (
                <AppTab
                  title={tab.title}
                  icon={tab.icon}
                  isSelected={isActive}
                  onClick={() => {
                    navigate(tab.navPath);
                  }}
                />
              )}
            </NavLink>
          </Box>
        );
      })}
    </AppTabGroup>
  );
};

export default NavigationTabs;
