import {
  Box,
  SxProps,
  Theme,
  Skeleton,
  Typography,
  Stack,
} from "@mui/material";
import MovingIcon from "@mui/icons-material/Moving";
import AlexGradientText from "@/components/text/AlexGradientText";
import { GraphTitle } from "@/components/graphs";
import { StatCardData } from "@alex/types";

export interface ISingleStatCardProps {
  title: string;
  data?: StatCardData;
  dateCompareText?: string;
  sx?: SxProps<Theme>;
}

const SingleStatCard: React.FunctionComponent<ISingleStatCardProps> = (
  props,
) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,

        bgcolor: "grey.100",
        display: "flex",
        flexDirection: "column",

        ...props.sx,
      }}
    >
      <GraphTitle sx={{ mb: 0.5 }}>{props.title}</GraphTitle>

      {!props.data ? (
        <Skeleton variant="rounded" sx={{ width: "100%", height: "80px" }} />
      ) : (
        <Stack sx={{ height: "80px" }}>
          <AlexGradientText variant="h1" bold sx={{ mb: 1 }}>
            {props.data.value}
          </AlexGradientText>

          {props.data.percentChange >= 0 ? (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ alignItems: "center", color: "success.main" }}
            >
              <MovingIcon sx={{ fontSize: 18 }} />
              <Typography sx={{ color: "text.secondary" }}>Up</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {Math.round(props.data.percentChange)}%
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {props.dateCompareText}
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ alignItems: "center", color: "error.main" }}
            >
              <MovingIcon sx={{ fontSize: 18, transform: "scaleY(-1)" }} />
              <Typography sx={{ color: "text.secondary" }}>Down</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {Math.round(Math.abs(props.data.percentChange))}%
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {props.dateCompareText}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SingleStatCard;
