import { Box, Stack, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import useSWR from "swr";
import queryString from "query-string";

import { BackButton, GradientButton } from "@/components/buttons";
import { addNewToast } from "@/services/ui/uiSlice";
import BorderedStack from "@/components/layout/BorderedStack";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/app/state/hooks";
import { PosIntegration } from "@alex/types";

const SquarePOSIntegrationSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isEnabled, setIsEnabled] = useState<boolean>();

  const justCompletedAuth = queryString.parse(
    window.location.search,
  )?.completed_auth;

  useEffect(() => {
    if (justCompletedAuth === "true") {
      dispatch(
        addNewToast({
          text: "Successfully authenticated Square integration",
          severity: "success",
        }),
      );
    }
  }, [justCompletedAuth]);

  const { data: integrationStatus } = useSWR<PosIntegration[]>(
    "/api/s/pos/install-status",
  );

  useEffect(() => {
    const squareIntegration = integrationStatus?.find((el) => {
      return el.name === "square";
    });

    if (squareIntegration) {
      setIsEnabled(squareIntegration.isEnabled);
    }
  }, [integrationStatus]);

  const { data: authURL } = useSWR<{ authURL: string }>(
    isEnabled === false && "/api/s/pos/square/authorization",
  );

  return (
    <Stack>
      {/* TODO - this needs to go back to /settings/integrations */}
      <BackButton sx={{ mb: 2 }} overridePath="/settings/integrations">
        Integrations
      </BackButton>

      <BorderedStack spacing={2}>
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
          <Box
            component="img"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Square%2C_Inc._-_Square_logo.svg/2048px-Square%2C_Inc._-_Square_logo.svg.png"
            sx={{ width: "35px" }}
          />

          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Square
          </Typography>
        </Stack>

        {isEnabled ? (
          <Stack>integration is enabled. add more data here</Stack>
        ) : (
          <>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              This integration is not yet enbabled. Please authorize to use it.
            </Typography>

            <GradientButton
              onClick={() => {
                if (authURL) {
                  window.location.href = authURL.authURL;
                }
              }}
              sx={{ px: 5 }}
            >
              Authorize
              <OpenInNewIcon sx={{ ml: 1, fontSize: 18 }} />
            </GradientButton>
          </>
        )}
      </BorderedStack>
    </Stack>
  );
};

export default SquarePOSIntegrationSettings;
