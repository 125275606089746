import { Box, Stack, CircularProgress } from "@mui/material";

import { colors } from "../themes";
import config from "@/config";

export interface ILoadingPageProps {}

const LoadingPage: React.FunctionComponent<ILoadingPageProps> = (props) => {
  return (
    <Stack
      sx={{
        height: `100vh`,
        width: "100vw",
        bgcolor: "white",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={`${config.cdn.baseURL}/alex-icon-lobby.png`}
          alt="Logo"
          style={{ position: "absolute", height: "32px", width: "40px" }}
        />
        <CircularProgress
          size={70}
          sx={{ color: colors.primary.main, borderRadius: "100%" }}
          thickness={1.8}
        />
      </Box>
    </Stack>
  );
};

export default LoadingPage;
