import tinycolor from "tinycolor2";

import { colors } from "@/app/themes";

export function getPrimaryGradient(tiltDeg?: number): string {
  const leftColor = colors.gradient.left;
  const rightColor = colors.gradient.right;
  const deg = tiltDeg || 160;

  return `linear-gradient(${deg}deg, ${leftColor} 0%, ${rightColor} 90%)`;
}

export function getSecondaryGradient(tiltDeg?: number): string {
  const leftColor = colors.secondary.light;
  const rightColor = colors.secondary.main;
  const deg = tiltDeg || 160;

  return `linear-gradient(${deg}deg, ${leftColor} 0%, ${rightColor} 100%)`;
}

export function hexToRgb(hex: string): number[] {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

export function rgbToHex(rgb: number[]): string {
  return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
    .toString(16)
    .slice(1)}`;
}

export function generateGradient(
  color1: string,
  color2: string,
  n: number,
): string[] {
  if (n <= 2) {
    return [color1, color2];
  }

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate the step size for each color channel
  const stepR = (rgb2[0] - rgb1[0]) / (n - 1);
  const stepG = (rgb2[1] - rgb1[1]) / (n - 1);
  const stepB = (rgb2[2] - rgb1[2]) / (n - 1);

  // Generate the gradient colors
  const gradient: string[] = Array.from({ length: n }, (_, i) =>
    rgbToHex([
      Math.round(rgb1[0] + stepR * i),
      Math.round(rgb1[1] + stepG * i),
      Math.round(rgb1[2] + stepB * i),
    ]),
  );

  return gradient;
}

export function generateShades(baseColor: string, n: number): string[] {
  if (n <= 1) {
    return [baseColor];
  }

  const rgb = hexToRgb(baseColor);

  const startNum = -Math.floor(n / 2);

  const shades: string[] = [];

  for (let i = startNum; i < startNum + n; i++) {
    shades.push(
      tinycolor(baseColor)
        .darken(-15 * i)
        .toString(),
    );
  }

  return shades;
}
