import { useEffect } from "react";
import { Box } from "@mui/material";

import { useAppDispatch } from "@/app/state/hooks";
import { getInteractionSummaries } from "./slice";
import Inbox from "./inbox";
import InteractionView from "./interactionView";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";

export interface IMessagesProps {}

const Messages: React.FunctionComponent<IMessagesProps> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getInteractionSummaries());
  }, [dispatch]);

  const progressBarHeight = "3px";
  const inboxWidth = "350px";

  return (
    <FullPageBase fullWidth sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          height: `calc(100% - ${progressBarHeight})`,
          width: "100%",
        }}
      >
        <Inbox width={inboxWidth} />
        <InteractionView width={`calc(100% - ${inboxWidth})`} />
      </Box>
    </FullPageBase>
  );
};

export default Messages;
