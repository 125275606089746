import { Box, Collapse, Stack, SxProps, Theme } from "@mui/material";
import EditSaveCancel, { IEditSaveResetProps } from "./EditSaveCancel";
import BorderedStack from "../layout/BorderedStack";

interface IFormWrapperProps extends IEditSaveResetProps {
  children: any;
  sx?: SxProps<Theme>;
}

const FormWrapper: React.FC<IFormWrapperProps> = (props) => {
  return (
    <BorderedStack sx={{ position: "relative", ...props.sx }} spacing={1}>
      <Box
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          borderRadius: "0 20px",
          position: "absolute",
          bottom: -1,
          left: -1,
          pl: 5,
          pr: 2,
          py: 0.5,
          bgcolor: "grey.100",
        }}
      >
        <EditSaveCancel
          editEnabled={props.editEnabled}
          onEditBtnClick={props.onEditBtnClick}
          onSaveBtnClick={props.onSaveBtnClick}
          onCancelBtnClick={props.onCancelBtnClick}
        />
      </Box>
      {props.children}
    </BorderedStack>
  );
};

export default FormWrapper;
