import { useState, useEffect } from "react";
import { InteractionSummary } from "../types";
import { Box, Typography, Stack, Avatar } from "@mui/material";
import {
  getNameNumberDisplayText,
  getNameInitials,
} from "@/shared/utils/formatters";

import moment from "moment";
import { getPrimaryGradient } from "@/shared/utils/ui";

export interface IInboxItemProps {
  item: InteractionSummary;
  isSelected: boolean;
  onItemClicked: (value: string) => void;
}

const InboxItem: React.FunctionComponent<IInboxItemProps> = (props) => {
  const { item } = props;
  let secondsBeforeRefresh = 30;

  const [timeSince, setTimeSince] = useState(
    getTimeSince(item.lastMsg.createdAt),
  );

  useEffect(() => {
    setTimeSince(getTimeSince(item.lastMsg.createdAt));

    const intervalId = setInterval(() => {
      setTimeSince(getTimeSince(item.lastMsg.createdAt));
    }, secondsBeforeRefresh * 1000);

    return () => clearInterval(intervalId);
  }, [item.lastMsg.createdAt, secondsBeforeRefresh]);

  const allowedMsgBodyLength = 35;
  let displayMsgBody = item.lastMsg.body;

  if (displayMsgBody.length > allowedMsgBodyLength) {
    displayMsgBody = `${displayMsgBody.slice(0, allowedMsgBodyLength - 5)}...`;
  }

  const avatarWidth = "46px";
  const nameInitials =
    getNameInitials(item.firstName, item.lastName) || undefined;

  return (
    <Box
      sx={{
        backgroundImage: (theme) =>
          props.isSelected ? getPrimaryGradient(160) : undefined,
        bgcolor: (theme) =>
          props.isSelected ? undefined : theme.palette.grey[100],
        borderRadius: "10px",
        padding: "10px",
        "&:hover": {
          background: (theme) =>
            props.isSelected ? undefined : theme.palette.grey[300],
        },
        cursor: "pointer",
      }}
      onClick={() => {
        props.onItemClicked(item.contactID);
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center", width: "100%" }}>
        {/* Avatar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: avatarWidth,
          }}
        >
          <Avatar
            sx={{
              bgcolor: props.isSelected ? "grey.100" : "grey.500",
              color: props.isSelected ? "primary.main" : "white",
              width: "40px",
              height: "40px",
            }}
          >
            {nameInitials && <Typography>{nameInitials}</Typography>}
          </Avatar>
        </Box>

        <Box sx={{ width: `calc(100% - ${avatarWidth})` }}>
          {/* Name */}
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              mb: "2px",
            }}
          >
            <Typography
              sx={{
                ml: "5px",
                fontWeight: "bold",

                color: props.isSelected ? "grey.100" : undefined,
              }}
            >
              {getNameNumberDisplayText(
                item.firstName,
                item.lastName,
                item.phoneNumber,
              )}
            </Typography>

            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Typography
                sx={{
                  color: props.isSelected ? "grey.100" : "text.secondary",
                  fontSize: 12,
                  mr: "8px",
                }}
              >
                {timeSince}
              </Typography>

              {item.numUnread ? (
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: (theme) =>
                      props.isSelected
                        ? theme.palette.grey[100]
                        : getPrimaryGradient(145),

                    py: "1px",
                    px: "8px",
                    borderRadius: "15px",

                    color: props.isSelected ? "primary" : "white",
                    fontSize: 12,
                  }}
                >
                  {item.numUnread}
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          {/* Message Preview */}
          <Typography
            sx={{
              pr: 1,
              pl: 0.5,
              fontSize: 14,
              color: props.isSelected ? "grey.100" : "text.secondary",
              lineHeight: "1.2em",
              height: "2.4em",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
            }}
          >
            {item.lastMsg.body}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

function getTimeSince(lastMsgDateStr: string): string {
  const lastMsgDate = moment(lastMsgDateStr).local();
  const currentTime = moment();
  const elapsedMinutes = currentTime.diff(lastMsgDate, "minutes");
  const elapsedHours = currentTime.diff(lastMsgDate, "hours");
  const elapsedDays = currentTime.diff(lastMsgDate, "days");

  if (elapsedMinutes < 60) {
    if (elapsedMinutes === 1) {
      return `${elapsedMinutes} min ago`;
    } else {
      return `${elapsedMinutes} min ago`;
    }
  } else if (elapsedHours < 48) {
    if (elapsedHours === 1) {
      return `${elapsedHours} hour ago`;
    } else {
      return `${elapsedHours} hours ago`;
    }
  } else {
    if (elapsedDays === 1) {
      return `${elapsedDays} day ago`;
    } else {
      return `${elapsedDays} days ago`;
    }
  }
}

export default InboxItem;
