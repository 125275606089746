const iconWidth = "20px";
const timeSentWidth = "100px";

export const sharedTemplateConfig = {
  iconWidth,
  msgBody: {
    baseStyles: {
      maxWidth: `calc(70% - ${iconWidth} - ${timeSentWidth})`,
      py: "4px",
      px: "10px",
    },
    borderRadius: "20px",
    fontSize: 13.5,
  },
  timeSent: {
    width: timeSentWidth,
    fontSize: 10,
  },
};

export function isEmojiMsg(char: string, type: "employee" | "contact") {
  const targetLen = type === "employee" ? 2 : 3;

  return char && char.length <= targetLen && /\p{Emoji}/u.test(char);
}
