import { Box, Popover, PopoverVirtualElement } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface IActionsPopoverProps {
  open: boolean;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  onClose: () => void;
  children: string | JSX.Element | JSX.Element[] | boolean | any;
}

const ActionsPopover: React.FunctionComponent<IActionsPopoverProps> = (
  props,
) => {
  const theme = useTheme();

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          borderRadius: "5px",
          boxShadow: `-2px 2px 5px ${theme.palette.grey[500]}`,
          border: `2px solid ${theme.palette.grey[400]}`,
        },
      }}
    >
      <Box
        sx={{
          textAlign: "left",
          color: "text.secondary",
          p: "5px",
          bgcolor: "white",
        }}
      >
        {props.children}
      </Box>
    </Popover>
  );
};

export default ActionsPopover;
