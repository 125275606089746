import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IAppIconProps } from "../types";
import { defaultStyles } from "./shared";
import { colors } from "@/app/themes";

const CancelIcon: React.FunctionComponent<IAppIconProps> = (props) => {
  const width = props.width || defaultStyles.width;
  const height = props.height || defaultStyles.height;

  return (
    <Stack sx={{ ...props.sx }}>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={0} y1={0} x2={0.75} y2={0.75}>
          <stop offset={0} stopColor={colors.gradient.left} />
          <stop offset={1} stopColor={colors.gradient.right} />
        </linearGradient>
      </svg>
      <CloseIcon
        sx={{
          width,
          height,
          fill: props.colored ? "url(#linearColors)" : "text.primary",
        }}
      />
    </Stack>
  );
};

export default CancelIcon;
