"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeDelayUnit = exports.FlowStepType = void 0;
var FlowStepType;
(function (FlowStepType) {
    FlowStepType["TimeDelay"] = "time_delay";
    FlowStepType["Message"] = "message";
})(FlowStepType = exports.FlowStepType || (exports.FlowStepType = {}));
var TimeDelayUnit;
(function (TimeDelayUnit) {
    TimeDelayUnit["Minutes"] = "minutes";
    TimeDelayUnit["Hours"] = "hours";
    TimeDelayUnit["Days"] = "days";
    TimeDelayUnit["Weeks"] = "weeks";
})(TimeDelayUnit = exports.TimeDelayUnit || (exports.TimeDelayUnit = {}));
