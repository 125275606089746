import { Box, Stack } from "@mui/material";

import { IAppIconProps } from "../types";
import { defaultStyles } from "./shared";
import SVG from "@/assets/images/icons/contact.svg";
import GradientSVG from "@/assets/images/icons/contact-gradient.svg";

const ContactIcon: React.FunctionComponent<IAppIconProps> = (props) => {
  const width = props.width || defaultStyles.width - 2;
  const height = props.height || defaultStyles.height - 2;

  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "center", ...props.sx }}
    >
      <Box
        component="img"
        sx={{ width, height }}
        src={props.colored ? GradientSVG : SVG}
      />
    </Stack>
  );
};

export default ContactIcon;
