import { Stack } from "@mui/material";
import LocationForm, { ILocationFormProps } from "../../form";
import useLocationUpsert from "../../form/useLocationUpsert";
import FormFloatingFooter from "@/components/forms/FormFloatingFooter";
import { useState } from "react";
import { EditSaveCancel, FormWrapper } from "@/components/forms";

interface ILocationSettingsProps {
  locationID?: string;
}

const LocationSettings: React.FC<ILocationSettingsProps> = (props) => {
  const {
    location: locationFormData,
    reset,
    update,
  } = useLocationUpsert(props.locationID);

  const [editEnabled, setEditEnabled] = useState(false);

  return (
    <Stack sx={{ width: "100%" }}>
      <FormWrapper
        editEnabled={editEnabled}
        onEditBtnClick={() => setEditEnabled(true)}
        onSaveBtnClick={() => {
          setEditEnabled(false);
          update();
        }}
        onCancelBtnClick={() => {
          setEditEnabled(false);
          reset();
        }}
        sx={{ mt: 1 }}
      >
        <LocationForm {...locationFormData} editEnabled={editEnabled} />
      </FormWrapper>
    </Stack>
  );
};

export default LocationSettings;
