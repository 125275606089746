import {
  Box,
  Button,
  Stack,
  SvgIconTypeMap,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import AlexGradientText from "../text/AlexGradientText";
import { getPrimaryGradient } from "@/shared/utils/ui";
import IconGradientWrapper from "../icons/IconGradientWrapper";

export interface IAppTabProps {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  iconSx?: SxProps<Theme>;
  isSelected?: boolean;
  onClick: () => void;
}

const AppTab: React.FC<IAppTabProps> = (props) => {
  const { isSelected, icon: Icon, iconSx, title, onClick } = props;

  return (
    <Stack
      onClick={() => onClick()}
      sx={{
        bgcolor: isSelected ? "primary.light" : "grey.100",
        color: "text.secondary",
        pt: 1,
        borderRadius: "5px 5px 0 0",
        userSelect: "none",

        "&:hover": {
          bgcolor: "default",
          fontWeight: "bold",
          cursor: "pointer",
        },
      }}
    >
      <Stack direction="row" sx={{ mx: 1.5, mb: 0.5 }}>
        {isSelected ? (
          <>
            <IconGradientWrapper sx={{ fontSize: 18, mr: 0.5, ...iconSx }}>
              {Icon}
            </IconGradientWrapper>
            <AlexGradientText bold>{title}</AlexGradientText>
          </>
        ) : (
          <>
            <Icon sx={{ fontSize: 18, mr: 0.5, ...iconSx }} />
            <Typography sx={{ fontWeight: "inherit" }}>{title}</Typography>
          </>
        )}
      </Stack>

      <Stack
        sx={{
          height: "2px",
          width: "100%",
          background: isSelected ? getPrimaryGradient(165) : "none",
          borderRadius: "10px",
        }}
      />
    </Stack>
  );
};

export default AppTab;
