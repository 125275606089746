import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import momentTz from "moment-timezone";
import tinycolor from "tinycolor2";

import { GraphTitle } from "@/components/graphs";
import { IDateStatAggregate } from "@alex/types";
import VisitsByHourGraphSingleBox from "./SingleBox";
import { colors } from "@/app/themes";

interface IVisitsByHourGraphProps {
  data?: IDateStatAggregate[];
}

interface BoxColor {
  bgColor: string;
  shadowColor: string;
}

const baseColor = "#1E2DFA";

const COLORS: BoxColor[] = [
  {
    bgColor: tinycolor(baseColor).darken(-35).toString(),
    shadowColor: tinycolor(baseColor).darken(-32).toString(),
  },
  {
    bgColor: tinycolor(baseColor).darken(-20).toString(),
    shadowColor: tinycolor(baseColor).darken(-15).toString(),
  },
  {
    bgColor: baseColor,
    shadowColor: tinycolor(baseColor).darken(-5).toString(),
  },
  {
    bgColor: tinycolor(baseColor).darken(20).toString(),
    shadowColor: tinycolor(baseColor).darken(25).toString(),
  },
  {
    bgColor: tinycolor(baseColor).darken(35).toString(),
    shadowColor: tinycolor(baseColor).darken(40).toString(),
  },
];

const VisitsByHourGraph: React.FC<IVisitsByHourGraphProps> = (props) => {
  const maxValue = Math.max(...(props.data?.map((el) => el.count) || []));

  function getBoxColor(count: number): BoxColor {
    if (!count) {
      return {
        bgColor: colors.grey[200],
        shadowColor: colors.grey[500],
      };
    }

    const numColors = COLORS.length;
    const colorIndex = Math.min(
      Math.floor((count / maxValue) * numColors),
      numColors - 1,
    );

    return COLORS[colorIndex];
  }

  return (
    <Stack
      sx={{
        bgcolor: "grey.100",
        width: "fit-content",
        p: 3,
        borderRadius: "20px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
        }}
      >
        <GraphTitle>Visits by Hour</GraphTitle>

        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
          spacing={0.75}
        >
          <Typography
            sx={{
              fontSize: 13,
              color: "grey.500",
            }}
          >
            Low
          </Typography>

          {COLORS.map((color) => {
            return (
              <VisitsByHourGraphSingleBox key={color.bgColor} color={color} />
            );
          })}

          <Typography
            sx={{
              fontSize: 13,
              color: "grey.500",
            }}
          >
            High
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        columns={26}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "780px",
          zIndex: 10,
        }}
      >
        <Grid item xs={2} sx={{ zIndex: 10 }} />
        {["12 am", "3 am", "6 am", "9 am", "12 pm", "3 pm", "6 pm", "9 pm"].map(
          (label) => {
            return (
              <Grid item xs={3} key={label}>
                <Typography sx={{ color: "grey.500" }}>{label}</Typography>
              </Grid>
            );
          },
        )}
        {props.data?.map((el, i) => {
          return (
            <Grid
              key={el.date.toString()}
              xs={i % 24 === 0 ? 3 : 1}
              item
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "30px",
                width: "30px",
              }}
            >
              {i % 24 === 0 ? (
                <Box sx={{ width: "60px" }}>
                  <Typography
                    sx={{
                      color: "grey.500",
                      textAlign: "right",
                      pr: 1,
                    }}
                  >
                    {momentTz(el.date).format("ddd")}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              <VisitsByHourGraphSingleBox
                data={el}
                color={getBoxColor(el.count)}
              />
            </Grid>
          );
        })}
      </Grid>

      {!props.data ? (
        <Stack sx={{ width: "100%", alignItems: "end" }}>
          <Skeleton
            variant="rounded"
            color="primary"
            sx={{
              height: "210px",
              bgcolor: "grey.200",
              borderRadius: "5px",
              width: "calc(100% - 60px)",
            }}
          />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default VisitsByHourGraph;
