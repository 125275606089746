import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { Typography } from "@mui/material";

const Test: React.FunctionComponent = () => {
  const sampleText = "The quick brown fox jumps over the lazy dog";
  return (
    <FullPageBase>
      <Typography variant="h1">h1: {sampleText}</Typography>

      <Typography variant="h2">h2: {sampleText}</Typography>

      <Typography variant="h3">h3: {sampleText}</Typography>

      <Typography variant="h4">h4: {sampleText}</Typography>

      <Typography variant="h5">h5: {sampleText}</Typography>

      <Typography variant="h6">h6: {sampleText}</Typography>

      <Typography variant="subtitle1">subtitle1: {sampleText}</Typography>

      <Typography variant="subtitle2">subtitle2: {sampleText}</Typography>

      <Typography variant="body1">body1: {sampleText}</Typography>

      <Typography variant="body2">body2: {sampleText}</Typography>
    </FullPageBase>
  );
};

export default Test;
