import { useEffect } from "react";

import UsersTable from "../overview/UsersTable";
import { useAppDispatch, useAppSelector } from "@/app/state/hooks";
import { getUsers } from "../../slice";
import { IBaseTableProps } from "@/components/tables";

export interface IUserSettingsProps extends IBaseTableProps {}

const UsersSettings: React.FunctionComponent<IUserSettingsProps> = (props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const rows = useAppSelector((state) => state.settings.users);

  return (
    <UsersTable
      rows={rows}
      onRefreshBtnClick={() => dispatch(getUsers())}
      {...props}
    />
  );
};

export default UsersSettings;
