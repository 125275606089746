import { Avatar, Box, Stack, Typography } from "@mui/material";
import moment from "moment";

import { DisplayMessage } from "@/features/messages/types";
import { sharedTemplateConfig as conf, isEmojiMsg } from "./shared";
import EmojiMsgText from "./shared/EmojiMsgText";
import config from "@/config";
import { getPrimaryGradient } from "@/shared/utils/ui";

interface IEmployeeMsgProps {
  msg: DisplayMessage;
}

const EmployeeMsg: React.FunctionComponent<IEmployeeMsgProps> = ({ msg }) => {
  const useEmojiTemplate = isEmojiMsg(msg.body, "employee");

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "end", alignItems: "flex-start", py: "4px" }}
    >
      {/* TIME SENT */}
      <Typography
        sx={{
          width: conf.timeSent.width,
          display: "flex",
          justifyContent: "flex-end",
          mr: "5px",
          color: (theme) => theme.palette.grey[500],
          fontSize: conf.timeSent.fontSize,
        }}
      >
        {moment(msg.createdAt).local().format("h:mm a")}
      </Typography>

      {/* BODY */}
      <Box
        sx={{
          ...conf.msgBody.baseStyles,
          bgcolor: useEmojiTemplate ? undefined : "primary.main",
          display: "inline-block",
          borderRadius: `${conf.msgBody.borderRadius} 0px ${conf.msgBody.borderRadius} ${conf.msgBody.borderRadius}`,
        }}
      >
        {useEmojiTemplate ? (
          <EmojiMsgText>{msg.body}</EmojiMsgText>
        ) : (
          <Typography
            sx={{
              color: "white",
              fontSize: conf.msgBody.fontSize,
            }}
          >
            {msg.body}
          </Typography>
        )}
      </Box>

      {/* ICON */}
      <Box
        sx={{
          width: conf.iconWidth,
          display: "flex",

          ml: "5px",
        }}
      >
        {msg.senderInitials ? (
          <Avatar
            sx={{
              bgcolor: "white",
              color: "primary.main",
              width: "24px",
              height: "24px",
            }}
          >
            <Typography
              sx={{
                background: getPrimaryGradient(130),
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                width: "fit-content",
                ml: -0.5,
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {msg.senderInitials.slice(0, 2)}
            </Typography>
          </Avatar>
        ) : (
          <Box
            component="img"
            sx={{ height: "15px" }}
            src={`${config.cdn.baseURL}/alex-icon-blue.png`}
          />
        )}
      </Box>
    </Stack>
  );
};

export default EmployeeMsg;
