import { Typography } from "@mui/material";

export interface IEmojiMsgTextProps {
  children: string;
}

const EmojiMsgText: React.FunctionComponent<IEmojiMsgTextProps> = (props) => {
  return (
    <Typography
      sx={{
        color: "white",
        fontSize: 28,
        m: -1,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default EmojiMsgText;
