import { useState } from "react";

import { Employee } from "@alex/types";

import { UserRow, IUserRowProps } from "./UserRow";
import { IBaseTableProps, AppTable } from "@/components/tables";
import {
  TableActionsPopover,
  ViewDetailsAction,
} from "@/components/tables/actions";

export interface IUsersTableProps extends IBaseTableProps {
  rows: Employee[] | null;
}

const UsersTable: React.FunctionComponent<IUsersTableProps> = ({
  rows,
  onEntityClick,
  onCreateBtnClick,
  onRefreshBtnClick,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focused, setFocused] = useState<{ id: string; name: string } | null>(
    null,
  );

  function openActionsPopover(
    id: string,
    name: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setFocused({ id, name });
  }

  function closePopover() {
    setIsPopoverOpen(false);
    setAnchorEl(null);
  }

  function handleViewDetailsBtnClick() {
    if (focused) {
      onEntityClick(focused.id);
      closePopover();
    }
  }

  return (
    <>
      <AppTable
        entityName="User"
        searchKeys={["emailAddress", "firstName", "lastName"]}
        columnLabels={[
          "Email Address",
          "Name",
          "Roles",
          "Created At",
          "Updated At",
          "",
        ]}
        rows={rows?.map((row) => {
          let rowProps: IUserRowProps = {
            ...row,
            onEntityClick,
            openActionsPopover,
          };
          return rowProps;
        })}
        rowComponent={UserRow}
        onEntityClick={onEntityClick}
        onCreateBtnClick={onCreateBtnClick}
        onRefreshBtnClick={onRefreshBtnClick}
        sx={{ height: "100%", width: "100%" }}
      />

      <TableActionsPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <ViewDetailsAction onClick={handleViewDetailsBtnClick} />
      </TableActionsPopover>
    </>
  );
};

export default UsersTable;
