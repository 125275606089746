import { Contact } from "@alex/types";

export interface UncreatedMessage {
  fromNum: string;
  toNum: string;
  body: string;
}

export interface Message extends UncreatedMessage {
  contactID: string;
  ID: string;
  businessID: string;
  isRead: boolean;
  isCampaignMsg: boolean;
  createdAt: string;
}

export interface MessageWithSenderInitials extends Message {
  senderInitials?: string;
}

export interface DisplayMessage extends MessageWithSenderInitials {
  type: MessageDisplayTemplateOptions;
}

export interface MessageDateDivider {
  type: MessageDisplayTemplateOptions;
  date: string;
}

export type MessageDisplayItem = DisplayMessage | MessageDateDivider;

export interface InteractionSummary {
  businessID: string;
  contactID: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  lastMsg: Message;
  numUnread: number;
}

// THUNK RESPONSE TYPES
export interface SetSelectedInteractionResponse {
  contact: Contact;
  messages: MessageWithSenderInitials[];
}

// ENUMS
export enum MessageDisplayTemplateOptions {
  SentByEmployee = "sentByEmployee",
  SentByContact = "sentByContact",
  DateDivider = "dateDivider",
}
