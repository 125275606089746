import moment from "moment";
import { DividerWithText } from "@/components/text/DividerWithText";
import { Box } from "@mui/material";

interface IDateDividerProps {
  date: Date;
}

const DateDivider: React.FunctionComponent<IDateDividerProps> = (props) => {
  return (
    <Box sx={{ py: "7px" }}>
      <DividerWithText width="240px">
        {moment(props.date).local().format("ddd, MMM D")}
      </DividerWithText>
    </Box>
  );
};

export default DateDivider;
