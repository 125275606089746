import { TableCell, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export interface IActionsTableCellProps {
  onClick: (target: React.MouseEvent<HTMLButtonElement>) => void;
}

const ActionsTableCell: React.FunctionComponent<IActionsTableCellProps> = (
  props,
) => {
  return (
    <TableCell align="right">
      <IconButton
        size="small"
        onClick={(e) => {
          props.onClick?.(e);
        }}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[400]}`,
          borderRadius: 2,
          mr: 2,
        }}
      >
        <MoreHorizIcon sx={{ fontSize: 14, color: "text.secondary" }} />
      </IconButton>
    </TableCell>
  );
};

export default ActionsTableCell;
