import { AppContext } from "@/app/context/AppContext";
import { useAppDispatch } from "@/app/state/hooks";
import { addNewToast } from "@/services/ui/uiSlice";
import { FormElementState } from "@/shared";
import {
  notEmptyValidator,
  validNumberValidator,
} from "@/shared/utils/validators";
import { IInstoreCaptureUIConfig } from "@alex/types";
import { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { ITabletScreensSettingsStateProps } from "./tabletScreens";

interface RewardsAppSettingsState {
  hasChanged: boolean;
  save: () => void;
  reset: () => void;
  tabletScreens: ITabletScreensSettingsStateProps;
}

const useRewardsAppSettings = (): RewardsAppSettingsState => {
  const [hasChanged, setHasChanged] = useState(false);
  const [logoImageUrl, setLogoImageUrl] = useState<string>();
  const [logoImageUrlErrorMsg, setLogoImageUrlErrorMsg] = useState<
    string | undefined
  >();
  const [bodyImageUrl, setBodyImageUrl] = useState<string>();
  const [bodyImageUrlErrorMsg, setBodyImageUrlErrorMsg] = useState<
    string | undefined
  >();
  const [title, setTitle] = useState<string>();
  const [titleErrorMsg, setTitleErrorMsg] = useState<string | undefined>();
  const [subTitle, setSubTitle] = useState<string>();
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState<
    string | undefined
  >();
  const [minutesBetweenVisits, setMinutesBetweenVisits] = useState(180);
  const [minutesBetweenVisitsErrorMsg, setMinutesBetweenVisitsErrorMsg] =
    useState<string | undefined>();

  const [updatedAt, setUpdatedAt] = useState<Date>();

  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const { data: savedConfig, mutate } = useSWR<IInstoreCaptureUIConfig>(
    "/api/s/instore-capture/ui-config",
  );

  useEffect(() => {
    reset();
  }, [savedConfig]);

  useEffect(() => {
    setHasChanged(
      logoImageUrl !== savedConfig?.logoImageUrl ||
        bodyImageUrl !== savedConfig?.bodyImageUrl ||
        title != savedConfig?.title ||
        subTitle != savedConfig?.subTitle,
    );
  }, [logoImageUrl, bodyImageUrl, title, subTitle]);

  function getIsValid(): boolean {
    const inputsToValidate = [
      { value: logoImageUrl, setter: setLogoImageUrlErrorMsg },
      { value: title, setter: setTitleErrorMsg },
      { value: subTitle, setter: setSubTitleErrorMsg },
    ];

    for (const input of inputsToValidate) {
      const isValidRes = notEmptyValidator(input.value, input.setter);
      if (!isValidRes.isValid) {
        return false;
      }
    }

    const minutesBetweenVisitsValid = validNumberValidator(
      minutesBetweenVisits,
      setMinutesBetweenVisitsErrorMsg,
    );

    if (!minutesBetweenVisitsValid) {
      return false;
    }

    return true;
  }

  async function save() {
    const isValid = getIsValid();
    if (!isValid) {
      return;
    }

    try {
      if (hasChanged) {
        const apiRes = await appContext?.api.post<IInstoreCaptureUIConfig>(
          "/api/s/instore-capture/ui-config",
          {
            logoImageUrl,
            bodyImageUrl,
            title,
            subTitle,
          },
        );

        mutate(apiRes);
        setHasChanged(false);
      }

      dispatch(
        addNewToast({
          text: "Saved!",
          severity: "success",
        }),
      );
    } catch (e) {}
  }

  function reset(overrideConfig?: IInstoreCaptureUIConfig) {
    const config = overrideConfig || savedConfig;

    if (config) {
      setLogoImageUrl(config?.logoImageUrl);
      setLogoImageUrlErrorMsg(undefined);

      setBodyImageUrl(config?.bodyImageUrl);
      setBodyImageUrlErrorMsg(undefined);

      setTitle(config?.title);
      setTitleErrorMsg(undefined);

      setSubTitle(config?.subTitle);
      setSubTitleErrorMsg(undefined);

      setUpdatedAt(new Date(config.updatedAt));
    }
  }

  return {
    hasChanged,
    save,
    reset,
    tabletScreens: {
      logoImageUrl: {
        value: logoImageUrl,
        onChange: (e) => setLogoImageUrl(e.target.value),
        errorMsg: logoImageUrlErrorMsg,
        onBlur: () => notEmptyValidator(logoImageUrl, setLogoImageUrlErrorMsg),
      },
      bodyImageUrl: {
        value: bodyImageUrl,
        onChange: (e) => setBodyImageUrl(e.target.value),
        errorMsg: bodyImageUrlErrorMsg,
      },
      title: {
        value: title,
        onChange: (e) => setTitle(e.target.value),
        errorMsg: titleErrorMsg,
        onBlur: () => notEmptyValidator(title, setTitleErrorMsg),
      },
      subTitle: {
        value: subTitle,
        onChange: (e) => setSubTitle(e.target.value),
        errorMsg: subTitleErrorMsg,
        onBlur: () => notEmptyValidator(subTitle, setSubTitleErrorMsg),
      },
      minutesBetweenVisits: {
        value: minutesBetweenVisits,
        onChange: (e) => setMinutesBetweenVisits(parseInt(e.target.value)),
        errorMsg: minutesBetweenVisitsErrorMsg,
        onBlur: () =>
          validNumberValidator(
            minutesBetweenVisits,
            setMinutesBetweenVisitsErrorMsg,
          ),
      },
      updatedAt,
    },
  };
};

export default useRewardsAppSettings;
