import React, { createContext, useReducer, ReactNode } from "react";
import useApi, { ApiState } from "@/hooks/useApi";

interface AppContextType {
  api: ApiState;
}

interface AppContextProviderProps {
  apiKey?: string;
  children: ReactNode;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppContextProvider: React.FC<AppContextProviderProps> = (props) => {
  const api = useApi(props.apiKey);

  return (
    <AppContext.Provider value={{ api }}>{props.children}</AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
