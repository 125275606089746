import { useParams } from "react-router-dom";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SettingsIcon from "@mui/icons-material/Settings";
import queryString from "query-string";
import useSWR from "swr";
import { IStoreLocation } from "@alex/types";

import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { BackButton } from "@/components/buttons";
import PageHeaderText from "@/components/text/PageHeaderText";
import { AppTabGroup, AppTab } from "@/components/tabs";
import { useEffect, useState } from "react";
import LocationAnalytics from "./analytics";
import LocationSettings from "./settings";
import { Stack, Typography } from "@mui/material";
import { useAppDispatch } from "@/app/state/hooks";
import {
  setBackButtonText,
  setBackButtonVisibility,
} from "@/services/ui/uiSlice";

const LocationDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const backLinkOverride = queryString.parse(window.location.search)?.backlink;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { id: locationID } = useParams();

  const { data: location } = useSWR<IStoreLocation>(
    locationID && `api/s/instore-capture/locations/${locationID}`,
  );

  const tabs = [
    {
      title: "Insights",
      icon: AutoGraphIcon,
      component: <LocationAnalytics locationID={locationID} />,
    },
    {
      title: "Settings",
      icon: SettingsIcon,
      component: <LocationSettings locationID={locationID} />,
    },
  ];

  useEffect(() => {
    dispatch(
      setBackButtonText(
        backLinkOverride ? "Back to Dashboard" : "Back to Locations",
      ),
    );
    dispatch(setBackButtonVisibility(true));

    return () => {
      dispatch(setBackButtonVisibility(false));
    };
  }, []);

  if (!location) {
    return <></>;
  }

  return (
    <FullPageBase>
      <Stack sx={{ position: "relative", height: "100%" }}>
        {/* <BackButton sx={{ mb: 1 }} /> */}

        <PageHeaderText small>{location.name}</PageHeaderText>
        <Typography sx={{ color: "text.secondary" }}>Location</Typography>

        <AppTabGroup sx={{ my: 3 }}>
          {tabs.map((tab, i) => {
            return (
              <AppTab
                key={i}
                title={tab.title}
                icon={tab.icon}
                onClick={() => setSelectedTabIndex(i)}
                isSelected={selectedTabIndex === i}
              />
            );
          })}
        </AppTabGroup>
        {tabs[selectedTabIndex].component}
      </Stack>
    </FullPageBase>
  );
};

export default LocationDetails;
