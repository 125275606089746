import { IStoreLocationWithStatus } from "@alex/types";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import LocationsTable from "./table/LocationsTable";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { AppRoute } from "@/app/routes";
import { FormModal } from "@/components/forms";
import LocationForm from "./form";
import { useState } from "react";
import useLocationUpsert from "./form/useLocationUpsert";

interface IStoreLocationsProps {}

const StoreLocations: React.FC<IStoreLocationsProps> = (props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    create,
    reset: resetForm,
    location,
    hasChanged,
  } = useLocationUpsert();

  const { data: locations, mutate: refetchLocations } = useSWR<
    IStoreLocationWithStatus[]
  >("api/s/instore-capture/locations");

  const navigateToLocation = (locationID: string) => {
    navigate(`${AppRoute.InstoreAppLocations}/${locationID}`);
  };

  function closeModal() {
    setIsModalOpen(false);
    resetForm();
  }

  return (
    <FullPageBase>
      <LocationsTable
        rows={locations?.sort((a, b) => (a.numVisits > b.numVisits ? -1 : 1))}
        onEntityClick={(id: string) => {
          navigateToLocation(id);
        }}
        onCreateBtnClick={() => setIsModalOpen(true)}
      />

      {isModalOpen && (
        <FormModal
          headerText="Create a Location"
          onSaveBtnClick={async () => {
            const wasSaved = await create();

            if (wasSaved) {
              refetchLocations();
              closeModal();
            }
          }}
          onCancelBtnClick={closeModal}
        >
          <LocationForm {...location} editEnabled={true} />
        </FormModal>
      )}
    </FullPageBase>
  );
};

export default StoreLocations;
