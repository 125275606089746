import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  Contact,
  ListGroup,
  ListUpdate,
  UncreatedList,
  KVPair,
  Group,
} from "@alex/types";

import { RootState } from "@/app/state/store";
import { apiGet, apiPost, apiPut } from "@/services/api/apiSlice";

interface GroupsState {
  groups: Group[] | null;
}

const initialState: GroupsState = {
  groups: null,
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<Group[]>) {
      state.groups = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
    builder.addCase(createList.fulfilled, (state, action) => {
      // if (action.payload) {
      //   if (state.lists) {
      //     state.lists.push(action.payload);
      //   } else {
      //     state.lists = [action.payload];
      //   }
      // }
    });
  },
});

export const getGroups = createAsyncThunk(
  "groups/getGroups",
  async (_, { dispatch }): Promise<Group[]> => {
    try {
      const lists = await dispatch(apiGet({ url: "/api/e/groups" }));

      if (lists) {
        return lists.payload as Group[];
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export const getListNameIsTaken = createAsyncThunk(
  "lists/getListNameIsTaken",
  async (list: string, { dispatch }): Promise<boolean> => {
    try {
      const res = await dispatch(
        apiGet({ url: `/api/e/lists/is-taken/${list}` }),
      );

      return res.payload.isTaken as boolean;
    } catch (e) {
      console.log(e);
    }

    return false;
  },
);

export const getListNames = createAsyncThunk<
  KVPair[],
  void,
  { rejectValue: { message: string } }
>("lists/getListNameIsTaken", async (_, { getState, dispatch }) => {
  // let lists = (getState() as RootState).groups.groups;

  // if (!lists) {
  //   const listsRes = await dispatch(getGroups());
  //   lists = listsRes.payload as ListGroup[];
  // }

  // return lists.map((el: ListGroup) => {
  //   return { key: el.ID, value: el.name };
  // });

  return [];
});

export const createList = createAsyncThunk<
  ListGroup | null,
  UncreatedList,
  { rejectValue: { message: string } }
>("lists/createList", async (newList: UncreatedList, { dispatch }) => {
  // TODO - could potentially do some validation here

  try {
    const res = await dispatch(apiPost({ url: `/api/e/lists`, body: newList }));

    return res.payload as ListGroup;
  } catch (e) {
    console.log(e);
  }

  return null;
});

export const updateList = createAsyncThunk<
  ListGroup | null,
  { id: string; update: ListUpdate },
  { rejectValue: { message: string } }
>(
  "lists/updateList",
  async (payload: { id: string; update: ListUpdate }, { dispatch }) => {
    // TODO - could potentially do some validation here

    try {
      const res = await dispatch(
        apiPut({ url: `/api/e/lists/${payload.id}`, body: payload.update }),
      );

      return res.payload as ListGroup;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const getListByID = createAsyncThunk<
  ListGroup | null,
  string,
  { rejectValue: { message: string } }
>("lists/getByID", async (listID: string, { dispatch, getState }) => {
  try {
    const res = await dispatch(apiGet({ url: `/api/e/lists/${listID}` }));

    return res.payload as ListGroup;
  } catch (e) {
    console.log(e);
  }

  return null;
});

export const getListContactsByID = createAsyncThunk(
  "lists/getContactsByID",
  async (listID: string, { dispatch }): Promise<Contact[] | null> => {
    try {
      const res = await dispatch(
        apiGet({ url: `/api/e/lists/${listID}/contacts` }),
      );

      return res.payload as Contact[];
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const getListNameByID = createAsyncThunk(
  "lists/getNameByID",
  async (
    listID: string,
    { dispatch, getState },
  ): Promise<string | undefined> => {
    // let lists = (getState() as RootState).lists.lists;

    // if (!lists) {
    //   const apiRes = await dispatch(getGroups());

    //   if (apiRes.payload) {
    //     lists = apiRes.payload as ListGroup[];
    //   }
    // }

    // const targetList = lists?.find((el) => el.ID === listID);

    // return targetList?.name;

    return undefined;
  },
);

export default groupsSlice.reducer;
