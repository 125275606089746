import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { BlastCampaign, UncreatedBlastCampaign } from "@alex/types";

import { apiGet, apiPost } from "@/services/api/apiSlice";

interface CampaignsState {
  blastCampaigns: BlastCampaign[] | null;
}

const initialState: CampaignsState = {
  blastCampaigns: null,
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setBlastCampaigns(state, action: PayloadAction<BlastCampaign[]>) {
      state.blastCampaigns = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getBlastCampaigns.fulfilled, (state, action) => {
      state.blastCampaigns = action.payload;
    });
    builder.addCase(createBlastCampaign.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.blastCampaigns) {
          state.blastCampaigns.push(action.payload);
        } else {
          state.blastCampaigns = [action.payload];
        }
      }
    });
  },
});

export const getBlastCampaigns = createAsyncThunk(
  "campaigns/getBlastCampaigns",
  async (_, { dispatch }): Promise<BlastCampaign[]> => {
    try {
      const { payload } = await dispatch(
        apiGet({ url: "/api/e/campaigns/blasts" }),
      );

      if (payload) {
        return payload;
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export const getBlastCampaignByID = createAsyncThunk(
  "campaigns/getBlastCampaignByID",
  async (campaignID: string, { dispatch }): Promise<BlastCampaign | null> => {
    try {
      // TODO - update this to look in state.blastCampaigns before calling the API
      const { payload } = await dispatch(
        apiGet({ url: `/api/e/campaigns/blasts/${campaignID}` }),
      );

      if (payload) {
        return payload;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const createBlastCampaign = createAsyncThunk(
  "campaigns/createBlastCampaign",
  async (
    newBlast: UncreatedBlastCampaign,
    { dispatch },
  ): Promise<BlastCampaign | null> => {
    try {
      const { payload } = await dispatch(
        apiPost({ url: "/api/s/campaign-brain/blaster/send", body: newBlast }),
      );

      if (payload) {
        return payload;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export default campaignsSlice.reducer;
