import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { getNumUnreadMessages } from "@/features/messages/slice";
import { useAppSelector } from "@/app/state/hooks";
import { getPrimaryGradient } from "@/shared/utils/ui";

const InboxAdornment: React.FunctionComponent = () => {
  const msgsState = useAppSelector((state) => state.messages);
  const initialCount = getNumUnreadMessages(msgsState);

  const [numUnreadMsgs, setNumUnreadMsgs] = useState(initialCount);

  useEffect(() => {
    setNumUnreadMsgs(getNumUnreadMessages(msgsState));
  }, [msgsState]);

  return (
    <>
      {numUnreadMsgs > 0 && (
        <Box
          sx={{
            background: getPrimaryGradient(135),
            px: 1.5,
            py: "1px",
            borderRadius: "15px",
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              color: "white",
            }}
          >
            {numUnreadMsgs}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InboxAdornment;
