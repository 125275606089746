import { useState, useEffect } from "react";
import { Stack, Box, Slide, Drawer, Skeleton } from "@mui/material";

import { getNavItems } from "@/app/routes";
import { INavItem } from "@/app/routes/types";
import { useAppSelector } from "@/app/state/hooks";
import AppNavItem from "./NavItem";
import BusinessDisplay from "./BusinessDisplay";
import config from "@/config";

export interface ISidebarProps {
  sidebarWidth: string;
  headerHeight: string;
  onLogoClick?: () => void;
}

const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const [navItems, setNavItems] = useState<INavItem[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const { me, myBusiness } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (me) {
      setNavItems(getNavItems(me.roles));
    } else {
      setNavItems([]);
    }
  }, [me]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={true}
      sx={{
        height: "100%",
        "& .MuiDrawer-paper": {
          borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`,
        },
      }}
      elevation={0}
    >
      <Box
        sx={{
          zIndex: 1,
          height: "100%",
          width: props.sidebarWidth,
          flexShrink: 0,
          bgcolor: "grey.100",
        }}
      >
        <Stack
          direction="column"
          sx={{
            height: `100%`,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                props.onLogoClick?.();
                setIsHovered(false);
              }}
              component="img"
              sx={{
                height: "30px",
                m: 2,
                width: "180px",
                cursor: "pointer",
              }}
              src={
                isHovered
                  ? `${config.cdn.baseURL}/alex-logo-hovered.png`
                  : `${config.cdn.baseURL}/alex-logo.png`
              }
            />

            <Stack direction="column">
              <Stack sx={{ pt: 1, px: 1 }}>
                {navItems.length ? (
                  <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    <Box>
                      {navItems
                        .filter((item) => !item.putOnSidebarBottom)
                        .map((item) => {
                          return <AppNavItem key={item.path} item={item} />;
                        })}
                    </Box>
                  </Slide>
                ) : (
                  <>
                    {[0, 1, 2, 3].map((i) => {
                      let width = "80%";

                      switch (i) {
                        case 1:
                          width = "50%";
                          break;
                        case 2:
                          width = "72%";
                          break;
                        case 3:
                          width = "43%";
                          break;
                      }

                      return (
                        <Skeleton
                          key={i}
                          variant="text"
                          sx={{ width, height: "2rem", ml: 1 }}
                        />
                      );
                    })}
                  </>
                )}
              </Stack>
            </Stack>
          </Box>

          <Box>
            <Stack sx={{ px: 1 }}>
              {navItems
                .filter((item) => item.putOnSidebarBottom)
                .map((item) => {
                  return <AppNavItem key={item.path} item={item} />;
                })}
            </Stack>

            <BusinessDisplay
              name={myBusiness?.name}
              phoneNumber={myBusiness?.phoneNumber}
            />
          </Box>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
