import {
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  Skeleton,
} from "@mui/material";
import SingleBar from "./SingleBar";

import { GraphTitle } from "@/components/graphs";
import { ContactVisitsAggregate } from "@alex/types";

interface IInstoreVisitsGraphProps {
  data?: ContactVisitsAggregate[];
  sx?: SxProps<Theme>;
}

// NOTE: There's an error with there not being a top-level key for the .map. I'm not fixing this right now because I'm eventually
// going to replace this graph with a recharts graph.

const VisitsByVisitCountGraph: React.FC<IInstoreVisitsGraphProps> = (props) => {
  const { data } = props;
  const maxNumVisits = Math.max(...(data?.map((el) => el.numContacts) || []));

  const labels = [
    0,
    Math.floor(maxNumVisits * 0.25),
    Math.floor(maxNumVisits * 0.5),
    Math.floor(maxNumVisits * 0.75),
    maxNumVisits,
  ];

  return (
    <Stack sx={{ ...props.sx }}>
      <GraphTitle>Visits by Visit Count</GraphTitle>

      {data ? (
        <Grid container sx={{ display: "flex", height: "90%" }}>
          {data?.map((el, i) => {
            return (
              <>
                <Grid item xs={4} md={3} lg={2}>
                  <Stack
                    sx={{
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                      textAlign: "end",
                      pr: 1,
                    }}
                  >
                    <Typography sx={{ color: "grey.500", fontSize: 14 }}>
                      {`${el.numVisits}${el.numVisits === 10 ? "+" : ""} Visit${
                        el.numVisits > 1 ? "s" : ""
                      }`}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8} md={9} lg={10}>
                  <Stack
                    sx={{
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                      textAlign: "end",
                      pr: 1,
                    }}
                  >
                    <SingleBar
                      percentageFilled={
                        (el.numContacts / (maxNumVisits || 1)) * 100
                      }
                      numContacts={el.numContacts}
                      title={`${el.numVisits}${
                        el.numVisits === 10 ? "+" : ""
                      } Visit${el.numVisits > 1 ? "s" : ""}`}
                    />
                  </Stack>
                </Grid>
              </>
            );
          })}

          <Grid item xs={4} md={3} lg={2} />
          <Grid item xs={8} md={9} lg={10}>
            <Stack
              direction="row"
              sx={{
                alignItems: "center",

                justifyContent: "space-between",
              }}
            >
              {labels.map((el, i) => (
                <Typography key={i} sx={{ color: "grey.500", fontSize: 14 }}>
                  {el}
                </Typography>
              ))}
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <Skeleton variant="rounded" sx={{ width: "100%", height: "90%" }} />
      )}
    </Stack>
  );
};

export default VisitsByVisitCountGraph;
