import { useEffect, useRef, useState } from "react";
import {
  DisplayMessage,
  MessageDateDivider,
  MessageDisplayTemplateOptions,
  MessageDisplayItem,
  Message,
} from "@/features/messages/types";
import { Box } from "@mui/material";
import DateDivider from "./msgTemplates/DateDivider";
import ContactMsg from "./msgTemplates/ContactMsg";
import EmployeeMsg from "./msgTemplates/EmployeeMsg";
import _ from "lodash";
import { useAppDispatch } from "@/app/state/hooks";
import { markMsgsRead } from "@/features/messages/slice";

interface IMsgListProps {
  items: MessageDisplayItem[];
}

const MsgList: React.FunctionComponent<IMsgListProps> = ({ items }) => {
  const dispatch = useAppDispatch();

  // scroll logic
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = (useSmooth = false) => {
    const scrollBehavior = useSmooth ? "smooth" : "auto";
    const element = scrollBottomRef.current;
    if (element) {
      element.scrollIntoView({ behavior: scrollBehavior });
    }
  };

  const [currContactID, setCurrContactID] = useState("");
  const [currNumMsgs, setCurrNumMsgs] = useState(0);

  useEffect(() => {
    const firstMsg = items.find((el) => {
      return (
        el.type === MessageDisplayTemplateOptions.SentByEmployee ||
        el.type === MessageDisplayTemplateOptions.SentByContact
      );
    }) as DisplayMessage;

    if (firstMsg && firstMsg.contactID !== currContactID) {
      setCurrContactID(firstMsg.contactID);
      scrollToBottom();
    }

    if (items.length !== currNumMsgs) {
      setCurrNumMsgs(items.length);
      scrollToBottom(true);
    }
  }, [items, currContactID, currNumMsgs]);

  function useReadMsgsCollector(): (msg: Message) => void {
    const [readMsgs, setReadMsgs] = useState<Message[]>([]);

    useEffect(() => {
      const updateReadMsgsDebounced = _.debounce(() => {
        if (readMsgs.length) {
          dispatch(markMsgsRead(readMsgs));

          setReadMsgs([]);
        }
      }, 1000);

      updateReadMsgsDebounced();

      return updateReadMsgsDebounced.cancel;
    }, [readMsgs]);

    const handleReadMsg = (msg: Message) => {
      setReadMsgs((prevReadMsgs) => {
        const newReadMsgs = [...prevReadMsgs, msg];
        return _.uniq(newReadMsgs);
      });
    };

    return handleReadMsg;
  }

  const handleReadMsg = useReadMsgsCollector();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxHeight: "100%",
          width: "100%",
          position: "absolute",
          bottom: "0px",
          pr: "15px",
        }}
      >
        {items.map((el) => {
          switch (el.type) {
            case MessageDisplayTemplateOptions.DateDivider:
              return (
                <DateDivider
                  key={(el as MessageDateDivider).date}
                  date={new Date((el as MessageDateDivider).date)}
                />
              );
            case MessageDisplayTemplateOptions.SentByContact:
              return (
                <ContactMsg
                  key={(el as DisplayMessage).ID}
                  msg={el as DisplayMessage}
                  onMsgRead={handleReadMsg}
                />
              );
            case MessageDisplayTemplateOptions.SentByEmployee:
              return (
                <EmployeeMsg
                  key={(el as DisplayMessage).ID}
                  msg={el as DisplayMessage}
                />
              );
          }

          return <></>;
        })}
        <Box ref={scrollBottomRef} />

        <Box sx={{ height: "15px" }} />
      </Box>
    </Box>
  );
};

export default MsgList;
