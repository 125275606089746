import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";

import config from "@/config";
import { RootState } from "@/app/state/store";

interface ApiState {
  pendingCount: number;
  hasConnectionError: boolean;
}

const initialState: ApiState = {
  pendingCount: 0,
  hasConnectionError: false,
};

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    setHasConnectionError(state, action: PayloadAction<boolean>) {
      state.hasConnectionError = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(apiGet.pending, (state, _action) => {
      state.pendingCount += 1;
    });
    builder.addCase(apiGet.fulfilled, (state, _action) => {
      state.pendingCount -= 1;
    });
    builder.addCase(apiGet.rejected, (state, _action) => {
      state.pendingCount -= 1;
    });
    builder.addCase(apiPut.pending, (state, _action) => {
      state.pendingCount += 1;
    });
    builder.addCase(apiPut.fulfilled, (state, _action) => {
      state.pendingCount -= 1;
    });
    builder.addCase(apiPut.rejected, (state, _action) => {
      state.pendingCount -= 1;
    });
    builder.addCase(apiPost.pending, (state, _action) => {
      state.pendingCount += 1;
    });
    builder.addCase(apiPost.fulfilled, (state, _action) => {
      state.pendingCount -= 1;
    });
    builder.addCase(apiPost.rejected, (state, _action) => {
      state.pendingCount -= 1;
    });
  },
});

const api = axios.create({
  baseURL: config.api.baseURL,
});

function getAxiosConfig(apiToken: string): AxiosRequestConfig {
  return { headers: { Authorization: `Bearer ${apiToken}` } };
}

const MAX_RETRIES = 4;
const INITIAL_BACKOFF_DELAY = 500;

const { setHasConnectionError } = apiSlice.actions;

export const apiGet = createAsyncThunk<
  any,
  { url: string; apiToken?: string },
  { rejectValue: { message: string } }
>(
  "api/apiGet",
  async (req: { url: string; apiToken?: string }, { getState, dispatch }) => {
    let apiToken: string;

    let retries = 0;
    let currDelay = INITIAL_BACKOFF_DELAY;

    // exponential back-off for Auth loading issue
    while (retries <= MAX_RETRIES) {
      let { auth: authState } = getState() as RootState;

      if (req.apiToken) {
        apiToken = req.apiToken;
      } else {
        apiToken = authState.apiToken;
      }

      if (!apiToken) {
        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, currDelay));
        continue;
      }

      const config = getAxiosConfig(apiToken);

      try {
        const res = await api.get(req.url, config);

        dispatch(setHasConnectionError(false));
        return res.data;
      } catch (e) {
        dispatch(setHasConnectionError(true));
        console.log("error in apiGet", e);
        return null;
      }
    }

    // exceeded maxRetries. Log user out
    console.log("a");
  },
);

export const apiPut = createAsyncThunk<
  any,
  { url: string; body: any },
  { rejectValue: { message: string } }
>("api/apiPut", async (req: { url: string; body: string }, { getState }) => {
  const { auth: authState } = getState() as RootState;
  const apiToken = authState.apiToken;

  const config = getAxiosConfig(apiToken);

  try {
    const res = await api.put(req.url, req.body, config);

    return res.data;
  } catch (e) {
    console.log("error in apiPut", e);
    return null;
  }
});

export const apiPost = createAsyncThunk<
  any,
  { url: string; body: any },
  { rejectValue: { message: string } }
>("api/apiPost", async (req: { url: string; body: string }, { getState }) => {
  const { apiToken } = (getState() as RootState).auth;

  const config = getAxiosConfig(apiToken);

  try {
    const res = await api.post(req.url, req.body, config);

    return res.data;
  } catch (e) {
    console.log("error in apiPost", e);
    return null;
  }
});

export const apiDelete = createAsyncThunk<
  any,
  { url: string },
  { rejectValue: { message: string } }
>("api/apiPost", async (req: { url: string }, { getState }) => {
  const { apiToken } = (getState() as RootState).auth;

  const config = getAxiosConfig(apiToken);

  try {
    const res = await api.delete(req.url, config);

    return res.data;
  } catch (e) {
    console.log("error in apiDelete", e);
    return null;
  }
});

export default apiSlice.reducer;
