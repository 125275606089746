import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import moment from "moment";

import { DisplayMessage, Message } from "@/features/messages/types";
import { Box, Stack, Typography } from "@mui/material";
import { sharedTemplateConfig as conf, isEmojiMsg } from "./shared";
import EmojiMsgText from "./shared/EmojiMsgText";

interface IContactMsgProps {
  msg: DisplayMessage;
  onMsgRead: (msg: Message) => void;
}

const ContactMsg: React.FunctionComponent<IContactMsgProps> = (props) => {
  const { msg } = props;

  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (!msg.isRead && inView) {
      props.onMsgRead(msg);
    }
    // DO NOT CHANGE DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const useEmojiTemplate = isEmojiMsg(msg.body, "contact");

  return (
    <Stack
      ref={ref}
      direction="row"
      sx={{ alignItems: "flex-start", py: "4px" }}
    >
      <Box
        sx={{
          ...conf.msgBody.baseStyles,
          bgcolor: (theme) =>
            useEmojiTemplate ? undefined : theme.palette.grey[200],
          display: "inline-block",
          borderRadius: `0px ${conf.msgBody.borderRadius} ${conf.msgBody.borderRadius} ${conf.msgBody.borderRadius}`,
        }}
      >
        {useEmojiTemplate ? (
          <EmojiMsgText>{msg.body}</EmojiMsgText>
        ) : (
          <Typography
            sx={{
              fontSize: conf.msgBody.fontSize,
            }}
          >
            {msg.body}
          </Typography>
        )}
      </Box>

      <Typography
        sx={{
          width: conf.timeSent.width,
          display: "flex",
          justifyContent: "flex-start",
          ml: "5px",
          color: (theme) => theme.palette.grey[500],
          fontSize: conf.timeSent.fontSize,
        }}
      >
        {moment(msg.createdAt).format("h:mm a")}
      </Typography>
    </Stack>
  );
};

export default ContactMsg;
