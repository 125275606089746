import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { SWRConfig } from "swr";
import axios from "axios";
import momentTz from "moment-timezone";

import config from "./config";
import { useAppDispatch } from "./app/state/hooks";
import { configureNewAPIToken } from "./features/auth/authSlice";
import AppRoutes from "./app/routes";
import getTheme from "./app/themes";
import "./App.css";
import Lobby from "./app/lobby";
import LoadingPage from "./app/miscPages/Loading";
import { AppContext, AppContextProvider } from "./app/context/AppContext";

function App() {
  // TODO - get this from business
  momentTz.tz.setDefault("US/Pacific");
  const dispatch = useAppDispatch();
  const myTheme = getTheme();
  const [apiKey, setApiKey] = useState("");

  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    async function getUserMetadata() {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: config.auth0.audience,
            scope: "",
          },
        });

        if (accessToken) {
          setApiKey(accessToken);
          dispatch(configureNewAPIToken(accessToken));
        }
      } catch (e) {
        console.log(e);
      }
    }

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, dispatch]);

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
        baseURL: config.api.baseURL,
      })
      .then((res) => res.data);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={myTheme}>
        <CssBaseline />
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            {isAuthenticated ? (
              <AppContextProvider apiKey={apiKey}>
                <SWRConfig
                  value={{
                    fetcher,
                  }}
                >
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </SWRConfig>
              </AppContextProvider>
            ) : (
              <Lobby />
            )}
          </>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
