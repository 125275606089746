import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { Notification } from "@alex/types";

import { apiDelete, apiGet, apiPut } from "@/services/api/apiSlice";
import { configureNewAPIToken } from "@/features/auth/authSlice";

interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<Notification[]>) {
      state.notifications = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(configureNewAPIToken.fulfilled, (state, action) => {
      if (action.payload?.notifications) {
        state.notifications = action.payload.notifications;
      }
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      if (action.payload) {
        state.notifications = action.payload;
      }
    });
    builder.addCase(deleteNotificationByID.fulfilled, (state, action) => {
      if (action.payload) {
        const deletedID = action.payload;

        state.notifications = state.notifications.filter(
          (el) => el.ID !== deletedID,
        );
      }
    });
    builder.addCase(markNotificationReadByID.fulfilled, (state, action) => {
      if (action.payload) {
        const readID = action.payload;

        state.notifications = state.notifications.map((el) =>
          el.ID === readID ? { ...el, isRead: true } : el,
        );
      }
    });
    builder.addCase(markNotificationUnreadByID.fulfilled, (state, action) => {
      if (action.payload) {
        const readID = action.payload;

        state.notifications = state.notifications.map((el) =>
          el.ID === readID ? { ...el, isRead: false } : el,
        );
      }
    });
  },
});

export const getNotifications = createAsyncThunk(
  "notifications/getAll",
  async (_, { dispatch }): Promise<Notification[]> => {
    try {
      const notifications = await dispatch(
        apiGet({ url: "/api/e/notifications" }),
      );

      if (notifications) {
        return notifications.payload as Notification[];
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export const deleteNotificationByID = createAsyncThunk(
  "notifications/deleteByID",
  async (notificationID: string, { dispatch }): Promise<string | null> => {
    try {
      const apiRes = await dispatch(
        apiDelete({
          url: `/api/e/notifications/${notificationID}`,
        }),
      );

      if (apiRes) {
        return notificationID;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const markNotificationReadByID = createAsyncThunk(
  "notifications/markReadByID",
  async (notificationID: string, { dispatch }): Promise<string | null> => {
    try {
      const apiRes = await dispatch(
        apiPut({
          url: `/api/e/notifications/mark-read/${notificationID}`,
          body: null,
        }),
      );

      if (apiRes) {
        return notificationID;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const markNotificationUnreadByID = createAsyncThunk(
  "notifications/markUnreadByID",
  async (notificationID: string, { dispatch }): Promise<string | null> => {
    try {
      const apiRes = await dispatch(
        apiPut({
          url: `/api/e/notifications/mark-unread/${notificationID}`,
          body: null,
        }),
      );

      if (apiRes) {
        return notificationID;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export default notificationsSlice.reducer;
