import { getPrimaryGradient } from "@/shared/utils/ui";
import { TableCell, Link, SxProps, Theme } from "@mui/material";

export interface IClickableTableCellProps {
  children: string | number | null;
  startAdornment?: any;
  onClick: () => void;
  cellSx?: SxProps<Theme>;
  linkSx?: SxProps<Theme>;
}

const ClickableTableCell: React.FunctionComponent<IClickableTableCellProps> = (
  props,
) => {
  return (
    <TableCell sx={{ width: "18%", ...props.cellSx }}>
      {props.startAdornment}
      <Link
        component="button"
        onClick={props.onClick}
        sx={{
          textDecoration: "none",
          fontSize: 15,
          color: "text.primary",
          background: getPrimaryGradient(155),
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
          width: "fit-content",

          "&:hover": {
            fontWeight: "bold",
          },
          ...props.linkSx,
        }}
      >
        {props.children}
      </Link>
    </TableCell>
  );
};

export default ClickableTableCell;
