import { Avatar, Box, Chip, Divider, Stack, Typography } from "@mui/material";
import SnoozeIcon from "@mui/icons-material/Snooze";
import CheckIcon from "@mui/icons-material/Check";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useAppSelector } from "@/app/state/hooks";
import {
  getNameNumberDisplayText,
  getNameInitials,
} from "@/shared/utils/formatters";

export interface IInteractionHeaderProps {
  height: string;
}

const InteractionHeader: React.FunctionComponent<IInteractionHeaderProps> = (
  props,
) => {
  const selectedContact = useAppSelector(
    (state) => state.messages.selectedInteraction?.contact,
  );

  if (!selectedContact) {
    return <></>;
  }

  const iconSpacing = "15px";

  const displayText = getNameNumberDisplayText(
    selectedContact.firstName,
    selectedContact.lastName,
    selectedContact.phoneNumber,
  );

  const nameInitials =
    getNameInitials(
      selectedContact.firstName || "",
      selectedContact.lastName || "",
    ) || undefined;

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          height: props.height,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: "10px",
        }}
      >
        {/* LEFT */}
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Avatar
            sx={{
              bgcolor: "grey.500",
              color: "white",
              width: "40px",
              height: "40px",
            }}
          >
            {nameInitials && (
              <Typography sx={{ fontSize: 18 }}>{nameInitials}</Typography>
            )}
          </Avatar>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              pl: "10px",
            }}
          >
            {displayText}
          </Typography>

          {selectedContact.isOptedOut && (
            <Chip
              label="Opted Out"
              color="error"
              variant="filled"
              size="small"
              sx={{ ml: 2 }}
            />
          )}
        </Stack>

        {/* RIGHT */}
        <Box display="flex">
          <SnoozeIcon color="primary" sx={{ mr: iconSpacing }} />
          <CheckIcon color="primary" sx={{ mr: iconSpacing }} />
          <ContactPageOutlinedIcon color="primary" sx={{ mr: iconSpacing }} />
          <MoreVertIcon color="primary" />
        </Box>
      </Stack>
      <Divider sx={{ my: "1px", borderBottomWidth: 2, borderRadius: "5px" }} />
    </Box>
  );
};

export default InteractionHeader;
