import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import PageHeaderText from "@/components/text/PageHeaderText";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { AppRoute } from "@/app/routes";
import NavigationTabs, { INavTab } from "@/components/tabs/NavigationTabs";

export interface ISettingsProps {}

const Settings: React.FunctionComponent<ISettingsProps> = (props) => {
  const tabs: INavTab[] = [
    {
      title: "Account",
      navPath: AppRoute.AccountSettings,
      icon: BusinessIcon,
    },
    {
      title: "Users",
      navPath: AppRoute.UserSettings,
      icon: PersonIcon,
    },
    {
      title: "Integrations",
      navPath: AppRoute.IntegrationSettings,
      icon: ElectricBoltIcon,
    },
  ];

  return (
    <FullPageBase>
      <Stack
        sx={{
          height: "110px",
          width: "100%",

          justifyContent: "space-between",
        }}
      >
        <PageHeaderText>Settings</PageHeaderText>

        <NavigationTabs tabs={tabs} />
      </Stack>

      <Box sx={{ height: `calc(100% - 140px)`, width: "100%", mt: 3 }}>
        <Outlet />
      </Box>
    </FullPageBase>
  );
};

export default Settings;
