import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Box, SxProps, Theme, Skeleton } from "@mui/material";
import moment from "moment";

import { colors } from "@/app/themes";
import AlexGradientText from "@/components/text/AlexGradientText";
import MessagesTooltip from "./MessagesTooltip";
import { renderBar } from "./utils";

import { MessageAggregate } from "@alex/types";

import { MessageAggregateDisplay } from "./types";

import { GraphTitle } from "@/components/graphs";

export interface IMessagesGraphProps {
  aggregates?: MessageAggregate[];

  sx?: SxProps<Theme>;
}

const MessagesGraph: React.FunctionComponent<IMessagesGraphProps> = (props) => {
  const { aggregates } = props;

  const [totalMsgs, setTotalMsgs] = useState(0);

  useEffect(() => {
    const tempSum = aggregates?.reduce((acc, agg) => {
      return acc + agg.msgsSent + agg.msgsReceived;
    }, 0);

    setTotalMsgs(tempSum || 0);
  }, [aggregates]);

  const axisStyle = {
    fontSize: "14px",
    fill: colors.grey[500],
  };

  const [barRadius, setBarRadius] = useState(10);
  const [dataWithLabels, setDataWithLabels] =
    useState<MessageAggregateDisplay[]>();

  function getDateDisplay(dt: Date): string {
    return moment(dt).format("MMM D");
  }

  useEffect(() => {
    let labelInterval: number;

    if (!aggregates) {
      return;
    } else if (aggregates.length <= 3) {
      setBarRadius(20);
      labelInterval = 1;
    } else if (aggregates.length <= 7) {
      setBarRadius(15);
      labelInterval = 1;
    } else if (aggregates.length <= 30) {
      setBarRadius(6);
      labelInterval = 8;
    } else if (aggregates.length <= 90) {
      setBarRadius(2.5);
      labelInterval = 25;
    } else {
      setBarRadius(2.5);
      labelInterval = 25;
    }

    const withLabels: MessageAggregateDisplay[] = aggregates.map((el, i) => {
      let label = null;
      if (i % labelInterval === labelInterval - 1) {
        label = getDateDisplay(el.startDate);
      }

      return {
        ...el,
        label,
      };
    });

    withLabels[withLabels.length - 1].label = moment(
      withLabels[withLabels.length - 1].startDate,
    ).format("MMM D");

    if (withLabels.length >= 90) {
      withLabels[2].label = getDateDisplay(withLabels[2].startDate);
    } else if (withLabels.length >= 30) {
      withLabels[1].label = getDateDisplay(withLabels[1].startDate);
    }

    setDataWithLabels(withLabels);
  }, [aggregates]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        ...props.sx,
      }}
    >
      <Box sx={{ height: "22%", mb: "3%" }}>
        <GraphTitle>Messages</GraphTitle>

        {totalMsgs === undefined ? (
          <Skeleton
            variant="rounded"
            sx={{ width: "50px", height: "2.25rem" }}
          />
        ) : (
          <AlexGradientText variant="h2" bold sx={{ paddingBottom: 1.5 }}>
            {totalMsgs}
          </AlexGradientText>
        )}
      </Box>

      <Box sx={{ height: "72%" }}>
        {aggregates ? (
          <ResponsiveContainer width="100%">
            <BarChart data={dataWithLabels} margin={{ right: 20 }}>
              <Tooltip
                content={<MessagesTooltip />}
                cursor={{ fill: "#EDEFF2" }}
                wrapperStyle={{ outline: "none" }}
              />
              <CartesianGrid
                strokeDasharray=""
                vertical={false}
                stroke={colors.grey[400]}
              />

              <XAxis
                dataKey="label"
                tick={axisStyle}
                tickLine={false}
                axisLine={false}
                interval={0}
              />

              <YAxis
                tick={axisStyle}
                tickLine={false}
                axisLine={false}
                width={40}
              />

              <Bar
                dataKey="msgsSent"
                stackId="stack"
                name="Sent"
                shape={(props) => renderBar(props, "msgsSent", barRadius)}
                minPointSize={10}
              />

              <Bar
                dataKey="msgsReceived"
                stackId="stack"
                name="Received"
                shape={(props) => renderBar(props, "msgsReceived", barRadius)}
                minPointSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Skeleton variant="rounded" sx={{ height: "100%", width: "100%" }} />
        )}
      </Box>

      <Box sx={{ height: "3%" }} />
    </Box>
  );
};

export default MessagesGraph;
