import { Button } from "@mui/material";

export interface ICancelButtonProps {
  onClick: () => void;
  isUpdate?: boolean;
}

const CancelButton: React.FunctionComponent<ICancelButtonProps> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        color: "text.secondary",
        borderRadius: "25px",
        px: 1.5,
        "&:hover": {
          bgcolor: "transparent",
          fontWeight: "bold",
        },
      }}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
