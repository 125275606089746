import {
  Box,
  Link,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { IVisitsByLocation } from "@alex/types";

import { GraphTitle } from "@/components/graphs";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { generateGradient, generateShades } from "@/shared/utils/ui";
import { colors } from "@/app/themes";
import VisitsByLocationTooltip from "./VisitsByLocationTooltip";
import LocationLabel from "./LocationLabel";

interface IVisitsByLocationGraphProps {
  data?: IVisitsByLocation[];
  sx?: SxProps<Theme>;
}

const VisitsByLocationGraph: React.FC<IVisitsByLocationGraphProps> = (
  props,
) => {
  const { data } = props;

  const gradientColors = generateShades("#1E2DFA", data?.length || 0);

  const displayData = data
    ?.sort((a, b) => a.locationName.localeCompare(b.locationName))
    ?.map((el, i) => {
      return {
        ...el,
        color: gradientColors[i],
      };
    })
    ?.sort((a, b) => (a.numVisits > b.numVisits ? -1 : 1));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        ...props.sx,
      }}
    >
      <Box sx={{ height: "8%", mb: "2%" }}>
        <GraphTitle>Visits by Location</GraphTitle>
      </Box>

      <Box sx={{ height: "90%" }}>
        {displayData === undefined ? (
          <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />
        ) : (
          <>
            {displayData.length ? (
              <Stack direction="row" sx={{ height: "100%", width: "100%" }}>
                <ResponsiveContainer width="55%" height="100%">
                  <PieChart>
                    <Tooltip
                      content={<VisitsByLocationTooltip />}
                      cursor={{ fill: "#EDEFF2" }}
                      wrapperStyle={{ outline: "none" }}
                    />
                    <Pie
                      data={displayData}
                      cx="50%"
                      cy="50%"
                      dataKey="numVisits"
                    >
                      {displayData?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>

                <Stack
                  sx={{
                    width: "45%",
                    height: "100%",
                    borderLeft: (theme) =>
                      `1px solid ${theme.palette.grey[400]}`,
                    pl: 3,
                  }}
                  spacing={1}
                >
                  <GraphTitle small>Top Locations</GraphTitle>

                  {displayData.slice(0, 7).map((el) => {
                    return (
                      <LocationLabel
                        locationName={el.locationName}
                        locationID={el.locationID}
                        color={el.color}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            ) : (
              <>No data</>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default VisitsByLocationGraph;
