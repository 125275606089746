import { Box, Grow } from "@mui/material";

import { BackButton } from "@/components/buttons";
import { useAppDispatch, useAppSelector } from "@/app/state/hooks";
import { setBackButtonText } from "@/services/ui/uiSlice";

const HeaderLeft: React.FunctionComponent = () => {
  const btnState = useAppSelector((state) => state.ui.backButton);
  const dispatch = useAppDispatch();

  return (
    <Grow
      in={btnState.isVisible}
      onExited={() => {
        dispatch(setBackButtonText());
      }}
    >
      <Box>
        <BackButton
          sx={{
            height: "30px",
            whiteSpace: "nowrap",
            border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          {btnState.text}
        </BackButton>
      </Box>
    </Grow>
  );
};

export default HeaderLeft;
