import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getTabTitles } from ".";

const OnRouteChange: React.FunctionComponent = () => {
  const location = useLocation();

  const tabTitles = getTabTitles();

  useEffect(() => {
    const newPath = location.pathname.slice(1);

    if (newPath in tabTitles) {
      document.title = tabTitles[newPath];
    }
  }, [location, tabTitles]);

  return <></>;
};

export default OnRouteChange;
