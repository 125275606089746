import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./app/state/store";
import config from "./config";

import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const auth0ProviderOptions: Auth0ProviderOptions = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientID,
  authorizationParams: {
    redirect_uri: config.auth0.loginRedirectURI,
    audience: config.auth0.audience,
    scope: config.auth0.scope,
  },
};

root.render(
  <StrictMode>
    <Auth0Provider {...auth0ProviderOptions}>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </Auth0Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
