import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import momentTz from "moment-timezone";

import { GraphTitle } from "@/components/graphs";
import { IDateStatAggregate } from "@alex/types";
import TabletStatusGraphSingleBox from "./SingleBox";

interface ITabletStatusGraphProps {
  data?: IDateStatAggregate[];
}

const TabletStatusGraph: React.FC<ITabletStatusGraphProps> = (props) => {
  return (
    <Stack
      sx={{
        mt: 4,
        bgcolor: "grey.100",
        width: "fit-content",
        p: 3,
        borderRadius: "20px",
      }}
    >
      <GraphTitle>Tablet Status</GraphTitle>

      <Grid
        container
        columns={26}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "780px",
          zIndex: 10,
        }}
      >
        <Grid item xs={2} sx={{ zIndex: 10 }} />
        {["12 am", "3 am", "6 am", "9 am", "12 pm", "3 pm", "6 pm", "9 pm"].map(
          (label) => {
            return (
              <Grid item xs={3} key={label}>
                <Typography sx={{ color: "grey.500" }}>{label}</Typography>
              </Grid>
            );
          },
        )}
        {props.data?.map((el, i) => {
          return (
            <Grid
              key={el.date.toString()}
              xs={i % 24 === 0 ? 3 : 1}
              item
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "30px",
                width: "30px",
              }}
            >
              {i % 24 === 0 ? (
                <Box sx={{ width: "60px" }}>
                  <Typography
                    sx={{
                      color: "grey.500",
                      textAlign: "right",
                      pr: 1,
                    }}
                  >
                    {momentTz(el.date).format("ddd")}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              <TabletStatusGraphSingleBox data={el} />
            </Grid>
          );
        })}
      </Grid>

      {!props.data ? (
        <Stack sx={{ width: "100%", alignItems: "end" }}>
          <Skeleton
            variant="rounded"
            color="primary"
            sx={{
              height: "210px",
              bgcolor: "grey.200",
              borderRadius: "5px",
              width: "calc(100% - 60px)",
            }}
          />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default TabletStatusGraph;
