import { Stack, SxProps, Theme } from "@mui/material";

import { colors } from "@/app/themes";

interface IIconGradientWrapperProps {
  children: any;
  disableGradient?: boolean;
  sx?: SxProps<Theme>;
}

const IconGradientWrapper: React.FunctionComponent<
  IIconGradientWrapperProps
> = (props) => {
  const Icon = props.children;

  return (
    <Stack sx={{}}>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={0} y1={0} x2={0.75} y2={0.75}>
          <stop offset={0} stopColor={colors.gradient.left} />
          <stop offset={1} stopColor={colors.gradient.right} />
        </linearGradient>
      </svg>

      <Icon
        sx={{
          ...props.sx,
          fill: props.disableGradient ? "default" : "url(#linearColors)",
        }}
      />
    </Stack>
  );
};

export default IconGradientWrapper;
