import { PosIntegration } from "@alex/types";
import IntegrationCard, { IIntegrationCard } from "./IntegrationCard";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const IntegrationsSettings: React.FunctionComponent = (props) => {
  const navigate = useNavigate();

  const { data: integrationStatus } = useSWR<PosIntegration[]>(
    "/api/s/pos/install-status",
  );

  const integrations: IIntegrationCard[] = [
    {
      name: "Square",
      logoUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Square%2C_Inc._-_Square_logo.svg/2048px-Square%2C_Inc._-_Square_logo.svg.png",
      onClick: () => {
        navigate("/settings/integrations/square");
      },
      isEnabled:
        integrationStatus?.find((el) => el.name === "square")?.isEnabled ||
        false,
      comingSoon: false,
    },
    {
      name: "Toast",
      logoUrl: "https://static.stocktitan.net/company-logo/TOST-lg.png",
      logoSx: {
        borderRadius: "10px",
      },
      onClick: () => {},
      comingSoon: true,
    },
  ];

  return (
    <Stack direction="row" spacing={5}>
      {integrations.map((el) => {
        return <IntegrationCard {...el} />;
      })}
    </Stack>
  );
};

export default IntegrationsSettings;
