import { Stack, SxProps, Theme } from "@mui/material";

interface IBorderedStackProps {
  children: any;
  spacing?: number;
  sx?: SxProps<Theme>;
}

const BorderedStack: React.FunctionComponent<IBorderedStackProps> = (props) => {
  return (
    <Stack
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "20px",
        py: 3,
        pb: 8,
        px: 5,
        ...props.sx,
      }}
      spacing={props.spacing}
    >
      {props.children}
    </Stack>
  );
};

export default BorderedStack;
