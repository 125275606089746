import { Box, Typography, CircularProgress, Stack } from "@mui/material";

import InboxItem from "./InboxItem";
import { useAppSelector } from "@/app/state/hooks";
import { useAppDispatch } from "@/app/state/hooks";
import { setSelectedInteraction } from "../slice";

export interface IInboxItemsProps {}

const InboxItems: React.FunctionComponent<IInboxItemsProps> = (_props) => {
  const dispatch = useAppDispatch();

  const interactionSummaries = useAppSelector(
    (state) => state.messages.interactionSummaries,
  );

  const selectedInteraction = useAppSelector(
    (state) => state.messages?.selectedInteraction,
  );

  const selectedContactID = selectedInteraction?.contact?.ID;

  if (!interactionSummaries) {
    return (
      <Stack sx={{ pt: 2, alignItems: "center" }}>
        <CircularProgress />
      </Stack>
    );
  } else if (!interactionSummaries.length) {
    return (
      <Typography
        sx={{
          pt: 2,
          color: "text.secondary",
          fontSize: 15,
          fontStyle: "italic",
        }}
      >
        No messages yet
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",

        overflowY: "auto",
        pr: "10px",
      }}
    >
      {interactionSummaries.map((item) => {
        return (
          <Box
            key={item.phoneNumber}
            sx={{
              mt: "10px",
            }}
          >
            <InboxItem
              item={item}
              isSelected={item.contactID === selectedContactID}
              onItemClicked={(selectedContactID) => {
                dispatch(setSelectedInteraction(selectedContactID));
              }}
            ></InboxItem>
          </Box>
        );
      })}
    </Box>
  );
};

export default InboxItems;
