import { Box, Stack, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { AppFile } from "@alex/types";

import { CancelIcon } from "@/components/icons";
import { useEffect, useState } from "react";

export interface IFileDisplayProps {
  value?: AppFile;
  onClear?: () => void;
}

const DEFAULT_ICON_SIZE = 22;
const HOVERED_ICON_SIZE = 24;

const FileDisplay: React.FunctionComponent<IFileDisplayProps> = (props) => {
  const [iconHovered, setIconHovered] = useState(false);
  const [iconSize, setIconSize] = useState(DEFAULT_ICON_SIZE);

  useEffect(() => {
    if (iconHovered) {
      setIconSize(HOVERED_ICON_SIZE);
    } else {
      setIconSize(DEFAULT_ICON_SIZE);
    }
  }, [iconHovered]);

  return (
    <Stack
      direction="row"
      sx={{
        bgcolor: "white",
        p: 2,
        borderRadius: "10px",
        width: "100%",
        boxShadow: (theme) => `-1px 2px 2px ${theme.palette.grey[500]}`,
        alignItems: "center",
      }}
    >
      <AttachFileIcon
        sx={{
          width: "5%",
          fontSize: 20,
          color: "text.secondary",
          transform: "rotate(-30deg)",
        }}
      />

      <Stack
        sx={{
          ml: "2%",
          width: "83%",
          justifyContent: "center",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          {props.value?.name}
        </Typography>

        <Typography
          sx={{
            mt: 1,
            color: "text.secondary",
          }}
        >
          420 KB
        </Typography>
      </Stack>
      <Stack
        onMouseEnter={() => setIconHovered(true)}
        onMouseLeave={() => setIconHovered(false)}
        onClick={() => props.onClear?.()}
        sx={{
          width: "5%",
          textOverflow: "ellipsis",
          alignItems: "flex-end",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <CancelIcon height={iconSize} width={iconSize} colored />
      </Stack>
    </Stack>
  );
};

export default FileDisplay;
