import { GraphTooltip } from "@/components/graphs";
import { getPrimaryGradient } from "@/shared/utils/ui";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";

interface ISingleBarProps {
  percentageFilled: number;
  numContacts: number;
  title: string;
}

const SingleBar: React.FC<ISingleBarProps> = (props) => {
  const height = "13px";
  const borderRadius = "20px";

  const [isHovered, setIsHovered] = useState(false);

  let fillAmount = props.percentageFilled;

  if (fillAmount < 0) {
    fillAmount = 0;
  } else if (fillAmount > 100) {
    fillAmount = 100;
  }

  return (
    <Stack
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        position: "relative",
        width: "100%",
        bgcolor: "grey.300",
        boxShadow: (theme) => `inset 0 0 5px ${theme.palette.grey[400]}`,
        height,
        borderRadius,
        zIndex: 10,
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          height,
          borderRadius,
          background: getPrimaryGradient(115),
          zIndex: 0,
          width: `${fillAmount}%`,
        }}
      />

      {isHovered ? (
        <GraphTooltip
          sx={{
            position: "absolute",
            bottom: -5,
            left: 5,
            textAlign: "left",
          }}
          title={`With ${props.title}`}
        >
          <Typography sx={{ color: "text.secondary" }}>
            Customers:{" "}
            <Typography
              sx={{ display: "inline", fontWeight: 900, color: "text.primary" }}
            >
              {props.numContacts}
            </Typography>
          </Typography>
        </GraphTooltip>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default SingleBar;
