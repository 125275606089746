import { Box, Button, Divider, Stack, SxProps, Theme } from "@mui/material";
import { GradientButton } from "../buttons";

interface IAppTabGroupProps {
  children: JSX.Element[];
  endAdornment?: JSX.Element;
  sx?: SxProps<Theme>;
}

const AppTabGroup: React.FC<IAppTabGroupProps> = (props) => {
  return (
    <Box sx={{ my: 3, ...props.sx }}>
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
          {props.children}
        </Stack>

        {props.endAdornment}
      </Stack>

      <Divider sx={{ borderBottomColor: "grey.300" }} />
    </Box>
  );
};

export default AppTabGroup;
