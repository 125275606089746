import { Stack, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

import { GraphTooltip } from "@/components/graphs";

const VisitsByLocationTooltip: React.FunctionComponent<
  TooltipProps<ValueType, NameType>
> = ({ payload }) => {
  if (payload && payload.length) {
    const { locationName, numVisits } = payload[0].payload;

    return (
      <GraphTooltip title={locationName}>
        <Typography sx={{ color: "text.secondary" }}>
          Visits:{" "}
          <Typography
            sx={{ display: "inline", fontWeight: 900, color: "text.primary" }}
          >
            {numVisits}
          </Typography>
        </Typography>
      </GraphTooltip>
    );
  }

  return null;
};

export default VisitsByLocationTooltip;
