import { colors } from "@/app/themes";

export const renderBar = (
  props: any,
  boxType: "msgsSent" | "msgsReceived",
  borderRadius = 8,
) => {
  const { x, y, width, height, payload } = props;
  const { msgsSent, msgsReceived } = payload;

  const onlyBottomRadius = `M${x},${y}
      h${width}
      v${height - borderRadius}
      q0,${borderRadius} -${borderRadius},${borderRadius}
      h${-width + borderRadius * 2}
      q-${borderRadius},0 -${borderRadius},-${borderRadius}
      v${-height + borderRadius}
      h${borderRadius}
      z`;

  const onlyTopRadius = `M${x + borderRadius},${y}
      h${width - borderRadius * 2}
      q${borderRadius},0 ${borderRadius},${borderRadius}
      v${height - borderRadius}
      h${-width}
      v${-height + borderRadius}
      q0,-${borderRadius} ${borderRadius},-${borderRadius}
      z`;

  const allRadius = `M${x + borderRadius},${y}
    h${width - borderRadius * 2}
    q${borderRadius},0 ${borderRadius},${borderRadius}
    v${height - borderRadius * 2}
    q0,${borderRadius} -${borderRadius},${borderRadius}
    h${-width + borderRadius * 2}
    q-${borderRadius},0 -${borderRadius},-${borderRadius}
    v${-height + borderRadius * 2}
    q0,-${borderRadius} ${borderRadius},-${borderRadius}
    z`;

  let d: string | undefined = "";

  if (boxType === "msgsSent") {
    if (!msgsSent) {
      d = undefined;
    } else if (msgsReceived) {
      d = onlyBottomRadius;
    } else {
      d = allRadius;
    }
  } else if (boxType === "msgsReceived") {
    if (!msgsReceived) {
      d = undefined;
    } else if (msgsSent) {
      d = onlyTopRadius;
    } else {
      d = allRadius;
    }
  }

  const gradientOption =
    boxType === "msgsSent" ? "barGradientSent" : "barGradientReceived";

  return (
    <svg>
      <defs>
        <linearGradient
          id="barGradientReceived"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset={`0%`} stopColor={colors.secondary.main} />
          <stop offset={`90%`} stopColor={colors.secondary.light} />
        </linearGradient>
        <linearGradient
          id="barGradientSent"
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset={`0%`} stopColor={colors.gradient.right} />
          <stop offset={`100%`} stopColor={colors.gradient.left} />
        </linearGradient>
      </defs>
      <path d={d} fill={`url(#${gradientOption})`} />
    </svg>
  );
};
