import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

import { ErrorText, LabelText, sharedConfig } from "./shared";

export interface IAppCheckboxGroupProps {
  label: string;
  errorMsg?: string;
  allItems: string[];
  selectedItems: string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppCheckboxGroup: React.FunctionComponent<IAppCheckboxGroupProps> = (
  props,
) => {
  return (
    <Box>
      <LabelText>{props.label}</LabelText>

      <FormGroup sx={{ "& .MuiFormControlLabel-root": { marginY: "-5px" } }}>
        {props.allItems.map((item) => {
          return (
            <FormControlLabel
              key={item}
              label={
                <Typography
                  sx={{
                    fontSize: sharedConfig.fontSize,
                    color: "text.secondary",
                  }}
                >
                  {item}
                </Typography>
              }
              control={
                <Checkbox
                  checked={props.selectedItems.includes(item)}
                  size="small"
                  onChange={(e) => {
                    props.onChange?.(e);
                  }}
                  name={item}
                />
              }
              sx={{ m: 0 }}
            />
          );
        })}
      </FormGroup>

      {props.errorMsg && <ErrorText text={props.errorMsg} />}
    </Box>
  );
};

export default AppCheckboxGroup;
