import { useState } from "react";
import { Stack, Box, Typography, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { getPhoneNumberDisplay } from "@/shared/utils/formatters";

export interface IBusinessDisplayProps {
  name?: string;
  phoneNumber?: string;
}

const BusinessDisplay: React.FunctionComponent<IBusinessDisplayProps> = (
  props,
) => {
  const UNCOPIED_TEXT = "Copy phone number";
  const COPIED_TEXT = "Copied phone number!";
  const [tooltipText, setTooltipText] = useState(UNCOPIED_TEXT);

  function handleCopyBtnClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.stopPropagation();
    setTooltipText(COPIED_TEXT);

    navigator.clipboard.writeText(props.phoneNumber || "");
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        py: 1.5,
        pl: 2.5,
        borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      {props.name && (
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 14.5,
            mb: 0.5,
          }}
        >
          {props.name}
        </Typography>
      )}

      {props.phoneNumber ? (
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            {props.phoneNumber && getPhoneNumberDisplay(props.phoneNumber)}
          </Typography>

          <Tooltip
            placement="top"
            arrow
            title={
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                {tooltipText}
              </Typography>
            }
          >
            <ContentCopyIcon
              onMouseEnter={() => setTooltipText(UNCOPIED_TEXT)}
              onClick={handleCopyBtnClick}
              sx={{
                ml: 1,
                fontSize: 15,
                color: "text.secondary",
                "&:hover": {
                  color: "text.primary",
                  cursor: "pointer",
                },
              }}
            />
          </Tooltip>
        </Stack>
      ) : (
        <Typography
          sx={{ fontSize: 14, fontStyle: "italic", color: "text.secondary" }}
        >
          No Phone Number
        </Typography>
      )}
    </Box>
  );
};

export default BusinessDisplay;
