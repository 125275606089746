import { Box } from "@mui/material";

import InteractionHeader from "./header";
import ContactInfo from "./contactInfo";
import Messages from "./messageView";
import { useAppSelector } from "@/app/state/hooks";

export interface IInteractionViewProps {
  width: string;
}

const InteractionView: React.FunctionComponent<IInteractionViewProps> = (
  props,
) => {
  const separatorWidth = "30px";
  const contactInfoWidth = "270px";

  const selectedContact = useAppSelector(
    (state) => state.messages.selectedInteraction?.contact,
  );

  if (!selectedContact) {
    return <></>;
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: props.width,
        px: "15px",
      }}
    >
      <InteractionHeader height="55px" />

      <Box
        sx={{
          display: "flex",
          height: `calc(100% - 50px - 5px)`,
          pt: "10px",
        }}
      >
        <Messages
          width={`calc(100% - ${separatorWidth} - ${contactInfoWidth})`}
        />

        <Box sx={{ width: separatorWidth }}></Box>

        <ContactInfo width={contactInfoWidth} />
      </Box>
    </Box>
  );
};

export default InteractionView;
