import { Box, Divider } from "@mui/material";

import PageHeaderText from "@/components/text/PageHeaderText";

const InboxHeader: React.FunctionComponent = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <PageHeaderText>Inbox</PageHeaderText>

        {/* <Button
          variant="contained"
          size="small"
          sx={{
            background: (theme) =>
              `linear-gradient(135deg, ${theme.gradient.left} 10%, ${theme.gradient.right} 90%)`,
            borderRadius: "100px",
            height: "30px",
          }}
        >
          <Typography variant="body1">New</Typography>
        </Button> */}
      </Box>

      <Divider sx={{ my: "15px", borderBottomWidth: 2, borderRadius: "5px" }} />

      {/* <Box sx={{ display: "flex" }}>
        <Typography
          sx={{ color: theme.palette.text.secondary, mt: "auto", mr: "10px" }}
        >
          List:
        </Typography>
        <FormControl variant="standard" sx={{ minWidth: 80 }}>
          <Select
            labelId="list-select-label"
            id="demo-simple-select-standard"
            // label="Age"
          >
            <MenuItem value={"all"}>All</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      {/* <TextField
        variant="standard" // <== changed this
        margin="normal"
        required
        fullWidth
        id="phoneNumber"
        name="phoneNumber"
        autoComplete="phoneNumber"
        autoFocus
        // onChange={handlePhoneNumberChange}
        placeholder="Phone Number"
        InputProps={{
          startAdornment: <AccountCircle />, // <== adjusted this
          disableUnderline: true, // <== added this
        }}
      /> */}
    </Box>
  );
};

export default InboxHeader;
