import { TableCell, SxProps, Theme, Typography } from "@mui/material";

export interface IAppTableCellProps {
  children: any;
  emphasize?: boolean;
  sx?: SxProps<Theme>;
}

const AppTableCell: React.FunctionComponent<IAppTableCellProps> = (props) => {
  const color = props.emphasize ? "text.primary" : "text.secondary";

  return (
    <TableCell sx={{ color, ...props.sx }}>
      {typeof props.children === "string" ? (
        <Typography>{props.children}</Typography>
      ) : (
        <>{props.children}</>
      )}
    </TableCell>
  );
};

export default AppTableCell;
