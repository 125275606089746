import {
  Box,
  Button,
  Drawer,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import PageHeaderText from "../text/PageHeaderText";

interface IAppRightDrawerProps {
  open?: boolean;
  title: string;
  children: any;
  onClose: () => void;
  sx?: SxProps<Theme>;
}

const AppRightDrawer: React.FC<IAppRightDrawerProps> = (props) => {
  return (
    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={props.onClose}
      sx={{ bgcolor: "none" }}
      PaperProps={{
        sx: {
          bgcolor: "grey.200",
          borderRadius: "20px 0 0 20px",
          p: 0.75,
          pb: 4,
        },
      }}
    >
      <Stack
        sx={{
          height: "100%",
          bgcolor: "white",
          borderRadius: "15px",
          width: "500px",
          px: 2,
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",

            py: 2,
            borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack
            onClick={props.onClose}
            sx={{
              height: "40px",
              width: "40px",
              bgcolor: "grey.200",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",

              "&:hover": {
                bgcolor: "grey.300",
                cursor: "pointer",
                boxShadow: (theme) => `-1px 1px 3px ${theme.palette.grey[300]}`,
              },
            }}
          >
            <CloseIcon sx={{ color: "text.secondary" }} />
          </Stack>

          <Typography variant="h3" sx={{ fontWeight: 900 }}>
            {props.title}
          </Typography>

          <Box sx={{ width: "30px" }} />
        </Stack>

        <Stack
          sx={{
            overflowY: "auto",
            py: 2,
          }}
        >
          {props.children}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AppRightDrawer;
