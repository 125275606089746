import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  BusinessWithPhoneNumber,
  BusinessUpdate,
  Employee,
  UncreatedEmployee,
  EmployeeUpdate,
  MeUpdate,
  KVPair,
} from "@alex/types";

import { apiGet, apiPost, apiPut } from "@/services/api/apiSlice";

interface SettingsState {
  users: Employee[] | null;
  timezoneOptions: KVPair[];
}

const initialState: SettingsState = {
  users: null,
  timezoneOptions: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<Employee[]>) {
      state.users = action.payload;
    },
    updateUsersWithUpdatedUser(state, action: PayloadAction<Employee>) {
      if (state.users) {
        const updatedUser = action.payload;

        state.users = state.users.map((el) =>
          el.ID === updatedUser.ID ? updatedUser : el,
        );
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.users) {
          state.users.push(action.payload);
        } else {
          state.users = [action.payload];
        }
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        settingsSlice.caseReducers.updateUsersWithUpdatedUser(state, {
          payload: action.payload,
          type: action.type,
        });
      }
    });
  },
});

// potentially move user thunks into a separate file
export const getUsers = createAsyncThunk<
  Employee[],
  void,
  { rejectValue: { message: string } }
>("settings/getUsers", async (_, { dispatch }) => {
  try {
    const users = await dispatch(apiGet({ url: "/api/e/employees" }));

    if (users) {
      return users.payload as Employee[];
    }
  } catch (e) {
    console.log(e);
  }

  return [];
});

export const getEmailAddressIsTaken = createAsyncThunk<
  boolean,
  string,
  { rejectValue: { message: string } }
>(
  "settings/getEmailAddressIsTaken",
  async (emailAddress: string, { dispatch }) => {
    try {
      const res = await dispatch(
        apiGet({
          url: `/api/e/employees/is-taken?email_address=${emailAddress}`,
        }),
      );

      return res.payload.isTaken as boolean;
    } catch (e) {
      console.log(e);
    }

    return false;
  },
);

export const createUser = createAsyncThunk<
  Employee | null,
  UncreatedEmployee,
  { rejectValue: { message: string } }
>("settings/createUser", async (newUser: UncreatedEmployee, { dispatch }) => {
  try {
    const res = await dispatch(
      apiPost({ url: `/api/e/employees`, body: newUser }),
    );

    return res.payload as Employee;
  } catch (e) {
    console.log(e);
  }

  return null;
});

export const updateUser = createAsyncThunk<
  Employee | null,
  { id: string; update: EmployeeUpdate },
  { rejectValue: { message: string } }
>(
  "settings/updateUser",
  async (payload: { id: string; update: EmployeeUpdate }, { dispatch }) => {
    // TODO - potentially do some validation here

    try {
      const res = await dispatch(
        apiPut({ url: `/api/e/employees/${payload.id}`, body: payload.update }),
      );

      return res.payload as Employee;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const getUserByID = createAsyncThunk<
  Employee | null,
  string,
  { rejectValue: { message: string } }
>("settings/getByID", async (userID: string, { dispatch }) => {
  try {
    const res = await dispatch(apiGet({ url: `/api/e/employees/${userID}` }));

    return res.payload as Employee;
  } catch (e) {
    console.log(e);
  }

  return null;
});

export const updateMyBusiness = createAsyncThunk<
  BusinessWithPhoneNumber | null,
  BusinessUpdate,
  { rejectValue: { message: string } }
>(
  "settings/updateMyBusiness",
  async (payload: BusinessUpdate, { dispatch }) => {
    try {
      const [myBusiness] = await Promise.all([
        dispatch(apiPut({ url: "/api/e/businesses/me", body: payload })),
      ]);

      if (myBusiness.payload) {
        return myBusiness.payload as BusinessWithPhoneNumber;
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const updateMe = createAsyncThunk<
  Employee | null,
  MeUpdate,
  { rejectValue: { message: string } }
>("settings/updateMe", async (payload: MeUpdate, { dispatch }) => {
  try {
    const [myBusiness] = await Promise.all([
      dispatch(apiPut({ url: "/api/e/employees/me", body: payload })),
    ]);

    if (myBusiness.payload) {
      return myBusiness.payload as Employee;
    }
  } catch (e) {
    console.log(e);
  }

  return null;
});

export const getTimezoneOptions = createAsyncThunk(
  "settings/getTimezoneOptions",
  async (_, { dispatch }): Promise<KVPair[]> => {
    try {
      const options = await dispatch(
        apiGet({ url: "/api/e/businesses/settings/timezone-options" }),
      );

      if (options) {
        return options.payload as KVPair[];
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export default settingsSlice.reducer;
