import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

import { Toast, ToastPayload } from "./types";

interface UIState {
  toasts: Toast[];
  backButton: BackButtonState;
  notificationsVisible: boolean;
}

interface BackButtonState {
  isVisible: boolean;
  text?: string;
}

const initialState: UIState = {
  toasts: [],
  backButton: {
    isVisible: false,
  },
  notificationsVisible: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<Toast>) {
      state.toasts.push(action.payload);
    },
    deleteToastByID(state, action: PayloadAction<string>) {
      const deleteIndex = state.toasts.findIndex(
        (el: Toast) => el.id === action.payload,
      );

      if (deleteIndex !== -1) {
        state.toasts.splice(deleteIndex, 1);
      }
    },
    setBackButtonVisibility(state, action: PayloadAction<boolean>) {
      state.backButton.isVisible = action.payload;
    },
    setBackButtonText(state, action: PayloadAction<string | undefined>) {
      state.backButton.text = action.payload;
    },
    setNotificationsVisibility(state, action: PayloadAction<boolean>) {
      state.notificationsVisible = action.payload;
    },
  },
});

export const addNewToast = createAsyncThunk<
  any,
  ToastPayload,
  { rejectValue: { message: string } }
>("ui/addToast", async (newToast: ToastPayload, { dispatch }) => {
  // anything more than 30 seconds is too long
  const defaultDisplaySeconds = 5;

  if (newToast?.displaySeconds && newToast?.displaySeconds > 30) {
    newToast.displaySeconds = 30;
  }

  const toastID = uuid();
  const toast: Toast = { ...newToast, id: toastID };

  dispatch(addToast(toast));

  setTimeout(() => {
    dispatch(deleteToastByID(toastID));
  }, (toast.displaySeconds || defaultDisplaySeconds) * 1000);
});

export const {
  addToast,
  deleteToastByID,
  setBackButtonVisibility,
  setBackButtonText,
  setNotificationsVisibility,
} = uiSlice.actions;

export default uiSlice.reducer;
