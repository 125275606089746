import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import moment from "moment";
import MessageIcon from "@mui/icons-material/Message";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TabletMacIcon from "@mui/icons-material/TabletMac";

import AppTab from "@/components/tabs/AppTab";
import AppTabGroup from "@/components/tabs/AppTabGroup";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import PageHeaderText from "@/components/text/PageHeaderText";
import RewardsAppMessagingSettings from "./messaging";
import TabletScreensSettings from "./tabletScreens";
import useRewardsAppSettings from "./useRewardsAppSettings";

import { EditSaveCancel } from "@/components/forms";
import { OutlinedGradientButton } from "@/components/buttons";

interface IRewardsAppSettingsProps {}

const RewardsAppSettings: React.FC<IRewardsAppSettingsProps> = (props) => {
  const { save, reset, tabletScreens } = useRewardsAppSettings();

  const tabs = [
    {
      title: "Tablet Screens",
      icon: TabletMacIcon,
      iconSx: {
        transform: "rotate(-90deg)",
      },
      component: (
        <TabletScreensSettings {...tabletScreens} save={save} reset={reset} />
      ),
    },
    {
      title: "Messaging",
      icon: MessageIcon,
      component: <RewardsAppMessagingSettings />,
    },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <FullPageBase>
      <Stack sx={{ position: "relative", height: "100%" }}>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <PageHeaderText>Rewards App Settings</PageHeaderText>

          <OutlinedGradientButton
            onClick={() => {
              window.open("https://instore.askalex.co", "_blank")?.focus();
            }}
            icon={
              <OpenInNewIcon sx={{ fontSize: 16, color: "text.secondary" }} />
            }
            small
            sx={{ color: "text.secondary" }}
          >
            View App
          </OutlinedGradientButton>
        </Stack>

        {/* <EditSaveCancel
          onEditBtnClick={() => setEditEnabled(true)}
          onSaveBtnClick={() => {
            save();
            setEditEnabled(false);
          }}
          onCancelBtnClick={() => {
            reset();
            setEditEnabled(false);
          }}
          editEnabled={editEnabled}
          sx={{ mt: 1 }}
        /> */}

        <AppTabGroup>
          {tabs.map((tab, i) => {
            return (
              <AppTab
                title={tab.title}
                icon={tab.icon}
                iconSx={tab.iconSx}
                onClick={() => setSelectedTabIndex(i)}
                isSelected={selectedTabIndex === i}
              />
            );
          })}
        </AppTabGroup>

        {tabs[selectedTabIndex].component}
      </Stack>
    </FullPageBase>
  );
};

export default RewardsAppSettings;
