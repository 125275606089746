import { IStoreLocation, IUncreatedStoreLocation } from "@alex/types";
import { useContext, useEffect, useState } from "react";
import { ILocationFormProps } from ".";
import { notEmptyValidator } from "@/shared/utils/validators";
import { AppContext } from "@/app/context/AppContext";
import { useAppDispatch } from "@/app/state/hooks";
import { AxiosError, HttpStatusCode } from "axios";
import { addNewToast } from "@/services/ui/uiSlice";
import useSWR from "swr";

interface LocationUpserterState {
  create: () => Promise<boolean>;
  update: () => Promise<boolean>;
  reset: () => void;
  hasChanged: boolean;
  location: ILocationFormProps;
}

const useLocationUpsert = (locationID?: string): LocationUpserterState => {
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();

  const [name, setName] = useState<string>();
  const [nameErrorMsg, setNameErrorMsg] = useState<string | undefined>();

  const [hasChanged, setHasChanged] = useState(false);

  const { data: savedLocation } = useSWR<IStoreLocation>(
    locationID && `api/s/instore-capture/locations/${locationID}`,
  );

  useEffect(() => {
    setName(savedLocation?.name);
  }, []);

  useEffect(() => {
    if (savedLocation) {
      setHasChanged(name !== savedLocation.name);
    }
  }, [name]);

  function getIsValid(): boolean {
    const isValidRes = notEmptyValidator(name, setNameErrorMsg);

    return isValidRes.isValid;
  }

  function reset() {
    setNameErrorMsg(undefined);

    if (savedLocation) {
      setName(savedLocation.name);
    } else {
      setName("");
    }
  }

  async function create(): Promise<boolean> {
    const isValid = getIsValid();

    if (!isValid) {
      return false;
    }

    try {
      const apiRes = await appContext?.api.post<IStoreLocation>(
        "/api/s/instore-capture/locations",
        { name },
      );

      dispatch(
        addNewToast({
          text: "Saved!",
          severity: "success",
        }),
      );

      reset();
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Forbidden) {
          dispatch(
            addNewToast({
              severity: "error",
              text: "Fix errors, then resubmit",
            }),
          );

          setNameErrorMsg("Name already exists!");
        }
      }
      console.error(e);
      return false;
    }

    return true;
  }

  async function update(): Promise<boolean> {
    const isValid = getIsValid();

    if (!isValid || !locationID) {
      return false;
    }

    try {
      const apiRes = await appContext?.api.put<IStoreLocation>(
        `/api/s/instore-capture/locations/${locationID}`,
        { name },
      );

      dispatch(
        addNewToast({
          text: "Saved!",
          severity: "success",
        }),
      );
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  return {
    location: {
      name: {
        value: name,
        onChange: (e) => setName(e.target.value),
        errorMsg: nameErrorMsg,
        onBlur: () => notEmptyValidator(name, setNameErrorMsg),
      },
    },
    hasChanged,
    reset,
    create,
    update,
  };
};

export default useLocationUpsert;
