import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { Keyword, KeywordUpdate, UncreatedKeyword } from "@alex/types";

import { apiGet, apiPost, apiPut, apiDelete } from "@/services/api/apiSlice";
import { RootState } from "@/app/state/store";

interface KeywordsState {
  keywords: Keyword[] | null;
}

const initialState: KeywordsState = {
  keywords: null,
};

const keywordsSlice = createSlice({
  name: "keywords",
  initialState,
  reducers: {
    setKeywords(state, action: PayloadAction<Keyword[]>) {
      state.keywords = action.payload;
    },
    updateKeywordsWithUpdatedKeyword(state, action: PayloadAction<Keyword>) {
      if (state.keywords && action.payload) {
        const updatedKeyword = action.payload;

        state.keywords = state.keywords.map((el) =>
          el.ID === updatedKeyword.ID
            ? { ...updatedKeyword, listName: el.listName }
            : el,
        );
      }
    },
    updateKeywordsWithDeletedKeyword(state, action: PayloadAction<string>) {
      if (state.keywords && action.payload) {
        const deletedID = action.payload;

        state.keywords = state.keywords.filter((el) => el.ID !== deletedID);
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getKeywords.fulfilled, (state, action) => {
      state.keywords = action.payload;
    });
    builder.addCase(createKeyword.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.keywords) {
          state.keywords.push(action.payload);
        } else {
          state.keywords = [action.payload];
        }
      }
    });
    builder.addCase(updateKeyword.fulfilled, (state, action) => {
      if (action.payload) {
        keywordsSlice.caseReducers.updateKeywordsWithUpdatedKeyword(state, {
          payload: action.payload,
          type: action.type,
        });
      }
    });
    builder.addCase(deleteKeywordByID.fulfilled, (state, action) => {
      if (action.payload) {
        keywordsSlice.caseReducers.updateKeywordsWithDeletedKeyword(state, {
          payload: action.payload,
          type: action.type,
        });
      }
    });
  },
});

export const createKeyword = createAsyncThunk<
  Keyword | null,
  UncreatedKeyword,
  { rejectValue: { message: string } }
>(
  "keywords/createKeyword",
  async (newKeyword: UncreatedKeyword, { dispatch }) => {
    try {
      const res = await dispatch(
        apiPost({ url: `/api/e/keywords`, body: newKeyword }),
      );

      return res.payload as Keyword;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const getKeywords = createAsyncThunk(
  "keywords/getKeywords",
  async (_, { dispatch }) => {
    try {
      const keywords = await dispatch(apiGet({ url: "/api/e/keywords" }));

      if (keywords) {
        return keywords.payload as Keyword[];
      }
    } catch (e) {
      console.log(e);
    }

    return [];
  },
);

export const getKeywordIsTaken = createAsyncThunk<
  boolean,
  string,
  { rejectValue: { message: string } }
>("keywords/getKeywordIsTaken", async (keyword: string, { dispatch }) => {
  try {
    const res = await dispatch(
      apiGet({ url: `/api/e/keywords/is-taken/${keyword}` }),
    );

    return res.payload.isTaken as boolean;
  } catch (e) {
    console.log(e);
  }

  return false;
});

export const getKeywordByID = createAsyncThunk<
  Keyword | null,
  string,
  { rejectValue: { message: string } }
>(
  "keywords/getKeywordByID",
  async (keywordID: string, { dispatch, getState }) => {
    if (!keywordID) {
      return null;
    }

    const keywords = (getState() as RootState).keywords.keywords;

    return keywords?.find((el) => el.ID === keywordID) || null;
  },
);

export const updateKeyword = createAsyncThunk<
  Keyword | null,
  { id: string; update: KeywordUpdate },
  { rejectValue: { message: string } }
>(
  "keywords/updateKeyword",
  async (payload: { id: string; update: KeywordUpdate }, { dispatch }) => {
    try {
      const res = await dispatch(
        apiPut({ url: `/api/e/keywords/${payload.id}`, body: payload.update }),
      );

      return res.payload as Keyword;
    } catch (e) {
      console.log(e);
    }

    return null;
  },
);

export const deleteKeywordByID = createAsyncThunk<
  string | null,
  string,
  { rejectValue: { message: string } }
>("keywords/deleteKeywordByID", async (keywordID: string, { dispatch }) => {
  try {
    await dispatch(apiDelete({ url: `/api/e/keywords/${keywordID}` }));

    return keywordID as string;
  } catch (e) {
    console.log(e);
  }

  return null;
});

export default keywordsSlice.reducer;
