import { useRef, useState } from "react";
import { Box, Collapse, Slide, Stack } from "@mui/material";

import { AppTextField } from "@/components/inputs";
import ImagePreview from "./ImagePreview";
import { FormElementState } from "@/shared";
import React from "react";
import { LabelText } from "@/components/inputs/shared";
import { EditSaveCancel } from "@/components/forms";

export interface ITabletScreensSettingsStateProps {
  logoImageUrl: FormElementState<string | undefined>;
  bodyImageUrl: FormElementState<string | undefined>;
  title: FormElementState<string | undefined>;
  subTitle: FormElementState<string | undefined>;
  minutesBetweenVisits: FormElementState<number | undefined>;
  save?: () => void;
  reset?: () => void;
  updatedAt?: Date;
}

const TabletScreensSettings: React.FC<ITabletScreensSettingsStateProps> = (
  props,
) => {
  const containerRef = React.useRef<HTMLElement>(null);
  const [editEnabled, setEditEnabled] = useState(false);

  return (
    <Box
      sx={{
        boxShadow: (theme) => `-0.5px 0.5px 3px ${theme.palette.grey[300]}`,
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "20px",
        position: "relative",
        pl: 3,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack sx={{ width: "50%", pt: 3, pb: 8 }} spacing={3}>
          {editEnabled ? (
            <>
              <AppTextField
                {...props.logoImageUrl}
                label="Logo Image URL"
                placeholder=" "
                width="100%"
              />

              <AppTextField
                {...props.bodyImageUrl}
                label="Body Image URL"
                placeholder=" "
                width="100%"
              />
            </>
          ) : (
            <ImagePreview
              logoImageUrl={props.logoImageUrl.value}
              bodyImageUrl={props.bodyImageUrl.value}
            />
          )}

          <AppTextField
            {...props.title}
            label="Title"
            placeholder="Enter your phone number to earn free ..."
            multiline
            characterLimit={50}
            width="100%"
            disabled={!editEnabled}
          />

          <AppTextField
            {...props.subTitle}
            label="Sub Title"
            placeholder="You will earn a FREE reward with just 10 stars!"
            multiline
            characterLimit={80}
            width="100%"
            disabled={!editEnabled}
          />

          {/* <AppTextField
              {...props.minutesBetweenVisits}
              label="Minutes Between Visits"
              width="10ch"
              placeholder="180"
              type="number"
              tooltipText="This is the amount of time between valid visits. 180 minutes (3 hours) is a common choice."
            /> */}
        </Stack>

        <Box
          ref={containerRef}
          sx={{ width: "fit-content", overflow: "hidden" }}
        >
          <Slide
            in={editEnabled}
            direction="left"
            container={containerRef.current}
          >
            <Stack
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
                boxShadow: (theme) => `-1px 0 1px ${theme.palette.grey[300]}`,
                bgcolor: "grey.100",
                ml: 1,
                px: 4,
                py: 2,
              }}
            >
              <ImagePreview
                logoImageUrl={props.logoImageUrl.value}
                bodyImageUrl={props.bodyImageUrl.value}
                large
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </Stack>
          </Slide>
        </Box>
      </Stack>

      <Box
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          borderRadius: "0 20px",
          position: "absolute",
          bottom: -1,
          left: -1,
          pl: 5,
          pr: 2,
          py: 0.5,
          bgcolor: "grey.100",
        }}
      >
        <EditSaveCancel
          editEnabled={editEnabled}
          onEditBtnClick={() => setEditEnabled(true)}
          onSaveBtnClick={() => {
            setEditEnabled(false);
            props.save?.();
          }}
          onCancelBtnClick={() => {
            setEditEnabled(false);
            props.reset?.();
          }}
        />
      </Box>
    </Box>
  );
};

export default TabletScreensSettings;
