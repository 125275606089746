import { useState, useEffect } from "react";
import { Box, TextField, SxProps, Theme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export interface ISearchBarProps {
  onChange?: (event: string) => void;
  placeholder?: string;
  width?: string;
  fontSize?: number;
  sx?: SxProps<Theme>;
}

const SearchBar: React.FunctionComponent<ISearchBarProps> = (props) => {
  const [searchText, setSearchText] = useState("");

  const [shrinkLabel, setShrinkLabel] = useState(true);

  useEffect(() => {
    setShrinkLabel(!!searchText.length);
  }, [searchText]);

  return (
    <Box component="form" noValidate>
      <TextField
        value={searchText}
        placeholder={props.placeholder || "Search"}
        onChange={(e) => {
          setSearchText(e.target.value);
          props.onChange?.(e.target.value);
        }}
        autoComplete="off"
        sx={{
          borderRadius: "100px",
          bgcolor: "grey.100",
          width: props.width || "25ch",

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
            borderRadius: "100px",
          },
          "& .MuiOutlinedInput-input": {
            py: "0px",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1px",
            },
        }}
        InputProps={{
          sx: { ...props.sx, fontSize: props.fontSize || 15 },
          startAdornment: (
            <SearchIcon
              sx={{
                fontSize: 20,
                mr: 0.5,
                color: shrinkLabel ? "primary.main" : "text.secondary",
              }}
            />
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
