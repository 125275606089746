export function getPhoneNumberDisplay(phoneNumber?: string): string | null {
  if (!phoneNumber) {
    return null;
  }
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "" : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function getNameNumberDisplayText(
  firstName: string | null,
  lastName: string | null,
  phoneNumber: string | null,
): string | null {
  let displayText = null;

  if (firstName || lastName) {
    displayText = `${firstName} ${lastName}`;
  } else if (phoneNumber) {
    displayText = getPhoneNumberDisplay(phoneNumber);
  }

  return displayText;
}

export function getNameInitials(
  firstName: string | undefined,
  lastName: string | undefined,
): string {
  const firstNameInitial = firstName ? firstName[0] : "";
  const lastNameInitial = lastName ? lastName[0] : "";

  return `${firstNameInitial}${lastNameInitial}`;
}

export function getFullNameDisplay(
  firstName: string | undefined,
  lastName: string | undefined,
): string | undefined {
  let fullName = "";

  if (firstName) {
    fullName += `${firstName} `;
  }

  if (lastName) {
    fullName += `${lastName}`;
  }

  return fullName || undefined;
}
