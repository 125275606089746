import { useState } from "react";
import { Stack } from "@mui/material";

import { AppTextField } from "@/components/inputs";
import { FormElementState } from "@/shared";
import { EditSaveCancel, FormWrapper } from "@/components/forms";

export interface ILocationFormProps {
  name: FormElementState<string | undefined>;
  editEnabled?: boolean;
}

const LocationForm: React.FC<ILocationFormProps> = (props) => {
  return (
    <Stack>
      <AppTextField
        disabled={!props.editEnabled}
        {...props.name}
        label="Name"
        placeholder="Name"
        width="40ch"
      />
    </Stack>
  );
};

export default LocationForm;
