import { useState } from "react";
import { Box } from "@mui/material";

import UsersOverview from "./overview";
import UserForm from "./details/UserForm";
import { FOCUSED_ENTITY_NEW } from "@/components/shared";

const UsersSettings: React.FunctionComponent = () => {
  const [focusedEntity, setFocusedEntity] = useState<string | undefined>("");

  return (
    <Box sx={{ height: "100%" }}>
      <UsersOverview
        onEntityClick={setFocusedEntity}
        onCreateBtnClick={() => setFocusedEntity(FOCUSED_ENTITY_NEW)}
      />

      {!!focusedEntity && (
        <UserForm
          userID={
            focusedEntity === FOCUSED_ENTITY_NEW ? undefined : focusedEntity
          }
          onClose={() => setFocusedEntity(undefined)}
        />
      )}
    </Box>
  );
};

export default UsersSettings;
