import { useState } from "react";

import { IStoreLocationWithStatus } from "@alex/types";

import { ILocationRowProps, LocationRow } from "./LocationRow";
import {
  IBaseTableProps,
  AppTable,
  TableActionsPopover,
  ViewDetailsAction,
  NoRowsRow,
} from "@/components/tables";

export interface ILocationsTableProps extends IBaseTableProps {
  rows: IStoreLocationWithStatus[] | undefined | null;
}

const LocationsTable: React.FunctionComponent<ILocationsTableProps> = ({
  rows,
  onEntityClick,
  ...props
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focused, setFocused] = useState<{ id: string; name: string } | null>(
    null,
  );

  function openActionsPopover(
    id: string,
    name: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setFocused({ id, name });
  }

  function closePopover() {
    setIsPopoverOpen(false);
    setAnchorEl(null);
  }

  function handleViewDetailsBtnClick() {
    if (focused) {
      onEntityClick(focused.id);
      closePopover();
    }
  }

  return (
    <>
      <AppTable
        headerText="Locations"
        entityName="Location"
        subHeader=""
        searchKeys={["name"]}
        columnLabels={["Name", "Status", "Total Visits", "Created At", ""]}
        rows={rows?.map((row) => {
          let rowProps: ILocationRowProps = {
            ...row,
            onEntityClick,
            openActionsPopover,
          };
          return rowProps;
        })}
        rowComponent={LocationRow}
        noRowsComponent={
          <NoRowsRow
            onClick={props.onCreateBtnClick}
            pluralEntity="Locations"
          />
        }
        onEntityClick={onEntityClick}
        onRefreshBtnClick={props.onRefreshBtnClick}
        onCreateBtnClick={props.onCreateBtnClick}
        sx={{ height: "100%", width: "100%" }}
      />

      <TableActionsPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <ViewDetailsAction onClick={handleViewDetailsBtnClick} />
      </TableActionsPopover>
    </>
  );
};

export default LocationsTable;
