import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone, FileWithPath } from "react-dropzone";

import { AppFile } from "@alex/types";

import { sharedConfig } from "../shared";
import { colors } from "@/app/themes";
import AlexGradientText from "@/components/text/AlexGradientText";

export interface IFileUploaderProps {
  onChange: (data: AppFile) => void;
}

const FileUploader: React.FunctionComponent<IFileUploaderProps> = (props) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const onDrop = async (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length === 0) return;

    const selectedFile = acceptedFiles[0];
    const reader = new FileReader();

    console.log({ selectedFile });

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const content = event?.target?.result as string;

      props.onChange({
        name: selectedFile.name || "file",
        content,
        type: selectedFile.type,
      });
    };

    reader.readAsText(selectedFile);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileExplorer,
    fileRejections,
    acceptedFiles,
    isDragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
    onDrop,
  });

  return (
    <Stack
      {...getRootProps()}
      sx={{
        borderRadius: sharedConfig.borderRadius,
        bgcolor: sharedConfig.bgcolor,
        justifyContent: "center",
        alignItems: "center",
        opacity: isDragActive ? 0.4 : 1,
        height: "100%",
        boxShadow: (theme) =>
          isDragActive ? `inset 0 0 20px ${theme.palette.grey[500]}` : "none",
      }}
      spacing={2}
    >
      <Box sx={{ cursor: "pointer", width: "fit-content" }}>
        <input {...getInputProps()} />
        <Stack
          onMouseDown={() => {
            setIsMouseDown(true);
          }}
          onMouseUp={() => {
            setIsMouseDown(false);
            openFileExplorer();
          }}
          onMouseLeave={() => {
            setIsMouseDown(false);
          }}
          direction="row"
          sx={{
            border: "1px solid transparent",
            background: (theme) =>
              `linear-gradient(white, white) padding-box, linear-gradient(to bottom right, ${theme.gradient.left}, ${theme.gradient.right}) border-box`,
            borderRadius: "50em",
            px: 2,
            py: 0.5,
            alignItems: "center",
            boxShadow: (theme) =>
              isMouseDown ? "none" : `1px 1px 1px ${theme.gradient.right}`,
          }}
        >
          <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={0} x2={0.75} y2={0.75}>
              <stop offset={0} stopColor={colors.gradient.left} />
              <stop offset={1} stopColor={colors.gradient.right} />
            </linearGradient>
          </svg>
          <FileUploadIcon
            sx={{
              fontSize: 18,
              fill: "url(#linearColors)",
            }}
          />

          <AlexGradientText sx={{ ml: 0.5 }}>Choose File</AlexGradientText>
        </Stack>
      </Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Or Drag & Drop a File Here
      </Typography>

      <Typography sx={{ color: "text.secondary", fontSize: 13 }}>
        CSV, XLS or XLSX
      </Typography>
    </Stack>
  );
};

export default FileUploader;
