import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  FormControl,
  Box,
  SxProps,
  Theme,
  Typography,
  Stack,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { KVPair } from "@alex/types";

import { ErrorText, LabelText, sharedConfig } from "./shared";
import { getPrimaryGradient } from "@/shared/utils/ui";

export interface IAppSelectProps {
  label?: string;
  value?: string;
  options?: KVPair[];
  disabled?: boolean;
  errorMsg?: string;
  width?: string;
  tooltipText?: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => void;
  sx?: SxProps<Theme>;

  noSort?: boolean;
}

const DEFAULT_WIDTH = sharedConfig.width;

const AppSelect: React.FunctionComponent<IAppSelectProps> = (props) => {
  const fieldWidth = props.width || DEFAULT_WIDTH;

  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasError(props?.errorMsg?.length ? true : false);
  }, [props.errorMsg]);

  const [displayOptions, setDisplayOptions] = useState<KVPair[]>();

  useEffect(() => {
    if (!props.options) {
      return;
    }

    if (props.noSort) {
      setDisplayOptions(props.options);
    } else {
      setDisplayOptions(
        props.options.sort((a, b) => (a.value > b.value ? 1 : -1)),
      );
    }
  }, [props.options, props.noSort]);

  const selectID = props.label;

  return (
    <Box sx={{ ...props.sx }}>
      {props.label && (
        <LabelText tooltipText={props.tooltipText}>{props.label}</LabelText>
      )}

      <Stack sx={{ minHeight: "42px", justifyContent: "center" }}>
        {props.disabled || !displayOptions ? (
          <Typography sx={{ color: "text.secondary", mb: 1 }}>
            {props.value}
          </Typography>
        ) : (
          <FormControl sx={{ width: fieldWidth }}>
            <Select
              id={selectID}
              value={props.value}
              error={hasError}
              onChange={(e) => {
                props.onChange?.(e);
              }}
              onBlur={(e) => {
                props.onBlur?.(e);
              }}
              disabled={props.disabled}
              sx={{
                borderRadius: sharedConfig.borderRadius,
                bgcolor: sharedConfig.bgcolor,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: hasError ? 1 : 0,
                  borderRadius: sharedConfig.borderRadius,
                },
                "& .MuiOutlinedInput-input": {
                  borderRadius: sharedConfig.borderRadius,
                  py: sharedConfig.py,
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenuItem-root.Mui-selected": {
                      background: getPrimaryGradient(115),
                      color: "white",
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "grey.200",
                    },
                  },
                },
              }}
            >
              {displayOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  <Typography sx={{ fontSize: sharedConfig.fontSize }}>
                    {option.value}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {hasError && <ErrorText text={props.errorMsg} />}
    </Box>
  );
};
export default AppSelect;
