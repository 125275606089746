import { Button, SxProps, Theme, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export interface IBackButtonProps {
  children?: string;
  overridePath?: string;

  sx?: SxProps<Theme>;
}

const BackButton: React.FunctionComponent<IBackButtonProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        if (props.overridePath) {
          navigate(props.overridePath);
        } else {
          navigate(-1);
        }
      }}
      sx={{
        color: "text.secondary",
        borderRadius: "25px",
        px: 2,
        py: 0.5,
        width: "fit-content",

        "&:hover": {
          bgcolor: "grey.100",
        },
        ...props.sx,
      }}
    >
      <ArrowBackIcon sx={{ fontSize: 15, mr: 0.75 }} />

      <Typography>{props.children || "Back"}</Typography>
    </Button>
  );
};

export default BackButton;
