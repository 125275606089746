import AddIcon from "@mui/icons-material/Add";

import GradientButton, { IGradientButtonProps } from "./GradientButton";

export interface ICreateButtonProps
  extends Omit<IGradientButtonProps, "icon"> {}

const CreateButton: React.FunctionComponent<ICreateButtonProps> = (props) => {
  return (
    <GradientButton
      {...props}
      icon={<AddIcon sx={{ mr: -0.5, fontSize: 22 }} />}
    />
  );
};

export default CreateButton;
