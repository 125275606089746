import { useAuth0 } from "@auth0/auth0-react";
import { Button, Box, Stack, Typography } from "@mui/material";

import config from "@/config";

const Lobby: React.FunctionComponent = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        bgcolor: "grey.100",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <Box
          sx={{
            width: "400px",
            bgcolor: "white",
            borderRadius: "30px",
            border: (theme) => `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Stack
            direction="column"
            sx={{ m: "40px", py: "16px", alignItems: "center" }}
          >
            <Box
              component="img"
              sx={{
                width: `90%`,
                pb: "32px",
              }}
              src={`${config.cdn.baseURL}/alex-logo.png`}
            />

            <Stack sx={{ alignItems: "center" }}>
              <Typography sx={{ fontSize: 22, pb: "16px" }}>Welcome</Typography>
            </Stack>

            <Typography sx={{ fontSize: 14, pb: "32px" }}>
              Click Login to continue.
            </Typography>

            <Button
              onClick={() => loginWithRedirect()}
              variant="contained"
              fullWidth
              disableElevation
              disableTouchRipple
              sx={{
                bgcolor: "primary.main",
                height: "52px",
                borderRadius: "10px",
                "&:hover": {
                  bgcolor: "#500c99",
                },
              }}
            >
              Log in
            </Button>
          </Stack>
        </Box>

        <Box
          onClick={() => alert("go to marketing site")}
          component="img"
          sx={{
            pt: "16px",
            height: "34px",
            width: "22px",
            cursor: "pointer",
          }}
          src={`${config.cdn.baseURL}/alex-icon-lobby.png`}
        />
      </Stack>
    </Box>
  );
};

export default Lobby;
