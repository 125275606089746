import { useState, useEffect } from "react";
import { min } from "lodash";
import { IconButton, Stack, Typography } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

export interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const sharedIconStyles = {
  fontSize: 20,
};

const TablePaginationActions: React.FunctionComponent<
  ITablePaginationActionsProps
> = (props) => {
  const { page, rowsPerPage, count, onPageChange } = props;
  const [firstItem, setFirstItem] = useState(0);
  const [lastItem, setLastItem] = useState(0);

  useEffect(() => {
    const first = 1 + page * rowsPerPage;
    const last = min([first + rowsPerPage - 1, count]);

    setFirstItem(min([first, last]) || 0);
    setLastItem(last || 0);
  }, [count, page, rowsPerPage]);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Stack
      direction="column"
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <Stack
        direction="row"
        sx={{ alignItems: "center", justifyContent: "center", mb: "-5px" }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon sx={{ ...sharedIconStyles }} />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft sx={{ ...sharedIconStyles }} />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight sx={{ ...sharedIconStyles }} />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon sx={{ ...sharedIconStyles }} />
        </IconButton>
      </Stack>

      <Typography
        sx={{ color: "grey.500", fontSize: 14 }}
      >{`${firstItem}-${lastItem} of ${count}`}</Typography>
    </Stack>
  );
};

export default TablePaginationActions;
