import { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";

import { KVPair } from "@alex/types";

import { AppSelect, AppTextField } from "@/components/inputs";
import { useAppSelector, useAppDispatch } from "@/app/state/hooks";
import { validateNotEmpty } from "@/shared/utils/validators";
import { ValidationResponse } from "@/shared";
import {
  getTimezoneOptions,
  updateMyBusiness,
} from "@/features/settings/slice";
import { addNewToast } from "@/services/ui/uiSlice";
import { EditSaveCancel, FormWrapper } from "@/components/forms";
import { getPhoneNumberDisplay } from "@/shared/utils/formatters";

export interface IBusinessSettingsProps {}

const BusinessSettings: React.FunctionComponent<IBusinessSettingsProps> = (
  props,
) => {
  const dispatch = useAppDispatch();
  const { myBusiness } = useAppSelector((state) => state.auth);

  const [editEnabled, setEditEnabled] = useState(false);

  const [name, setName] = useState<string | undefined>(myBusiness?.name);
  const [nameErrorMsg, setNameErrorMsg] = useState<string | undefined>();

  const [tzOptions, setTzOptions] = useState<KVPair[]>();
  const [timezone, setTimezone] = useState<string | undefined>(
    myBusiness?.timezone,
  );

  function initData() {
    setName(myBusiness?.name);
    setTimezone(myBusiness?.timezone);
  }

  useEffect(() => {
    initData();
  }, [myBusiness]);

  useEffect(() => {
    dispatch(getTimezoneOptions()).then((res) => {
      if (res.payload) {
        setTzOptions(res.payload as KVPair[]);
      }
    });
  }, [dispatch]);

  function validateName(): ValidationResponse {
    const emptyRes = validateNotEmpty(name);

    setNameErrorMsg(emptyRes.errorMsg);

    return emptyRes;
  }

  async function handleSaveBtnClick() {
    // TODO - validate first
    if (!name) {
      return;
    }

    const apiRes = await dispatch(
      updateMyBusiness({
        name,
        timezone,
      }),
    );

    if (apiRes.payload) {
      dispatch(
        addNewToast({
          text: "Successfully updated account",
          severity: "success",
        }),
      );
    } else {
      dispatch(
        addNewToast({
          text: "Failed to update account",
          severity: "error",
        }),
      );
    }
  }

  if (name === undefined) {
    return <Box />;
  } else {
    return (
      <Stack spacing={3}>
        <FormWrapper
          editEnabled={editEnabled}
          onEditBtnClick={() => setEditEnabled(true)}
          onSaveBtnClick={() => {
            handleSaveBtnClick();
            setEditEnabled(false);
          }}
          onCancelBtnClick={() => {
            setEditEnabled(false);
            initData();
          }}
        >
          <AppTextField
            label="Business Name"
            value={name}
            errorMsg={nameErrorMsg}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) => validateName()}
            disabled={!editEnabled}
          />

          <AppSelect
            label="Reporting Time Zone"
            tooltipText="Used to display dates and times."
            value={timezone}
            options={tzOptions}
            noSort
            onChange={(e) => {
              setTimezone(e.target.value);
            }}
            disabled={!editEnabled}
          />

          <AppTextField
            label="Phone Number"
            value={getPhoneNumberDisplay(myBusiness?.phoneNumber)}
            disabled={true}
          />
        </FormWrapper>
      </Stack>
    );
  }
};

export default BusinessSettings;
