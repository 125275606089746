import { Grid, SxProps, Theme } from "@mui/material";

import SingleStatCard from "./SingleStatCard";
import { StatCardData } from "@alex/types";

interface IStatCardsProps {
  totalCustomers?: StatCardData;
  newCustomers?: StatCardData;
  instoreVisits?: StatCardData;
  numDays?: number;
  sx?: SxProps<Theme>;
}

const StatCards: React.FC<IStatCardsProps> = (props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", flexWrap: "wrap", ...props.sx }}
    >
      <Grid item xs={12} sm={6} md={3}>
        <SingleStatCard
          title="Total Customers"
          data={props.totalCustomers}
          dateCompareText={`vs prev. ${props.numDays} days`}
          sx={{ borderRadius: "20px", height: "100%" }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SingleStatCard
          title="New Customers"
          data={props.newCustomers}
          dateCompareText={`vs prev. ${props.numDays} days`}
          sx={{ borderRadius: "20px", height: "100%" }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SingleStatCard
          title="Instore Visits"
          data={props.instoreVisits}
          dateCompareText={`vs prev. ${props.numDays} days`}
          sx={{ borderRadius: "20px", height: "100%" }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SingleStatCard
          title="Instore Visits"
          data={props.instoreVisits}
          dateCompareText={`vs prev. ${props.numDays} days`}
          sx={{ borderRadius: "20px", height: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default StatCards;
