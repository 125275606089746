import { LabelText } from "@/components/inputs/shared";
import { Stack, SxProps, Theme } from "@mui/material";

interface IRewardsAppMessagingSettingsProps {
  sx?: SxProps<Theme>;
}

const RewardsAppMessagingSettings: React.FC<
  IRewardsAppMessagingSettingsProps
> = (props) => {
  return (
    <Stack sx={{ ...props.sx }}>
      <LabelText>Coming Soon!</LabelText>
    </Stack>
  );
};

export default RewardsAppMessagingSettings;
