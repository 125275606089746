import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import momentTz from "moment-timezone";

import { IDateStatAggregate } from "@alex/types";
import { Stack, Typography, Tooltip } from "@mui/material";
import { GraphTooltip } from "@/components/graphs";

interface ITabletStatusGraphSingleBoxProps {
  data: IDateStatAggregate;
}

const TabletStatusGraphSingleBox: React.FC<ITabletStatusGraphSingleBoxProps> = (
  props,
) => {
  const { data: el } = props;
  const isChecked = !!el.count;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      open={isHovered}
      onOpen={() => {}} // leave it!
      onClose={() => {}} // leave it!
      placement="top"
      title={
        <GraphTooltip
          title={`${momentTz(el?.date).format("ddd, MMM. DD @")} ${momentTz(
            el?.date,
          ).format("ha")} - ${momentTz(el?.date).add(1, "hour").format("ha")}`}
          sx={{ p: 0, border: undefined }}
        >
          <Typography sx={{ color: "text.secondary" }}>
            Tablet Status:{" "}
            <Typography
              sx={{
                display: "inline",
                fontWeight: 900,
                color: "text.primary",
              }}
            >
              {isChecked ? "On" : "Off"}
            </Typography>
          </Typography>
        </GraphTooltip>
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "white",
            borderRadius: "10px",
            p: 2,
            borderWidth: "0px",
            border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          },
        },
      }}
    >
      <Stack
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={(e) => {
          setIsHovered(false);
        }}
        sx={{
          height: "27px",
          width: "27px",
          bgcolor: (theme) =>
            isChecked ? theme.palette.success.light : "grey.200",
          borderRadius: "5px",
          justifyContent: "center",
          alignItems: "center",
          border: (theme) =>
            isChecked ? `0.2px solid ${theme.palette.grey}` : "none",
          "&:hover": {
            boxShadow: (theme) =>
              `-2px 2px 1px ${
                isChecked ? theme.palette.success.main : theme.palette.grey[500]
              }`,
          },
        }}
      >
        {isChecked ? (
          <CheckIcon sx={{ fontSize: 20, color: "success.main" }} />
        ) : (
          <></>
        )}
      </Stack>
    </Tooltip>
  );
};

export default TabletStatusGraphSingleBox;
