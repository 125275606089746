import { Alert, Snackbar, Slide } from "@mui/material";

import { useAppSelector, useAppDispatch } from "@/app/state/hooks";
import { deleteToastByID } from "../uiSlice";
import { Toast } from "../types";

export interface IToastsListProps {}

const ToastsList: React.FunctionComponent<IToastsListProps> = (props) => {
  const dispatch = useAppDispatch();
  const vertical = "bottom";
  const horizontal = "right";

  const toasts = useAppSelector((state) => state.ui.toasts);

  function handleToastClose(toastID: string) {
    dispatch(deleteToastByID(toastID));
  }

  return (
    <>
      {toasts.map((toast: Toast) => {
        return (
          <Snackbar
            key={toast.id}
            anchorOrigin={{ vertical, horizontal }}
            open={true}
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
          >
            <Alert
              onClose={() => handleToastClose(toast.id)}
              severity={toast.severity}
              variant="filled"
              sx={{ borderRadius: "10px", maxWidth: "350px", color: "white" }}
            >
              {toast.text}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
};

export default ToastsList;
