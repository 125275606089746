import { SxProps, Theme } from "@mui/material";

import GradientButton from "./GradientButton";

export interface ISaveButtonProps {
  onClick: () => void;
  isUpdate?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const SaveButton: React.FunctionComponent<ISaveButtonProps> = (props) => {
  const buttonText = props.isUpdate ? "Update" : "Save";

  return (
    <GradientButton
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        borderRadius: "25px",

        ...props.sx,
      }}
    >
      {buttonText}
    </GradientButton>
  );
};

export default SaveButton;
