import { Box } from "@mui/material";

import InboxHeader from "./InboxHeader";
import InboxItems from "./InboxItems";

export interface IInboxProps {
  width: string;
}

const Inbox: React.FunctionComponent<IInboxProps> = (props) => {
  const headerHeight = "60px";

  return (
    <Box
      sx={{
        height: "100%",
        width: props.width,
      }}
    >
      <Box sx={{ height: headerHeight }}>
        <InboxHeader />
      </Box>

      <Box sx={{ height: `calc(100% - ${headerHeight} - 20px)` }}>
        <InboxItems />
      </Box>
    </Box>
  );
};

export default Inbox;
