import { Typography, SxProps, Theme } from "@mui/material";

import { getPrimaryGradient } from "@/shared/utils/ui";

export interface IAlexGradientTextProps {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
  bold?: boolean;
  children: string | number | undefined;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  secondaryColor?: boolean;
}

const AlexGradientText: React.FunctionComponent<IAlexGradientTextProps> = (
  props,
) => {
  return (
    <Typography
      onClick={() => props.onClick?.()}
      variant={props.variant}
      sx={{
        background: getPrimaryGradient(160),
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontWeight: props.bold ? "bold" : undefined,
        width: "fit-content",
        cursor: props.onClick ? "pointer" : "inherit",
        "&:hover": {
          fontWeight: props.onClick || props.bold ? "bold" : "normal",
        },
        ...props.sx,
      }}
    >
      {props.children !== undefined ? props.children : ""}
    </Typography>
  );
};

export default AlexGradientText;
