import { useState } from "react";

import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import ContactsOverview from "./table";
import ContactForm from "./form/ContactForm";
import { FOCUSED_ENTITY_NEW } from "@/components/shared";

const Contacts: React.FunctionComponent = () => {
  const [focusedEntity, setFocusedEntity] = useState<string | undefined>(
    undefined,
  );

  return (
    <FullPageBase>
      <ContactsOverview
        onEntityClick={setFocusedEntity}
        onCreateBtnClick={() => setFocusedEntity(FOCUSED_ENTITY_NEW)}
      />

      {!!focusedEntity && (
        <ContactForm
          contactID={
            focusedEntity === FOCUSED_ENTITY_NEW ? undefined : focusedEntity
          }
          onClose={() => setFocusedEntity(undefined)}
        />
      )}
    </FullPageBase>
  );
};

export default Contacts;
