import { getPrimaryGradient } from "@/shared/utils/ui";
import { Typography, Link, SxProps, Theme } from "@mui/material";

export interface INoRowsRowProps {
  pluralEntity: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

const NoRowsRow: React.FunctionComponent<INoRowsRowProps> = (props) => {
  return (
    <Typography
      sx={{
        fontSize: "inherit",
        display: "flex",
        alignItems: "center",
      }}
    >
      No {props.pluralEntity.toLowerCase()} yet,
      <Link
        component="button"
        onClick={props.onClick}
        sx={{
          fontSize: "inherit",
          background: getPrimaryGradient(155),
          WebkitBackgroundClip: "text",
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
          width: "fit-content",
          ml: 0.5,
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        create one now!
      </Link>
    </Typography>
  );
};

export default NoRowsRow;
