import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useInView } from "react-intersection-observer";

import { TimeRange } from "@alex/types";

import VisitsByVisitCountGraph from "./components/visitsByVisitCountGraph";
import MessagesGraph from "./components/messagesGraph";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import PageHeaderText from "@/components/text/PageHeaderText";
import TimeRangePicker from "./components/timeRangePicker";
import { useDashboardData } from "./hooks/useDashboardData";
import StatCards from "./components/statCards";
import VisitsByLocationGraph from "./components/visitsByLocationGraph";

const HEADER_HEIGHT = "60px";
const TIME_SELECTOR_HEIGHT = "60px";

const Dashboard: React.FunctionComponent = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>();
  const [numDays, setNumDays] = useState<number>();
  const {
    totalCustomers,
    newCustomers,
    instoreVisits,
    instoreVisitAggregates,
    messageAggregates,
    visitsByLocation,
  } = useDashboardData(timeRange, numDays);

  const { ref, inView } = useInView({ threshold: 1 });
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    setShowShadow(!inView);
  }, [inView]);

  return (
    <FullPageBase>
      <PageHeaderText>Dashboard</PageHeaderText>
      <Box ref={ref} sx={{ mb: 1 }} />

      <TimeRangePicker
        onChange={(range: TimeRange, nDays: number) => {
          setTimeRange(range);
          setNumDays(nDays);
        }}
        shadow={showShadow}
      />

      <StatCards
        totalCustomers={totalCustomers}
        newCustomers={newCustomers}
        instoreVisits={instoreVisits}
        numDays={numDays}
      />

      {/* TODO - should use a grid here to be more responsive */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mt: 3,
          width: "100%",
          height: "400px",
        }}
      >
        <MessagesGraph
          aggregates={messageAggregates}
          sx={{
            bgcolor: "grey.100",
            borderRadius: "20px",
            py: 3,
            px: 4,

            width: "50%",
          }}
        />

        <VisitsByVisitCountGraph
          sx={{
            bgcolor: "grey.100",
            borderRadius: "20px",
            py: 3,
            px: 4,
            width: "50%",
          }}
          data={instoreVisitAggregates}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 3,

          width: "100%",
          height: "400px",
        }}
      >
        <VisitsByLocationGraph
          data={visitsByLocation}
          sx={{
            bgcolor: "grey.100",
            borderRadius: "20px",
            py: 3,
            px: 4,
            width: "50%",
          }}
        />
      </Stack>
    </FullPageBase>
  );
};

export default Dashboard;
