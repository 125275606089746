import { Stack, SxProps, Theme } from "@mui/material";

import { TimeRange } from "@alex/types";
import { useEffect, useState } from "react";
import { getPrimaryGradient } from "@/shared/utils/ui";

interface ITimeRangePickerProps {
  onChange: (range: TimeRange, numDays: number) => void;
  shadow?: boolean;
  sx?: SxProps<Theme>;
}

const LOCAL_STORAGE_KEY = "alexDashboardNumDays";

const TimeRangePicker: React.FC<ITimeRangePickerProps> = (props) => {
  const now = new Date();

  const daysAgo3 = new Date();
  daysAgo3.setDate(daysAgo3.getDate() - 3);

  const daysAgo7 = new Date();
  daysAgo7.setDate(daysAgo7.getDate() - 7);

  const daysAgo30 = new Date();
  daysAgo30.setDate(daysAgo30.getDate() - 30);

  const daysAgo90 = new Date();
  daysAgo90.setDate(daysAgo30.getDate() - 90);

  const options = [
    {
      numDays: 3,
      range: {
        startDate: daysAgo3,
        endDate: now,
      },
    },
    {
      numDays: 7,
      range: {
        startDate: daysAgo7,
        endDate: now,
      },
    },
    {
      numDays: 30,
      range: {
        startDate: daysAgo30,
        endDate: now,
      },
    },
    {
      numDays: 90,
      range: {
        startDate: daysAgo90,
        endDate: now,
      },
    },
  ];

  let defaultOption = options[2];

  const storedNumDays = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (storedNumDays) {
    const storedOption = options.find(
      (el) => el.numDays === parseInt(storedNumDays),
    );

    if (storedOption) {
      defaultOption = storedOption;
    }
  }

  const [selected, setSelected] = useState<number>(defaultOption.numDays);

  useEffect(() => {
    props.onChange(defaultOption.range, defaultOption.numDays);
  }, []);

  return (
    <Stack sx={{ position: "sticky", top: "-20px", width: "fit-content" }}>
      <Stack
        sx={{
          bgcolor: "white",
          height: "15px",
          width: "100%",
          opacity: 0.9,
          filter: "blur(5px)",
        }}
      />

      <Stack
        sx={{
          background: props.shadow ? getPrimaryGradient(165) : "none",
          bgcolor: props.shadow ? "grey.200" : "grey.100",
          borderRadius: "100px",

          boxShadow: (theme) =>
            props.shadow
              ? `0 0 3px 0.5px ${theme.palette.primary.main}`
              : "none",
          p: "2px",
        }}
      >
        <Stack
          direction="row"
          sx={{
            bgcolor: "inherit",
            borderRadius: "100px",
            alignItems: "center",

            cursor: "pointer",
            p: 0.25,

            zIndex: 10000,
            ...props.sx,
          }}
          spacing={2}
        >
          {options.map((el) => {
            return (
              <Stack
                key={el.numDays}
                onClick={() => {
                  setSelected(el.numDays);
                  props.onChange(el.range, el.numDays);
                  localStorage.setItem(
                    LOCAL_STORAGE_KEY,
                    el.numDays.toString(),
                  );
                }}
                sx={{
                  px: 3,
                  py: 0.75,
                  borderRadius: "50px",
                  bgcolor: el.numDays === selected ? "text.primary" : "inherit",
                  color: el.numDays === selected ? "white" : "text.secondary",
                  fontWeight: "bold",
                  fontSize: 13,
                  zIndex: -1,
                  boxShadow: (theme) =>
                    el.numDays === selected
                      ? `0px 1px 1px ${theme.palette.grey[800]}`
                      : "none",
                }}
              >
                {`${el.numDays} Days`}
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      <Stack
        sx={{
          bgcolor: "white",
          height: "15px",
          width: "100%",
          opacity: 0.9,
          filter: "blur(5px)",
        }}
      />
    </Stack>
  );
};

export default TimeRangePicker;
