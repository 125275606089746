import { SxProps, Theme, Typography } from "@mui/material";

export interface IErrorTextProps {
  text?: string;
  sx?: SxProps<Theme>;
}

const ErrorText: React.FunctionComponent<IErrorTextProps> = (props) => {
  return (
    <Typography
      sx={{
        color: "error.main",
        fontSize: 14,
        ...props.sx,
      }}
    >
      {props.text}
    </Typography>
  );
};

export default ErrorText;
