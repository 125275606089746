import { useEffect, useState } from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Contact } from "@alex/types";

import ButtonWithIcon from "@/components/buttons/AppButton";
import { getPhoneNumberDisplay } from "@/shared/utils/formatters";
import { useAppDispatch } from "@/app/state/hooks";
import { updateContact } from "@/features/messages/slice";

interface IContactDetailsProps {
  contact: Contact;
}

const ContactDetails: React.FunctionComponent<IContactDetailsProps> = (
  props,
) => {
  const dispatch = useAppDispatch();

  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const [firstName, setFirstName] = useState(props.contact.firstName);
  const [lastName, setLastName] = useState(props.contact.lastName);
  const comments = props.contact.comments;

  function resetContactInfo() {
    setFirstName(props.contact.firstName);
    setLastName(props.contact.lastName);
  }

  useEffect(() => {
    resetContactInfo();
  }, [props.contact.firstName, props.contact.lastName]);

  function handleCancelBtnClick() {
    resetContactInfo();
    setEditModeEnabled(false);
  }

  function handleSaveBtnClick() {
    dispatch(
      updateContact({
        id: props.contact.ID,
        update: {
          firstName,
          lastName,
          comments,
        },
      }),
    );

    setEditModeEnabled(false);
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Contact Info</Typography>

        {editModeEnabled ? (
          <Stack direction="row" sx={{ alignItems: "flex-end" }}>
            <ButtonWithIcon
              onClick={handleCancelBtnClick}
              text="Cancel"
              textColor="error.main"
              variant="text"
              color="error"
              size="small"
            />

            <ButtonWithIcon
              onClick={handleSaveBtnClick}
              text="Save"
              textColor="white"
              variant="contained"
              color="success"
              size="small"
            />
          </Stack>
        ) : (
          <ButtonWithIcon
            onClick={() => setEditModeEnabled(!editModeEnabled)}
            text="Edit"
            textColor="primary"
            variant="text"
            size="small"
            icon={
              <EditOutlinedIcon
                sx={{
                  height: "20px",
                  marginRight: "2px",
                  color: (theme) => theme.gradient.left,
                  fontSize: "inherit",
                }}
              />
            }
          />
        )}
      </Box>

      {/* PHONE NUMBER */}
      <ContactInfoField
        name="Phone Number"
        value={getPhoneNumberDisplay(props.contact.phoneNumber)}
      />

      {/* NAME */}
      <ContactInfoField
        name="First Name"
        value={firstName}
        editModeEnabled={editModeEnabled}
        onChange={(e) => setFirstName(e.target.value)}
      />

      <ContactInfoField
        name="Last Name"
        value={lastName}
        editModeEnabled={editModeEnabled}
        onChange={(e) => setLastName(e.target.value)}
      />
    </Box>
  );
};

export default ContactDetails;

// HELPERS
interface IContactInfoFieldProps {
  name: string;
  value: string | null;
  editModeEnabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const ContactInfoField: React.FunctionComponent<IContactInfoFieldProps> = (
  props,
) => {
  if (props.editModeEnabled && props.onChange) {
    return (
      <TextField
        label={props.name}
        value={props.value}
        onChange={(e) => {
          props.onChange?.(e);
        }}
        autoComplete="off"
        size="small"
        sx={{
          borderRadius: "5px",
          bgcolor: "grey.100",
          width: "100%",
          mt: "15px",
          mb: "11px",

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
            borderRadius: "5px",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1.5px",
            },
        }}
      ></TextField>
    );
  } else if (props.value?.length) {
    return (
      <>
        <Typography sx={{ color: "text.secondary", fontSize: 14 }}>
          {props.name}:
        </Typography>
        <Typography variant="h6" sx={{ pb: 2, fontWeight: "bold" }}>
          {props.value || ""}
        </Typography>
      </>
    );
  } else {
    return <></>;
  }
};
