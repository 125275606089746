import { ValidationResponse } from "@/shared";

export function validateNotEmpty(text: string | undefined): ValidationResponse {
  const isValid = !!text && text.length > 0;

  return {
    isValid,
    errorMsg: isValid ? "" : "Required!",
  };
}

export function notEmptyValidator(
  text: string | undefined,
  setErrorMsg: (errorMsg: string | undefined) => void,
): ValidationResponse {
  const emptyRes = validateNotEmpty(text);

  if (!emptyRes.isValid) {
    setErrorMsg(emptyRes.errorMsg || "");
  } else {
    setErrorMsg(undefined);
  }
  return emptyRes;
}

export function validNumberValidator(
  number: any,
  setErrorMsg: (errorMsg: string | undefined) => void,
): ValidationResponse {
  const isValid = number !== undefined && !Number.isNaN(number);

  const res = {
    isValid,
    errorMsg: isValid ? "" : "Required!",
  };

  if (!res.isValid) {
    setErrorMsg(res.errorMsg || "");
  } else {
    setErrorMsg(undefined);
  }
  return res;
}

export function validateEmailAddressFormat(
  emailAddress: string,
): ValidationResponse {
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isValid = emailRegex.test(emailAddress);

  return {
    isValid,
    errorMsg: isValid ? "" : "malformed email address",
  };
}
