import { Stack } from "@mui/material";

import Notifications from "./NotificationsBell";
import AvatarPopover from "./AvatarPopover";

const HeaderRight: React.FunctionComponent = () => {
  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <Stack direction="row" sx={{ mr: 0.5, alignItems: "center" }}>
        <Notifications />
      </Stack>

      <AvatarPopover />
    </Stack>
  );
};

export default HeaderRight;
