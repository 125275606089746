import {
  Box,
  Collapse,
  SxProps,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { IBaseRoute } from "@/app/routes/types";
import AlexGradientText from "@/components/text/AlexGradientText";

export interface INavItemChildrenProps {
  children: IBaseRoute[];
  opened: boolean;
  sx?: SxProps<Theme>;
}

const NavItemChildren: React.FunctionComponent<INavItemChildrenProps> = ({
  children,
  ...props
}) => {
  return (
    <Collapse in={props.opened} timeout={{ enter: 300, exit: 300 }}>
      <Box sx={{ ...props.sx }}>
        {children.map((el, i) => {
          return (
            <Stack
              direction="row"
              key={i}
              sx={{
                alignItems: "center",
              }}
            >
              <Box sx={{ mr: "12px", zIndex: 0 }}>
                <Box
                  sx={{
                    height: "3px",
                    width: "2px",
                    bgcolor: "grey.400",
                    borderRadius: i === 0 ? "10px 10px 0px 0px" : "none",
                  }}
                />

                <Stack direction="row" sx={{ alignItems: "flex-end" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10px",
                      width: "10px",
                      bgcolor: "grey.400",
                      borderRadius: "0px 0px 0px 6px",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        mb: "3px",
                        ml: "3px",

                        width: "9px",
                        height: "9px",
                        borderRadius: "0 0 0 4px",
                        bgcolor: "grey.100",
                        border: (theme) =>
                          `2.5px solid ${theme.palette.grey[100]}`,
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      height: "2px",
                      width: "2px",
                      bgcolor: "grey.400",
                      borderRadius: "0 100px 100px 0",
                    }}
                  />
                </Stack>

                <Box
                  sx={{
                    height: "16px",
                    width: "2px",
                    bgcolor:
                      i < children.length - 1 ? "grey.400" : "transparent",
                    mt: "-6px",
                  }}
                />
              </Box>

              <NavLink key={el.path} to={el.path}>
                {({ isActive }) => (
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    {isActive ? (
                      <AlexGradientText
                        variant="h6"
                        sx={{ ...(isActive ? { fontWeight: "bold" } : {}) }}
                      >
                        {el.displayName}
                      </AlexGradientText>
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{
                          color: "text.secondary",
                          "&:hover": {
                            fontWeight: "bold",
                          },
                        }}
                      >
                        {el.displayName}
                      </Typography>
                    )}
                  </Stack>
                )}
              </NavLink>
            </Stack>
          );
        })}
      </Box>
    </Collapse>
  );
};

export default NavItemChildren;
