import { Chip, TableRow } from "@mui/material";
import moment from "moment";

import { IStoreLocationWithStatus } from "@alex/types";

import {
  IBaseTableRowProps,
  ActionsTableCell,
  AppTableCell,
  ClickableTableCell,
} from "@/components/tables";
import { useState } from "react";

export interface ILocationRowProps
  extends IStoreLocationWithStatus,
    IBaseTableRowProps {}

export const LocationRow: React.FC<ILocationRowProps> = (props) => {
  const { name, isActive, numVisits } = props;

  return (
    <TableRow key={props.ID}>
      <ClickableTableCell
        onClick={() => {
          props.onEntityClick(props.ID);
        }}
      >
        {name}
      </ClickableTableCell>

      <AppTableCell>
        <Chip
          label={isActive ? "Active" : "Inactive"}
          color={isActive ? "success" : "default"}
          variant="outlined"
          size="small"
          sx={{
            color: isActive ? "success.main" : "grey.500",
            fontSize: 14,
            px: 0.5,
            py: 0.25,
          }}
        />
      </AppTableCell>

      <AppTableCell>{numVisits}</AppTableCell>

      <AppTableCell>
        {moment(props.createdAt).local().format("ddd, MMM D, yyyy")}
      </AppTableCell>

      <ActionsTableCell
        onClick={(e) => {
          props.openActionsPopover?.(props.ID, props.name, e);
        }}
      />
    </TableRow>
  );
};
