import { useState } from "react";
import EmojiPicker from "emoji-picker-react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { IconButton, Popover } from "@mui/material";

interface IEmojiEditorProps {
  onEmojiSelect: (emoji: string) => void;
}

const EmojiEditor: React.FunctionComponent<IEmojiEditorProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
        sx={{ fontSize: 28 }}
      >
        <SentimentSatisfiedAltIcon color="primary" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <EmojiPicker
          lazyLoadEmojis={true}
          onEmojiClick={(e) => {
            setAnchorEl(null);
            props.onEmojiSelect(e.emoji);
          }}
        />
      </Popover>
    </>
  );
};

export default EmojiEditor;
