import { AppBar, Box, Stack, Slide } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import HeaderCenter from "./center";
import HeaderLeft from "./left";
import HeaderRight from "./right";

export interface IHeaderProps {
  sidebarWidth: string;
  headerHeight: string;
  onLogoClick?: () => void;
}

const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <Slide direction="down" in={true} mountOnEnter unmountOnExit>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          width: `calc(100% - ${props.sidebarWidth})`,
          height: props.headerHeight,
          zIndex: 500,
          boxShadow: "none",
          bgcolor: "white",

          borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            px: 2,
          }}
        >
          <HeaderLeft />

          <HeaderCenter />

          <HeaderRight />
        </Stack>
      </AppBar>
    </Slide>
  );
};

export default Header;
