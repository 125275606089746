import { Box, Stack, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Contact } from "@alex/types";

import PageHeaderText from "@/components/text/PageHeaderText";
import FullPageBase from "@/app/layouts/baseComponents/FullPageBase";
import { BackButton } from "@/components/buttons";
import AlexGradientText from "@/components/text/AlexGradientText";
import { useAppDispatch } from "@/app/state/hooks";
import { getListContactsByID, getListNameByID } from "../../slice";
import ListContactsTable from "./ListContactsTable";
import ListForm from "../form/ListForm";

const ListDetails: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [listContacts, setListContacts] = useState<Contact[]>();
  const [listName, setListName] = useState<string>();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const { id: listID } = useParams();

  useEffect(() => {
    if (listID) {
      dispatch(getListContactsByID(listID)).then((res) => {
        if (res.payload) {
          setListContacts(res.payload as Contact[]);
        }
      });

      dispatch(getListNameByID(listID)).then((res) => {
        if (res.payload) {
          setListName(res.payload as string);
        }
      });
    }
  }, [dispatch, listID]);

  return (
    <FullPageBase>
      <BackButton sx={{ marginRight: "auto" }} />

      <Stack direction="row" sx={{ alignItems: "center", my: 2 }}>
        <PageHeaderText small>List:</PageHeaderText>
        <AlexGradientText variant="h2" secondaryColor sx={{ ml: 1 }}>
          {listName}
        </AlexGradientText>
      </Stack>

      <Box sx={{ mb: 2 }}>
        <Link
          component="button"
          onClick={() => setIsFormModalOpen(true)}
          sx={{
            color: "text.secondary",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          View details
        </Link>
      </Box>

      <ListContactsTable rows={listContacts} />

      {/* TODO - figure out how to lazy load this */}
      {isFormModalOpen && (
        <ListForm listID={listID} onClose={() => setIsFormModalOpen(false)} />
      )}
    </FullPageBase>
  );
};

export default ListDetails;
