import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Typography, Divider, Stack } from "@mui/material";
import moment from "moment";

import { useAppSelector } from "@/app/state/hooks";
import ContactDetails from "./ContactDetails";
import ListChips from "./ListChips";

export interface IContactInfoProps {
  width: string;
}

const ContactInfo: React.FunctionComponent<IContactInfoProps> = (props) => {
  const selectedContact = useAppSelector(
    (state) => state.messages.selectedInteraction?.contact,
  );

  if (!selectedContact) {
    return <></>;
  }

  return (
    <Box sx={{ width: props.width }}>
      <ContactDetails contact={selectedContact} />

      <Divider
        sx={{ borderBottomWidth: "1.6px", borderRadius: "5px", my: 2 }}
      />

      {/* IDK */}
      <Box>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: "3px",
            mb: "15px",
            color: "success.main",
          }}
        >
          <CheckIcon sx={{ fontSize: 24 }} />
          <Typography>Active</Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: "3px",
            mb: "15px",
            color: "text.secondary",
          }}
        >
          <HorizontalRuleIcon sx={{ fontSize: 24 }} />
          <Typography>Texted directly</Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: "3px",
            mb: "15px",
            color: "text.secondary",
          }}
        >
          <CalendarTodayOutlinedIcon sx={{ fontSize: 24 }} />
          <Typography>
            Updated on{" "}
            {moment(selectedContact.updatedAt).format("MMM DD, YYYY")}
          </Typography>
        </Stack>
      </Box>

      <Divider
        sx={{ borderBottomWidth: "1.6px", borderRadius: "5px", my: 2 }}
      />

      {/* Lists */}
      <ListChips lists={selectedContact.lists || []} />
    </Box>
  );
};

export default ContactInfo;
