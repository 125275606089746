import { Box, Stack, Typography } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";

import NotificationCard from "./NotificationCard";
import { useAppDispatch, useAppSelector } from "@/app/state/hooks";
import {
  deleteNotificationByID,
  markNotificationReadByID,
  markNotificationUnreadByID,
} from "./slice";
import AppRightDrawer from "@/components/drawers/AppRightDrawer";
import { setNotificationsVisibility } from "@/services/ui/uiSlice";
import { colors } from "@/app/themes";

const NotificationsDrawer: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.ui.notificationsVisible);

  const { notifications } = useAppSelector((state) => state.notifications);

  if (!notifications) {
    return <></>;
  }

  return (
    <AppRightDrawer
      title="Notifications"
      open={isOpen}
      onClose={() => dispatch(setNotificationsVisibility(false))}
      sx={{
        alignItems: "center",
      }}
    >
      {notifications.length > 0 ? (
        <Stack
          direction="column"
          sx={{
            width: "100%",
          }}
          spacing={2}
        >
          {notifications.map((el) => {
            return (
              <NotificationCard
                key={el.ID}
                item={el}
                onMarkReadBtnClick={(e) => {
                  if (e) {
                    e.stopPropagation();
                  }
                  dispatch(markNotificationReadByID(el.ID));
                }}
                onMarkUnreadBtnClick={(e) => {
                  e.stopPropagation();

                  dispatch(markNotificationUnreadByID(el.ID));
                }}
                onDeleteBtnClick={(e) => {
                  e.stopPropagation();
                  dispatch(deleteNotificationByID(el.ID));
                }}
                sx={{ width: "100%" }}
              />
            );
          })}
        </Stack>
      ) : (
        <Stack sx={{ alignItems: "center", pt: 10 }} spacing={2}>
          <Box
            sx={{
              width: "fit-content",
              color: "grey.500",
            }}
          >
            <svg
              data-testid="notification-null-state-icon"
              width="60"
              height="60"
              viewBox="0 0 62 62"
              role="img"
              aria-label="You’re up to date!"
            >
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  transform="translate(1.000000, 1.000000)"
                  stroke={colors.grey[500]}
                  strokeWidth="2"
                >
                  <path d="M60,30 C60,46.5681818 46.5681818,60 30,60 C13.4309091,60 0,46.5681818 0,30 C0,13.43 13.4309091,0 30,0 C46.5681818,0 60,13.43 60,30 Z"></path>
                  <path d="M20.9090909,36.3627273 L20.9090909,41.8172727 C20.9090909,43.3172727 20.0909091,44.5445455 19.0909091,44.5445455 C18.0909091,44.5445455 17.0318182,43.3409091 16.7381818,41.8709091 L15.4545455,35.4536364"></path>
                  <path d="M38.1818182,16.3627273 L37.83,16.3627273 C32.1263636,21.4490909 24.6072727,24.5445455 16.3636364,24.5445455 C16.0590909,24.5445455 15.7572727,24.5536364 15.4545455,24.5445455 L15.4545455,35.4536364 C15.7572727,35.4445455 16.0590909,35.4536364 16.3636364,35.4536364 C25.0418182,35.4536364 32.9127273,38.9727273 38.7127273,44.5445455 C39.4554545,44.3163636 40,43.6327273 40,42.8145455 L40,18.1809091 C40,17.1772727 39.1863636,16.3627273 38.1818182,16.3627273 Z"></path>
                  <path d="M36.3636364,18.1809091 L36.3636364,41.8172727"></path>
                  <path d="M15.4545455,35.4536364 C12.4418182,35.4536364 10,33.0118182 10,29.9990909 C10,26.9872727 12.4418182,24.5445455 15.4545455,24.5445455"></path>
                  <path d="M50,29.9990909 L43.6363636,29.9990909"></path>
                  <path d="M49.0909091,38.1809091 L43.6363636,35.4536364"></path>
                  <path d="M49.0909091,21.8172727 L43.6363636,24.5445455"></path>
                </g>
              </g>
            </svg>
          </Box>
          <Typography
            sx={{
              color: "grey.500",
              fontWeight: "bold",
            }}
          >
            You're up to date!
          </Typography>
        </Stack>
      )}
    </AppRightDrawer>
  );
};

export default NotificationsDrawer;
