import { SxProps, Theme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import TooltipIconButton from "./TooltipIconButton";

export interface IRefreshButtonProps {
  onClick: () => void;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

const RefreshButton: React.FunctionComponent<IRefreshButtonProps> = (props) => {
  return (
    <TooltipIconButton
      tooltipText="Refresh"
      onClick={props.onClick}
      sx={{
        bgcolor: "transparent",
        color: "text.secondary",

        "&:hover": {
          color: "text.primary",
          bgcolor: "transparent",
        },
        ...props.sx,
      }}
      placement="bottom"
    >
      <RefreshIcon sx={{ ...props.iconSx }} />
    </TooltipIconButton>
  );
};

export default RefreshButton;
