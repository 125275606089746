import { Box, Chip, Typography } from "@mui/material";

import { KVPair } from "@alex/types";

export interface IListChipsProps {
  lists: KVPair[];
}

const ListChips: React.FunctionComponent<IListChipsProps> = (props) => {
  const { lists } = props;

  if (!lists) {
    return <></>;
  }
  return (
    <>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.grey[100],
          p: 2,
          borderRadius: "15px",
          color: "text.secondary",
        }}
      >
        <Typography sx={{ mb: 2, fontSize: 15, fontWeight: "bold" }}>
          Lists
        </Typography>

        {lists.map((el) => {
          return (
            <Chip
              key={el.key}
              label={el.value}
              // onClick={() => console.log(el.value)}
              // onDelete={() => openConfirmDeleteModal(el)}
              variant="outlined"
              sx={{
                mr: 1,
                mb: 0.5,
                fontSize: 14,
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

export default ListChips;
