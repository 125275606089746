import { useState, useEffect } from "react";

import { Employee, EmployeeRole } from "@alex/types";

import { FormModal, IBaseFormProps } from "@/components/forms";
import { AppTextField, AppCheckboxGroup } from "@/components/inputs";
import {
  validateNotEmpty,
  validateEmailAddressFormat,
} from "@/shared/utils/validators";
import { ValidationResponse } from "@/shared";
import { useAppDispatch } from "@/app/state/hooks";
import {
  getEmailAddressIsTaken,
  createUser,
  updateUser,
  getUserByID,
} from "../../slice";
import { addNewToast } from "@/services/ui/uiSlice";

const DEFAULT_HEADER_TEXT = "Create a User";

export interface IUserFormProps extends IBaseFormProps {
  userID?: string;
}

const UserForm: React.FunctionComponent<IUserFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const isUpdate = !!props.userID;

  const [headerText, setHeaderText] = useState(DEFAULT_HEADER_TEXT);
  const [secondaryHeaderText, setSecondaryHeaderText] = useState<
    string | undefined
  >();

  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressErrorMsg, setEmailAddressErrorMsg] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");

  const [roles, setRoles] = useState<EmployeeRole[]>([]);
  const [rolesErrorMsg, setRolesErrorMsg] = useState("");

  useEffect(() => {
    if (props.userID) {
      dispatch(getUserByID(props.userID)).then((res) => {
        if (res.payload) {
          const savedUser = res.payload as Employee;

          setHeaderText("Edit User");
          setSecondaryHeaderText(savedUser.emailAddress);

          setEmailAddress(savedUser.emailAddress);
          setFirstName(savedUser.firstName);
          setLastName(savedUser.lastName);
          setRoles(savedUser.roles);
        } else {
          setHeaderText(DEFAULT_HEADER_TEXT);
          setSecondaryHeaderText(undefined);
        }
      });
    }
  }, [dispatch, props.userID]);

  async function validateEmailAddress(): Promise<ValidationResponse> {
    if (!emailAddress) {
      const errMsg = "can't be empty";
      setEmailAddressErrorMsg(errMsg);
      return {
        isValid: false,
        errorMsg: errMsg,
      };
    }

    const validatorRes = validateEmailAddressFormat(emailAddress);

    if (!validatorRes.isValid) {
      setEmailAddressErrorMsg(validatorRes.errorMsg as string);
      return validatorRes;
    }

    const isTaken = await dispatch(getEmailAddressIsTaken(emailAddress));

    if (isTaken.payload) {
      const errMsg = "email address already taken";
      setEmailAddressErrorMsg(errMsg);
      return {
        isValid: false,
        errorMsg: errMsg,
      };
    } else {
      setEmailAddressErrorMsg("");

      return { isValid: true };
    }
  }

  function validateFirstName(): ValidationResponse {
    const res = validateNotEmpty(firstName);

    setFirstNameErrorMsg(res.errorMsg || "");

    return res;
  }

  function validateLastName(): ValidationResponse {
    const res = validateNotEmpty(firstName);

    setLastNameErrorMsg(res.errorMsg || "");

    return res;
  }

  function handleRolesChange(e: React.ChangeEvent<HTMLInputElement>) {
    const targetRole = e.target.name as EmployeeRole;

    if (roles.includes(targetRole)) {
      setRoles(
        roles.filter((el) => {
          return el !== targetRole;
        }),
      );
    } else {
      setRoles([...roles, targetRole]);
    }
  }

  function validateRoles(): ValidationResponse {
    let isValid = true;
    let errorMsg;

    if (!roles.length) {
      isValid = false;
      errorMsg = "must have at least one role";
    }

    setRolesErrorMsg(errorMsg || "");
    return {
      isValid,
      errorMsg,
    };
  }

  async function handleSaveBtnClick() {
    const validationResponses = await Promise.all([
      validateEmailAddress(),
      validateFirstName(),
      validateLastName(),
      validateRoles(),
    ]);

    let isValid = true;

    validationResponses.forEach((el) => {
      if (!el || !el.isValid) {
        isValid = false;
      }
    });

    if (!isValid) {
      dispatch(
        addNewToast({
          text: "Fix issues, then try again",
          severity: "error",
        }),
      );
      return;
    }

    if (isUpdate) {
      await dispatch(
        updateUser({
          id: props.userID as string, // TODO - need to figure out if this is safe to cast
          update: {
            firstName,
            lastName,
            roles,
          },
        }),
      );

      dispatch(
        addNewToast({
          text: "Updated user",
          severity: "success",
        }),
      );
    } else {
      await dispatch(
        createUser({
          emailAddress,
          firstName,
          lastName,
          roles,
        }),
      );

      dispatch(
        addNewToast({
          text: "Created user",
          severity: "success",
        }),
      );
    }

    props.onClose();
  }

  return (
    <FormModal
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      isUpdate={isUpdate}
      onSaveBtnClick={handleSaveBtnClick}
      onCancelBtnClick={props.onClose}
    >
      <AppTextField
        label="Email Address"
        value={emailAddress}
        onChange={(e) => setEmailAddress(e.target.value)}
        onBlur={(e) => validateEmailAddress()}
        disabled={isUpdate}
        errorMsg={emailAddressErrorMsg}
      />
      <AppTextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onBlur={(e) => validateFirstName()}
        errorMsg={firstNameErrorMsg}
      />
      <AppTextField
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onBlur={(e) => validateLastName()}
        errorMsg={lastNameErrorMsg}
      />

      <AppCheckboxGroup
        label="Roles"
        errorMsg={rolesErrorMsg}
        allItems={["admin", "messaging"]}
        selectedItems={roles}
        onChange={handleRolesChange}
      />
    </FormModal>
  );
};

export default UserForm;
