import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  SxProps,
  Theme,
  ButtonBase,
  Skeleton,
} from "@mui/material";

import TablePaginationActions from "./internal/TablePaginationActions";
import TableHeader, {
  IInheritedTableHeaderProps,
  ITableHeaderProps,
} from "./internal/TableHeader";
import { IBaseTableProps } from "./types";

export interface IAppTableProps<T>
  extends IBaseTableProps,
    IInheritedTableHeaderProps {
  entityName?: string;
  searchKeys: string[];
  isMatchOverride?: (el: any, searchText: string) => boolean;
  columnLabels: string[]; // TODO - change this to interface that dictates if column is sortable
  rows: T[] | undefined;
  rowComponent: React.FunctionComponent<T>;
  noRowsComponent?: JSX.Element;
  sx?: SxProps<Theme>;
}

const NO_ACTIONS_FOOTER_HEIGHT = "0px";
const WITH_ACTIONS_FOOTER_HEIGHT = "60px";

const AppTable = <T extends {}>(props: IAppTableProps<T>) => {
  const { rows: allRows, rowComponent: RowComponent, searchKeys } = props;

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState(allRows || []);

  const [showFooterActions, setShowFooterActions] = useState(false);
  const [footerHeight, setFooterHeight] = useState(NO_ACTIONS_FOOTER_HEIGHT);

  useEffect(() => {
    if (searchText) {
      const filteredRows = allRows?.filter((el: any) => {
        for (const searchKey of searchKeys) {
          let isMatch = false;

          if (props.isMatchOverride) {
            isMatch = props.isMatchOverride(el, searchText);
          } else {
            isMatch = el[searchKey]
              ?.toLowerCase()
              .includes(searchText.toLowerCase());
          }

          if (isMatch) {
            return true;
          }
        }

        return false;
      });

      setFilteredRows(filteredRows || []);
    } else {
      setFilteredRows(allRows || []);
    }
  }, [searchText, searchKeys, allRows]);

  useEffect(() => {
    if (showFooterActions) {
      setFooterHeight(WITH_ACTIONS_FOOTER_HEIGHT);
    }
  }, [showFooterActions]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);

    if (filteredRows.length > rowsPerPage) {
      setShowFooterActions(true);
    }
  }, [filteredRows.length]);

  return (
    <Box sx={{ ...props.sx }}>
      <TableHeader
        {...props}
        entityName={props.entityName || ""}
        onSearchChange={(e) => setSearchText(e)}
      />

      <Box
        sx={{
          pt: "10px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: `calc(100% - ${footerHeight})`,
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            borderRadius: "20px",
          }}
        >
          <Table>
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                bgcolor: "grey.100",
                zIndex: 999,
              }}
            >
              <TableRow>
                {props.columnLabels.map((label, index) => {
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        color: "text.secondary",
                        fontWeight: "bold",
                      }}
                    >
                      <ButtonBase
                        onClick={() => {}}
                        sx={{ py: 0.5, borderRadius: 1 }}
                      >
                        {label}
                      </ButtonBase>
                    </TableCell>
                  );
                })}
              </TableRow>

              {!allRows && (
                <>
                  {[0, 1].map((i) => {
                    return (
                      <TableRow key={i}>
                        {props.columnLabels.map((label) => {
                          return (
                            <TableCell
                              key={label}
                              sx={{
                                bgcolor: "white",
                              }}
                            >
                              {label ? (
                                <Skeleton
                                  variant="rounded"
                                  color="primary"
                                  sx={{ height: "18px" }}
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableHead>

            <TableBody
              sx={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              {allRows &&
                (allRows.length ? (
                  filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => <RowComponent key={index} {...row} />)
                ) : (
                  <TableRow key="no-rows-row">
                    <TableCell
                      colSpan={props.columnLabels?.length || 0}
                      sx={{ fontSize: 14, borderBottomColor: "none" }}
                    >
                      {props.noRowsComponent}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            height: footerHeight,
            bgcolor: "grey.100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0px 0px 20px 20px",
          }}
        >
          {showFooterActions && (
            <TablePaginationActions
              count={filteredRows.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          )}
        </Box>

        <Box sx={{ height: "30px" }} />
      </Box>
    </Box>
  );
};

export default AppTable;
