import { Box, Stack, SxProps, Theme } from "@mui/material";

import { colors } from "@/app/themes";
import GraphTitle from "./GraphTitle";

interface IGraphTooltipProps {
  title: string;
  children: any;
  sx?: SxProps<Theme>;
}

const GraphTooltip: React.FC<IGraphTooltipProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Stack
      sx={{
        background: "white",
        p: 2,
        borderRadius: "10px",
        border: `1px solid ${colors.grey[300]}`,

        ...props.sx,
      }}
    >
      <GraphTitle
        small
        sx={{ color: "text.secondary", pb: 1, whiteSpace: "pre-line" }}
      >
        {title}
      </GraphTitle>

      {children}
    </Stack>
  );
};

export default GraphTooltip;
