import {
  Box,
  Chip,
  TableRow,
  Stack,
  Tooltip,
  SxProps,
  Theme,
} from "@mui/material";
import moment from "moment";

import { Employee, EmployeeRole } from "@alex/types";

import { useAppSelector } from "@/app/state/hooks";
import {
  IBaseTableRowProps,
  ActionsTableCell,
  AppTableCell,
  ClickableTableCell,
} from "@/components/tables";
import { SettingsIcon, MessageIcon } from "@/components/icons";

export interface IUserRowProps extends Employee, IBaseTableRowProps {}

export const UserRow: React.FunctionComponent<IUserRowProps> = (props) => {
  const me = useAppSelector((state) => state.auth.me);

  function getRoleIconAndDisplayText(role: EmployeeRole): {
    icon: JSX.Element;
    displayText: string;
  } {
    const iconSx: SxProps<Theme> = {
      fontSize: 20,
    };

    switch (role) {
      case EmployeeRole.Admin:
        return {
          icon: <SettingsIcon height={16} width={16} sx={iconSx} />,
          displayText: "Admin",
        };
      case EmployeeRole.Messaging:
        return {
          icon: <MessageIcon height={16} width={16} sx={iconSx} />,
          displayText: "Messaging",
        };
    }
  }

  return (
    <TableRow key={props.ID}>
      <ClickableTableCell
        onClick={() => {
          props.onEntityClick(props.ID);
        }}
        cellSx={{ width: "25%" }}
        startAdornment={
          props.emailAddress === me?.emailAddress && (
            <Chip
              label="You"
              color="info"
              variant="outlined"
              size="small"
              sx={{ mr: 1, cursor: "pointer" }}
            />
          )
        }
      >
        {props.emailAddress}
      </ClickableTableCell>

      <AppTableCell>{`${props.firstName} ${props.lastName}`}</AppTableCell>

      <AppTableCell>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          {props.roles.map((role) => {
            const { icon, displayText } = getRoleIconAndDisplayText(role);

            return (
              <Tooltip key={role} title={displayText} placement="top" arrow>
                <Box
                  key={role}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: (theme) =>
                      `1px solid ${theme.palette.text.secondary}`,
                    borderRadius: "5px",
                    px: 1,
                    py: 0.25,
                    color: "text.secondary",
                    mr: 1,
                  }}
                >
                  {icon}
                </Box>
              </Tooltip>
            );
          })}
        </Stack>
      </AppTableCell>

      <AppTableCell>
        {moment(props.createdAt).local().format("MMM D, yyyy")}
      </AppTableCell>

      <AppTableCell>
        {moment(props.updatedAt).local().format("MMM D, yyyy")}
      </AppTableCell>

      <ActionsTableCell
        onClick={(e) => {
          if (props.openActionsPopover) {
            props.openActionsPopover(props.ID, props.emailAddress, e);
          }
        }}
      />
    </TableRow>
  );
};
