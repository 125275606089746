import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Avatar,
  Typography,
  Popover,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

import config from "@/config";
import { useAppSelector } from "@/app/state/hooks";
import { AppRoute } from "@/app/routes";
import { getNameInitials } from "@/shared/utils/formatters";
import { getPrimaryGradient } from "@/shared/utils/ui";

const AvatarPopover: React.FunctionComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClose() {
    setIsPopoverOpen(false);
    setAnchorEl(null);
  }

  function openPopover(event: React.MouseEvent<HTMLButtonElement>) {
    setIsPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const { logout } = useAuth0();

  function logoutWithRedirect() {
    logout({
      logoutParams: {
        returnTo: config.auth0.logoutRedirectURI,
      },
    });
  }

  function handleLogoutBtnClick() {
    setIsPopoverOpen(false);
    logoutWithRedirect();
  }

  function handleProfileBtnClick() {
    setIsPopoverOpen(false);
    navigate(AppRoute.Profile);
  }

  const me = useAppSelector((state) => state.auth.me);

  const [avatarInitials, setAvatarInitials] = useState("");
  useEffect(() => {
    setAvatarInitials(getNameInitials(me?.firstName, me?.lastName));
  }, [me]);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
        }}
      >
        <IconButton onClick={openPopover} disableRipple>
          <Avatar
            sx={{
              background: getPrimaryGradient(115),
              width: "32px",
              height: "32px",
            }}
          >
            <Typography sx={{ fontSize: 15, color: "white" }}>
              {avatarInitials}
            </Typography>
          </Avatar>
        </IconButton>
      </Stack>
      {/* </Button> */}

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: "20px",
            boxShadow: `-3px 3px 20px ${theme.palette.grey[500]}`,
            border: `5px solid ${theme.palette.grey[200]}`,
            backgroundColor: theme.palette.grey[200],
          },
        }}
      >
        <Box
          sx={{
            textAlign: "left",
            color: "text.secondary",
            padding: "10px 15px",
            bgcolor: "white",
            border: (theme) => `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "15px",
            pt: 2,
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Avatar
              sx={{
                background: getPrimaryGradient(155),
                color: "white",
                width: "40px",
                height: "40px",
                mr: "10px",
              }}
            >
              {avatarInitials}
            </Avatar>

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {`${me?.firstName || ""} ${me?.lastName || ""}`}
              </Typography>

              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                {me?.emailAddress || ""}
              </Typography>
            </Box>
          </Stack>

          <Divider sx={{ mt: 2 }} />

          {/* PROFILE */}
          <Button
            onClick={handleProfileBtnClick}
            sx={{
              justifyContent: "left",
              my: "5px",
              borderRadius: "5px",
              color: "text.secondary",
              width: "100%",
              "&:hover": {
                backgroundColor: "grey.200",
              },
            }}
          >
            <ManageAccountsIcon />
            <Typography sx={{ ml: 1 }}>Profile</Typography>
          </Button>

          <Divider />
          {/* LOGOUT */}
          <Button
            onClick={handleLogoutBtnClick}
            color="error"
            sx={{
              justifyContent: "left",
              mt: "5px",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>Log out</Typography>
          </Button>
        </Box>
        <Box sx={{ bgcolor: "grey.200", textAlign: "center", mt: "5px" }}>
          <Typography sx={{ color: "text.secondary", fontSize: 13 }}>
            Terms of Service
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default AvatarPopover;
