import { Box, Link, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "@/app/routes";
import { useState } from "react";

interface ILocationLabelProps {
  locationName: string;
  locationID: string;
  color: string;
}

const LocationLabel: React.FC<ILocationLabelProps> = (props) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        navigate(
          `${AppRoute.InstoreAppLocations}/${props.locationID}?backlink=/`,
        );
      }}
      sx={{
        color: "text.primary",
        cursor: "pointer",

        fontWeight: isHovered ? "bold" : "initial",
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Box
          sx={{
            height: "20px",
            width: "20px",
            borderRadius: "5px",
            mr: 1,
            bgcolor: props.color,
            boxShadow: (theme) =>
              isHovered ? `-1px 1px 1px ${theme.palette.text.primary}` : "",
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: "inherit" }}>
          {props.locationName}
        </Typography>
      </Stack>
    </Link>
  );
};

export default LocationLabel;
