import { TooltipProps } from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { Typography, Stack, Box } from "@mui/material";
import moment from "moment";

import { colors } from "@/app/themes";
import { getPrimaryGradient, getSecondaryGradient } from "@/shared/utils/ui";
import { GraphTooltip } from "@/components/graphs";

const MessagesTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
  payload,
}) => {
  if (payload && payload.length) {
    const dateStr = payload[0].payload.startDate;

    return (
      <GraphTooltip title={moment(dateStr).local().format("ddd, MMM DD")}>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              mr: 0.5,
              background: getSecondaryGradient(160),
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",

              width: "fit-content",
            }}
          >
            Received:
          </Typography>
          <Typography sx={{ fontSize: 15 }}>{payload[1].value} </Typography>
        </Stack>

        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              mr: 0.5,
              background: getPrimaryGradient(160),
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",

              width: "fit-content",
            }}
          >
            Sent:
          </Typography>
          <Typography sx={{ fontSize: 15 }}>{payload[0].value} </Typography>
        </Stack>
      </GraphTooltip>
    );
  }

  return null;
};

export default MessagesTooltip;
