import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Contact } from "@alex/types";

import {
  AppTable,
  TableActionsPopover,
  MessageContactAction,
} from "@/components/tables";
import ListContactRow, { IListContactRowProps } from "./ListContactRow";
import { useAppDispatch } from "@/app/state/hooks";
import { setSelectedInteraction } from "@/features/messages/slice";

export interface IListContactsTableProps {
  rows: Contact[] | undefined;
}

const ListContactsTable: React.FunctionComponent<IListContactsTableProps> = ({
  rows,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [focused, setFocused] = useState<{ id: string; name: string } | null>(
    null,
  );

  function openActionsPopover(
    id: string,
    name: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
    setFocused({ id, name });
  }

  function closePopover() {
    setIsPopoverOpen(false);
    setAnchorEl(null);
  }

  function handleViewDetailsBtnClick() {
    if (focused) {
      dispatch(setSelectedInteraction(focused.id)).then((res) => {
        navigate("/messages");
      });
    }
  }

  return (
    <>
      <AppTable
        searchKeys={["phoneNumber", "firstName", "lastName"]}
        columnLabels={["Name", "Phone Number", "Date Added", ""]}
        rows={rows?.map((row) => {
          let rowProps: IListContactRowProps = {
            ...row,
            openActionsPopover,
          };
          return rowProps;
        })}
        rowComponent={ListContactRow}
        onEntityClick={() => {}}
      />

      <TableActionsPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
      >
        <MessageContactAction onClick={handleViewDetailsBtnClick} />
      </TableActionsPopover>
    </>
  );
};

export default ListContactsTable;
