import { Button, Typography } from "@mui/material";

export interface IViewDetailsActionProps {
  onClick: () => void;
}

const ViewDetailsAction: React.FunctionComponent<IViewDetailsActionProps> = (
  props,
) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        justifyContent: "left",
        borderRadius: "5px",
        width: "100%",
        color: "text.secondary",
        "&:hover": {
          backgroundColor: "grey.200",
        },
      }}
    >
      <Typography sx={{ fontSize: 14, color: "text.secondary" }}>
        View Details
      </Typography>
    </Button>
  );
};

export default ViewDetailsAction;
