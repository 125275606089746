import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

interface DividerWithTextProps {
  children: string;
  width: string;
}

export const DividerWithText = (props: DividerWithTextProps) => {
  const theme = useTheme();

  const lineClass = {
    borderBottom: `.5px solid ${theme.palette.grey[400]}`,
    width: "100%",
    mt: "3px",
  };

  return (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Box sx={lineClass} />
      <Typography
        sx={{
          color: "grey.500",
          mx: "10px",
          width: props.width,
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {props.children}
      </Typography>

      <Box sx={lineClass} />
    </Stack>
  );
};

export default DividerWithText;
